import api from "dao/providers/axios";
import appconfig from "config/appconfig";

const personalaccesstokenEndPoint = appconfig.endpoints.personalaccesstoken;
const personalaccesstokenMeEndPoint = appconfig.endpoints.personalaccesstokenMe;

export const daoPersonalAccessTokenPost = (payload) => api.post(personalaccesstokenEndPoint, payload);

export const daoPersonalAccessTokenListGet = () => api.get(personalaccesstokenEndPoint);

export const daoPersonalAccessTokenPatch = (tokenId, payload) => api.patch(personalaccesstokenMeEndPoint(tokenId), payload);

export const daoPersonalAccessTokenDelete = (tokenId) => api.delete(personalaccesstokenMeEndPoint(tokenId));