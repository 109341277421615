import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SideNav from "../components/side-nav-view";
import { stopEvent } from "lib/utility-functions";
import history from "lib/history";
export const SideNavContainer = () => {

  const compareRoutes = () => (route) => {
    return window.location.pathname === route;
  };

  const movetoDifferentPage = (e) => (route) => {
    stopEvent(e);
    history.push(route);
    return;
  };

  return (
    <SideNav
      compareRoutes={compareRoutes()}
      movetoDifferentPage={movetoDifferentPage()}
    />
  );
};

export default SideNavContainer;
