// This file contains the Locale selector values
// Locale values
export const locales = {
  de_DE: [
    {value: 'de_DE', label: 'Deutsch - Deutsch'},
    {value: 'en_GB', label: 'English (International) - Englisch (International)'},
    {value: 'en_US', label: 'English (US) - Englisch (USA)'},
    {value: 'es_ES', label: 'Español - Spanisch'},
    {value: 'fr_FR', label: 'Français - Französisch'},
    {value: 'it_IT', label: 'Italiano - Italienisch'},
    {value: 'zh_CN', label: '简体中文 - Chinesisch (vereinfacht)'},
    {value: 'ja_JP', label: '日本語 – Japanisch'},
    {value: 'ko_KR', label: '한국어 – Koreanisch'},
    {value: 'pt_BR', label: 'Português brasileiro - Portugiesisch (Brasilien)'},
  ],
  en_US: [
    {value: 'de_DE', label: 'Deutsch - German'},
    {value: 'en_GB', label: 'English (International) - English (International)'},
    {value: 'en_US', label: 'English (US) - English (US)'},
    {value: 'es_ES', label: 'Español - Spanish'},
    {value: 'fr_FR', label: 'Français - French'},
    {value: 'it_IT', label: 'Italiano - Italian'},
    {value: 'zh_CN', label: '简体中文 - Simplified Chinese'},
    {value: 'ja_JP', label: '日本語 - Japanese'},
    {value: 'ko_KR', label: '한국어 - Korean'},
    {value: 'pt_BR', label: 'Português do Brasil - Brazilian Portuguese'},
  ],
  es_ES: [
    {value: 'de_DE', label: 'Deutsch - Alemán'},
    {value: 'en_GB', label: 'English (International) - Inglés (internacional)'},
    {value: 'en_US', label: 'English (US) - Inglés (US)'},
    {value: 'es_ES', label: 'Español - Español'},
    {value: 'fr_FR', label: 'Français - Francés'},
    {value: 'it_IT', label: 'Italiano - Italiano'},
    {value: 'zh_CN', label: '简体中文 - Chino simplificado'},
    {value: 'ja_JP', label: '日本語 - Japonés'},
    {value: 'ko_KR', label: '한국어 - Coreano'},
    {value: 'pt_BR', label: 'Português do Brasil - Portugués de Brasil'},
  ],
  fr_FR: [
    {value: 'de_DE', label: 'Deutsch - Allemand'},
    {value: 'en_GB', label: 'English (International) - Anglais (International)'},
    {value: 'en_US', label: 'English (US) - Anglais (US)'},
    {value: 'es_ES', label: 'Español - Espagnol'},
    {value: 'fr_FR', label: 'Français - Français'},
    {value: 'it_IT', label: 'Italiano - Italien'},
    {value: 'zh_CN', label: '简体中文 - Chinois simplifié'},
    {value: 'ja_JP', label: '日本語 - Japonais'},
    {value: 'ko_KR', label: '한국어 - Coréen'},
    {value: 'pt_BR', label: 'Português brasileiro - Portugais brésilien'},
  ],
  it_IT: [
    {value: 'de_DE', label: 'Deutsch - Tedesco'},
    {value: 'en_GB', label: 'English (International) - Inglese (Internazionale)'},
    {value: 'en_US', label: 'English (US) - Inglese (USA)'},
    {value: 'es_ES', label: 'Español - Spagnolo'},
    {value: 'fr_FR', label: 'Français - Francese'},
    {value: 'it_IT', label: 'Italiano - Italiano'},
    {value: 'zh_CN', label: '简体中文 - Cinese semplificato'},
    {value: 'ja_JP', label: '日本語 - Giapponese'},
    {value: 'ko_KR', label: '한국어 - Coreano'},
    {value: 'pt_BR', label: 'Português brasileiro - Portoghese (Brasile)'},
  ],
  zh_CN: [
    {value: 'de_DE', label: 'Deutsch - 德语'},
    {value: 'en_GB', label: 'English (International) - 英语(国际)'},
    {value: 'en_US', label: 'English (US) - 英语(美国)'},
    {value: 'es_ES', label: 'Español - 西班牙语'},
    {value: 'fr_FR', label: 'Français - 法语'},
    {value: 'it_IT', label: 'Italiano - 意大利语'},
    {value: 'zh_CN', label: '简体中文 - 简体中文'},
    {value: 'ja_JP', label: '日本語 – 日语'},
    {value: 'ko_KR', label: '한국어 – 朝鲜语'},
    {value: 'pt_BR', label: 'Português Brasileiro – 巴西葡萄牙语'},
  ],
  ja_JP: [
    {value: 'de_DE', label: 'Deutsch - German'},
    {value: 'en_GB', label: 'English (International) - English (International)'},
    {value: 'en_US', label: 'English (US) - English (US)'},
    {value: 'es_ES', label: 'Español - Spanish'},
    {value: 'fr_FR', label: 'Français - French'},
    {value: 'it_IT', label: 'Italiano - Italian'},
    {value: 'zh_CN', label: '简体中文 - Simplified Chinese'},
    {value: 'ja_JP', label: '日本語 - 日本語'},
    {value: 'ko_KR', label: '한국어 - 韓国語'},
    {value: 'pt_BR', label: 'Português brasileiro - ブラジルポルトガル語'},
  ],
  ko_KR: [
    {value: 'de_DE', label: 'Deutsch - German'},
    {value: 'en_GB', label: 'English (International) - English (International)'},
    {value: 'en_US', label: 'English (US) - English (US)'},
    {value: 'es_ES', label: 'Español - Spanish'},
    {value: 'fr_FR', label: 'Français - French'},
    {value: 'it_IT', label: 'Italiano - Italian'},
    {value: 'zh_CN', label: '简体中文 - Simplified Chinese'},
    {value: 'ja_JP', label: '日本語 - 일본어'},
    {value: 'ko_KR', label: '한국어 - 한국어'},
    {value: 'pt_BR', label: 'Português brasileiro - 포르투갈어(브라질)'},
  ],
  pt_BR: [
    {value: 'de_DE', label: 'Deutsch - German'},
    {value: 'en_GB', label: 'English (International) - English (International)'},
    {value: 'en_US', label: 'English (US) — Inglês (EUA)'},
    {value: 'es_ES', label: 'Español - Spanish'},
    {value: 'fr_FR', label: 'Français - French'},
    {value: 'it_IT', label: 'Italiano'},
    {value: 'zh_CN', label: '简体中文 - Simplified Chinese'},
    {value: 'ja_JP', label: '日本語 - Japonês'},
    {value: 'ko_KR', label: '한국어 - Coreano'},
    {value: 'pt_BR', label: 'Português brasileiro - Português brasileiro'},
  ],
};

export const fauxLocales = {
  de_DE: {
    hu: 'Magyar - Ungarisch',
    nl: 'Nederlands - Niederländisch',
    ru: 'Русский - Russisch',
  },
  en_US: {
    hu: 'Magyar - Hungarian',
    nl: 'Nederlands - Dutch',
    ru: 'Русский - Russian',
  },
  es_ES: {
    hu: 'Magyar - Húngaro',
    nl: 'Nederlands - Neerlandés',
    ru: 'Русский - Ruso',
  },
  fr_FR: {
    hu: 'Magyar - Hongrois',
    nl: 'Nederlands - Néerlandais',
    ru: 'Русский - Russe',
  },
  it_IT: {
    hu: 'Magyar - Ungherese',
    nl: 'Nederlands - Olandese',
    ru: 'Русский - Russo',
  },
  zh_CN: {
    hu: 'Magyar - 匈牙利语',
    nl: 'Nederlands - 荷兰语',
    ru: 'Русский - 俄语',
  },
  ja_JP: {
    hu: 'Magyar - ハンガリー語',
    nl: 'Nederlands - オランダ語',
    ru: 'Русский - ロシア語',
  },
  ko_KR: {
    hu: 'Magyar - 헝가리어',
    nl: 'Nederlands - 네덜란드어',
    ru: 'Русский - 러시아어',
  },
  pt_BR: {
    hu: 'Magyar - Húngaro',
    nl: 'Nederlands - Holandês',
    ru: 'Русский - Russo',
  },
};

const fauxLocaleSelection = (locale, fauxLocale) => (
  fauxLocales[locale] && fauxLocales[locale][fauxLocale] ?
    {value: fauxLocale, label: fauxLocales[locale][fauxLocale]} : null);


export const localeOptions = (locale, selectedLocale) => {
  const localeArray = (locales[locale] || locales.en_US).slice();
  const fauxLocale = fauxLocaleSelection(locale, selectedLocale);
  if (fauxLocale) {
      localeArray.push(fauxLocale);
  }
  return localeArray;
};

export const isFauxLocale = (locale) => locale === 'hu' || locale === 'nl' || locale === 'ru';
