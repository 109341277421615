import {
  daoPasskeyRegistrationOptionsPost,
  daoPasskeyRegistrationPost,
  daoPasskeyGet,
  daoPasskeyDelete,
} from "dao/passkey-dao";

export const PASSKEY_REGISTRATION_OPTIONS_POST = "PASSKEY_REGISTRATION_OPTIONS_POST";
export const PASSKEY_REGISTRATION_OPTIONS_POST_SUCCESS = "PASSKEY_REGISTRATION_OPTIONS_POST_SUCCESS";
export const PASSKEY_REGISTRATION_OPTIONS_POST_FAILURE = "PASSKEY_REGISTRATION_OPTIONS_POST_FAILURE";

export const PASSKEY_REGISTRATION_POST = "PASSKEY_REGISTRATION_POST";
export const PASSKEY_REGISTRATION_POST_SUCCESS = "PASSKEY_REGISTRATION_POST_SUCCESS";
export const PASSKEY_REGISTRATION_POST_FAILURE = "PASSKEY_REGISTRATION_POST_FAILURE";

export const PASSKEY_CREDENTIALS_GET = "PASSKEY_CREDENTIALS_GET";
export const PASSKEY_CREDENTIALS_GET_SUCCESS = "PASSKEY_CREDENTIALS_GET_SUCCESS";
export const PASSKEY_CREDENTIALS_GET_FAILURE = "PASSKEY_CREDENTIALS_GET_FAILURE";

export const PASSKEY_CREDENTIAL_DELETE = "PASSKEY_CREDENTIAL_DELETE";
export const PASSKEY_CREDENTIAL_DELETE_SUCCESS = "PASSKEY_CREDENTIAL_DELETE_SUCCESS";
export const PASSKEY_CREDENTIAL_DELETE_FAILURE = "PASSKEY_CREDENTIAL_DELETE_FAILURE";

export const initialState = {
  data: {
    credentials: [],
  },

  isLoading: false,
};

export const passkeyIsLoading = (state) => state.passkey.isLoading;

export const passkeyList = (state) =>
  state.passkey && state.passkey.data && state.passkey.data.credentials
    ? state.passkey.data.credentials
    : [];


// ------------------------------------
// Actions
// ------------------------------------
export const passkeyCredentialsGetSuccess = (payload = {}) => ({
  type: PASSKEY_CREDENTIALS_GET_SUCCESS,
  payload,
});

export const passkeyCredentialsGetFailure = (payload = {}) => ({
  type: PASSKEY_CREDENTIALS_GET_FAILURE,
  payload,
});

export const passkeyCredentialsGet = () => (dispatch) => {
  dispatch({
    type: PASSKEY_CREDENTIALS_GET,
  });

  return daoPasskeyGet().then(
    (response) => dispatch(passkeyCredentialsGetSuccess(response.data)),
    (ex) => {
      dispatchEvent(passkeyCredentialsGetFailure(ex));
      Promise.reject(ex);
    }
  );
};

export const passkeyRegistrationPostSuccess = (payload = {}) => ({
  type: PASSKEY_REGISTRATION_POST_SUCCESS,
  payload,
});

export const passkeyRegistrationPostFailure = (payload = {}) => ({
  type: PASSKEY_REGISTRATION_POST_FAILURE,
  payload,
});

export const passkeyRegistrationPost = (payload = {}, authProp) => (dispatch) => {
  dispatch({
    type: PASSKEY_REGISTRATION_POST,
  });
  return daoPasskeyRegistrationPost(payload, authProp).then(
    (response) => dispatch(passkeyRegistrationPostSuccess(response.data)),
    (ex) => {
      dispatch(passkeyRegistrationPostFailure(ex));
      return Promise.reject(ex);
    }
  );
};
export const passkeyRegistrationOptionsPostSuccess = (payload = {}) => ({
  type: PASSKEY_REGISTRATION_OPTIONS_POST_SUCCESS,
  payload,
});

export const passkeyRegistrationOptionsPostFailure = (payload = {}) => ({
  type: PASSKEY_REGISTRATION_OPTIONS_POST_FAILURE,
  payload,
});

export const passkeyRegistrationOptionsPost = (authProp = '', payload = {},) => (dispatch) => {
  dispatch({
    type: PASSKEY_REGISTRATION_OPTIONS_POST,
  });
  return daoPasskeyRegistrationOptionsPost(payload, authProp).then(
    (response) => dispatch(passkeyRegistrationOptionsPostSuccess(response.data)),
    (ex) => {
      dispatch(passkeyRegistrationOptionsPostFailure(ex));
      return Promise.reject(ex);
    }
  );
};

export const passkeyDeleteSuccess = (payload = {}) => ({
  type: PASSKEY_CREDENTIAL_DELETE_SUCCESS,
  payload,
});

export const passkeyDeleteFailure = (payload = {}) => ({
  type: PASSKEY_CREDENTIAL_DELETE_FAILURE,
  payload,
});

export const passkeyDelete = (credentialId, authProp = '') => (dispatch) => {
  dispatch({
    type: PASSKEY_CREDENTIAL_DELETE,
  });
  return daoPasskeyDelete(credentialId, authProp).then(
    (response) =>
      dispatch(passkeyDeleteSuccess(response.data)),
    (ex) => {
      dispatch(passkeyDeleteFailure(ex));
      return Promise.reject(ex);
    }
  );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PASSKEY_CREDENTIALS_GET]: (state) => ({ ...state, isLoading: true }),

  [PASSKEY_CREDENTIALS_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {

      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [PASSKEY_CREDENTIALS_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PASSKEY_REGISTRATION_POST]: (state) => ({ ...state, isLoading: true }),

  [PASSKEY_REGISTRATION_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [PASSKEY_REGISTRATION_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PASSKEY_REGISTRATION_OPTIONS_POST]: (state) => ({ ...state, isLoading: true }),

  [PASSKEY_REGISTRATION_OPTIONS_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [PASSKEY_REGISTRATION_OPTIONS_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PASSKEY_CREDENTIAL_DELETE]: (state) => ({ ...state, isLoading: true }),

  [PASSKEY_CREDENTIAL_DELETE_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PASSKEY_CREDENTIAL_DELETE_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

};

const passkeyReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default passkeyReducer;
