import React from "react";
import PropTypes from "prop-types";
import {
  Map,
  Marker,
  GoogleApiWrapper as googleApiWrapper,
} from "google-maps-react";
import environment from "lib/environment";

export const GoogleMapContainer = ({
  containerStyle,
  mapTypeControl,
  streetViewControl,
  fullscreenControl,
  mapMarkerData,
  disableDefaultUI,
  zoomControl,
  clickedDeviceId,
  markerClick,
}) => {
  const adjustMap = (mapProps, map) => {
    const google = mapProps.google;
    const markers = mapProps.children;
    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => {
      const lat = marker.props.position.lat;
      const lng = marker.props.position.lng;
      bounds.extend(new google.maps.LatLng(lat, lng));
    });
    map.fitBounds(bounds);
    map.setOptions({
      styles: [
        {
          featureType: "poi",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
      ],
    });
  };

  return (
    <Map
      google={google}
      initialCenter={{ lat: 0, lng: 0 }}
      containerStyle={containerStyle}
      mapTypeControl={mapTypeControl}
      streetViewControl={streetViewControl}
      fullscreenControl={fullscreenControl}
      onReady={adjustMap}
      disableDefaultUI={disableDefaultUI}
      zoomControl={zoomControl}
      zoomControlOptions={{ style: google.maps.ZoomControlStyle.SMALL }}
      maxZoom={16}
    >
      {mapMarkerData.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.lat, lng: marker.lon }}
          title={marker.title}
          name={marker.id}
          zIndex={
            clickedDeviceId === marker.id
              ? google.maps.Marker.MAX_ZINDEX + 1
              : marker.zIndex
          }
          icon={{
            url: marker.iconRef
              ? marker.iconRef
              : environment.get().thirdPartyUrls.googleMapsDefaultMarker, // use the default google map marker if iconRef is not defined.
            scaledSize:
              clickedDeviceId === marker.id
                ? new google.maps.Size(28, 34)
                : null,
          }}
          onClick={markerClick}
        />
      ))}
    </Map>
  );
};

GoogleMapContainer.propTypes = {
  //   google: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
  mapTypeControl: PropTypes.bool.isRequired,
  streetViewControl: PropTypes.bool.isRequired,
  fullscreenControl: PropTypes.bool.isRequired,
  mapMarkerData: PropTypes.array.isRequired,
  markerClick: PropTypes.func.isRequired,
  clickedDeviceId: PropTypes.string,
  disableDefaultUI: PropTypes.bool,
  zoomControl: PropTypes.bool,
};

export default googleApiWrapper((props) => ({
  apiKey: props.id,
  language: props.language,
  containerStyle: props.containerStyle,
  mapTypeControl: props.mapTypeControl,
  streetViewControl: props.streetViewControl,
  fullscreenControl: props.fullscreenControl,
  mapMarkerData: props.mapMarkerData,
  disableDefaultUI: props.disableDefaultUI || false,
  zoomControl: props.zoomControl || true,
}))(GoogleMapContainer);
