import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EmailEditor from "../components/email-editor-view";
import { hasIllegalCharacters } from "core/input-validations";
import { stopEvent } from "lib/utility-functions";
import { organizationDomains } from "modules/organization-module";
import { emailVerificationRequestPost } from "modules/identity-module";
import { uiShowToastrMessage } from "modules/ui-module";
import {
  meData,
  meIsLoading,
  meIsOrganizationMember,
  meIsEmailChangeAllowed,
  meLocale,
  meRequestPatch,
  meForceSamlSsoLogin,
  meIsConflicted,
} from "modules/me-module";

export const EmailEditorContainer = () => {
  const {
    personData,
    isLoading,
    isOrganizationMember,
    domainUserName,
    recoveryEmail,
    userDomain,
    domainOptions,
    locale,
    isEmailChangeAllowed,
    forceSamlSsoLogin,
    isViewOnly,
  } = useSelector(getValuesFromStore);
  let [allValues, setAllValues] = useState({
    personData: Object.assign({}, personData),
    errors: {},
    isDirty: false,
    modalIsOpen: false,
    toastrType: "",
    toastrMessageId: "",
    domainValue: "",
    domainUserName: "",
    recoveryEmail: recoveryEmail || "",
    domainOptions: [],
    modifiedUserName: "",
    isRecoveryEmailModified: false,
    emailEditorClass: "",
    verifyEmailClass: "hidden",
  });
  let origState = {
    personData: Object.assign({}, personData),
    errors: {},
    isDirty: false,
    modalIsOpen: false,
    toastrType: "",
    toastrMessageId: "",
    domainValue: "",
    domainUserName: "",
    recoveryEmail: recoveryEmail || "",
    domainOptions: [],
    modifiedUserName: "",
    isRecoveryEmailModified: false,
    emailEditorClass: "",
    verifyEmailClass: "hidden",
  };
  let dispatch = useDispatch();
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      if (isOrganizationMember && userDomain.length && domainUserName.length) {
        setAllValues((prevState) => ({
          ...prevState,
          domainValue: userDomain,
          domainUserName: domainUserName,
        }));
      }
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mounted.current) {
      if (isOrganizationMember) {
        setAllValues((prevState) => ({
          ...prevState,
          domainValue: userDomain,
          domainUserName,
        }));
      }
    }
  }, [isOrganizationMember, userDomain, domainUserName]);

  const exceedsMaxEmailLength = (field, value) => {
    let emailAddress = value;
    if (field.indexOf("domainUserName") > -1) {
      emailAddress = `${value}@${allValues.domainValue}`;
    }
    return emailAddress.length > 128;
  };

  const validateUserInput = (activeField, value, onBlurCheck) => {
    let errorText = "";
    const field =
      activeField === "domainSelection" ? "domainUserName" : activeField;
    const errors = { ...allValues.errors };
    if (errors[field]) {
      delete errors[field];
      setAllValues((prevState) => ({
        ...prevState,
        errors: { ...errors },
      }));
    }

    if (hasIllegalCharacters(value)) {
      errorText = "shared.illegal-character-entry";
    }

    if (
      !errorText.length &&
      field.indexOf("domainUserName") > -1 &&
      activeField !== "domainSelection" &&
      typeof domainOptions !== "undefined" &&
      domainOptions.length &&
      value.indexOf("@") > -1
    ) {
      errorText = "email-editor.validation-error-domain-entered";
    }

    if (
      !errorText.length &&
      onBlurCheck &&
      value.length &&
      (field === "recoveryEmail" || field === "userName") &&
      !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    ) {
      errorText = "email-editor.validation-error-email";
    }

    if (!errorText.length && exceedsMaxEmailLength(activeField, value)) {
      errorText = "email-editor.validation-error-email-length-exceeded";
    }

    if (errorText.length) {
      errors[field] = errorText;

      setAllValues((prevState) => ({
        ...prevState,
        errors: { ...errors },
      }));
      return false;
    }
    return true;
  };

  const openModal = (e) => {
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: true,
    }));
  };

  const closeModal = (e) => {
    if (isOrganizationMember && userDomain.length && domainUserName.length) {
      setAllValues((prevState) => ({
        ...origState,
        errors: {},
        domainValue: userDomain,
        domainUserName: domainUserName,
        modalIsOpen: false,
        recoveryEmail,
      }));
    }

    else {
      setAllValues(origState);
    }
  };

  const completeProcess = () => {
    showEmailEditor();
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: false,
    }));
  };

  const displayToastrMessage = (toastrType, toastrMessageId) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId));
  };

  const onBlur = (e) => {
    const field = (e.target.name || e.target.id).split("-")[0];
    const value = e.target.value;
    validateUserInput(field, value, true);
    return true;
  };

  const onChange = (e) => {
    let field = (e.target.name || e.target.id).split("-")[0];
    let value = e.target.value;
    const stateUpdates = {};
    stateUpdates.isDirty = true;

    const personData = { ...allValues.personData };

    if (field.indexOf("domainUserName") > -1) {
      if (validateUserInput(field, value, false)) {
        stateUpdates.domainUserName = value;
        field = "userName";
        value = `${value}@${allValues.domainValue}`;
      } else {
        return false;
      }
    }

    if (field !== "recoveryEmail") {
      personData[field] = value;
    }

    if (validateUserInput(field, value, false)) {
      if (field !== "recoveryEmail") {
        stateUpdates.personData = personData;
        stateUpdates.modifiedUserName = value;
      } else {
        stateUpdates.isRecoveryEmailModified = true;
        stateUpdates.recoveryEmail = value;
      }
    }

    setAllValues((prevState) => ({
      ...prevState,
      ...stateUpdates,
    }));

    return true;
  };

  const onDomainChange = (e) => {
    stopEvent(e);
    const stateChanges = { ...allValues, isDirty: true };
    const personData = { ...allValues.personData };
    const domainVal = e.target.value;
    if (domainVal) {
      const completeUserName = `${allValues.domainUserName}@${domainVal}`;
      if (validateUserInput("domainSelection", completeUserName, false)) {
        personData["userName"] = completeUserName;
        stateChanges.personData = personData;
        stateChanges.domainValue = domainVal;
        stateChanges.modifiedUserName = completeUserName;
      }

      if (domainVal === userDomain) {
        stateChanges.isDirty = false;
      }
    }

    setAllValues(stateChanges);
    return true;
  };

  const sendEmailVerificationRequest = () =>
    dispatch(
      emailVerificationRequestPost(locale, {
        email: allValues.modifiedUserName,
      })
    );

  const handlePrimaryEmailVerification = (e, showSuccessToastrMessage = true) => {
    let showVerificationStep = false;
    if (typeof e !== "undefined") {
      stopEvent(e);
    } else {
      showVerificationStep = true;
    }

    sendEmailVerificationRequest().then(
      () => {
        if (showSuccessToastrMessage) {
          displayToastrMessage(
            "success",
            "email-editor.verification-email-send-successful"
          );
        }
        if (showVerificationStep) {
          toggleEmailEditor(true);
        }
      },
      () => {
        displayToastrMessage(
          "error",
          "email-editor.verification-email-send-failed"
        );
      }
    );
  };

  const getRecoveryEmailPatchContent = () => {
    let patchContent = "";
    if (allValues.recoveryEmail.length) {
      patchContent = {
        emails: [
          {
            value: allValues.recoveryEmail,
            type: "recovery",
            primary: false,
          },
        ],
      };
    } else {
      patchContent = {
        meta: { attributes: ["emails"] },
      };
    }
    return patchContent;
  };

  const onSave = () => {
    if (allValues.isRecoveryEmailModified) {
      return dispatch(meRequestPatch(getRecoveryEmailPatchContent())).then(
        () => {
          displayToastrMessage(
            "success",
            "email-editor.recovery-email-save-successful"
          );
          if (allValues.modifiedUserName.length) {
            handlePrimaryEmailVerification();
          } else {
            setAllValues((prevState) => ({
              ...prevState,
              modalIsOpen: false,
            }));
          }
        },
        () => {
          displayToastrMessage(
            "error",
            "email-editor.recovery-email-save-failed"
          );
        }
      );
    } else if (
      !allValues.isRecoveryEmailModified &&
      allValues.modifiedUserName.length
    ) {
      return handlePrimaryEmailVerification();
    }
    return true;
  };

  const enableSubmit = () =>
    Object.keys(allValues.errors).length === 0 &&
    allValues.isDirty &&
    !isLoading &&
    !isViewOnly &&
    allValues.personData.userName.length > 0 &&
    allValues.personData.userName.charAt(0) !== "@";

  const showEmailEditor = (e) => {
    stopEvent(e);
    toggleEmailEditor(false);
    return true;
  };

  const toggleEmailEditor = (showEmailEditor) => {
    const emailEditorClass = showEmailEditor ? "hidden" : "";
    const verifyEmailClass = showEmailEditor ? "" : "hidden";

    setAllValues((prevState) => ({
      ...prevState,
      emailEditorClass,
      verifyEmailClass,
    }));
  };

  return (
    <EmailEditor
      personData={allValues.personData}
      isEmailChangeAllowed={isEmailChangeAllowed}
      onEditLinkClick={openModal}
      modalIsOpen={allValues.modalIsOpen}
      closeModal={closeModal}
      completeProcess={completeProcess}
      onBlur={onBlur}
      onChange={onChange}
      onSave={onSave}
      enableSubmit={enableSubmit()}
      isLoading={isLoading}
      errors={allValues.errors}
      domainValue={allValues.domainValue}
      domainUserName={allValues.domainUserName}
      recoveryEmail={allValues.recoveryEmail}
      domainOptions={domainOptions}
      onDomainChange={onDomainChange}
      showEmailEditor={showEmailEditor}
      modifiedUserName={allValues.modifiedUserName}
      handlePrimaryEmailVerification={handlePrimaryEmailVerification}
      emailEditorClass={allValues.emailEditorClass}
      verifyEmailClass={allValues.verifyEmailClass}
      forceSamlSsoLogin={forceSamlSsoLogin}
      forceSamlSsoClass={forceSamlSsoLogin ? "hidden" : ""}
      isViewOnly={isViewOnly}
    />
  );
};

export const getValuesFromStore = (state) => {
  const personData = meData(state);
  const isOrganizationMember = meIsOrganizationMember(state);
  const domainOptions = [];
  const orgDomains = organizationDomains(state);
  let domainUserName = "";
  let userDomain = "";
  if (isOrganizationMember && orgDomains.length) {
    orgDomains.forEach((domain) => {
      if (!domain.token) {
        domainOptions.push({ value: domain.name, label: `@${domain.name}` });
      }
    });
    // during unit testing personData may not have a 'userName' property, so this check is necessary.
    const userNameParts = personData.userName
      ? personData.userName.split("@")
      : ["", ""];
    domainUserName = userNameParts[0];
    userDomain = userNameParts[1];
  }
  const emails = personData.emails || [];
  const recoveryEmailObj = emails.find(
    (email) => email.type === "recovery" && !email.primary
  );
  const recoveryEmail = recoveryEmailObj ? recoveryEmailObj.value : "";

  return {
    personData,
    isLoading: meIsLoading(state),
    isOrganizationMember,
    domainUserName,
    recoveryEmail,
    userDomain,
    domainOptions,
    locale: meLocale(state),
    isEmailChangeAllowed: meIsEmailChangeAllowed(state),
    forceSamlSsoLogin: meForceSamlSsoLogin(state),
    isViewOnly: meIsConflicted(state),
  };
};

export default EmailEditorContainer;
