import api from "dao/providers/axios";
import appconfig from "config/appconfig";

const passkeyRegistrationOptions = appconfig.endpoints.passkeyRegistrationOptions;
const passkeyRegistration = appconfig.endpoints.passkeyRegistration;
const passkeys = appconfig.endpoints.passkeys;
const passkeyByCredentialId = appconfig.endpoints.passkeyByCredentialId;


export const daoPasskeyRegistrationOptionsPost = (payload, authProp) => api.post(passkeyRegistrationOptions, payload, { headers: { Authorization: `${authProp}` } });

export const daoPasskeyRegistrationPost = (payload, authProp) => api.post(passkeyRegistration, payload, { headers: { Authorization: `${authProp}` } });

export const daoPasskeyGet = () => api.get(passkeys);

export const daoPasskeyDelete = (credentialId, authProp) => api.delete(passkeyByCredentialId(credentialId), { headers: { Authorization: `${authProp}` } });