/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import DeveloperTools from "../components/developer-tools-view";
import {
  meEnhancedSecurity,
  meRequestPatch,
  meTfaEnrolled,
} from "modules/me-module";
import environment from "lib/environment";
import appconfig from "config/appconfig";
import Session from "lib/session";
import { useState, useEffect } from "react";
import { uiShowToastrMessage, uiShowConfirmDialog } from "modules/ui-module";
import { stopEvent } from "lib/utility-functions";
import auth from "lib/profile-auth";
import { personalAccessTokenGet, personalAccessTokenDelete, personalAccessTokenIsLoading } from "modules/personal-access-token-module";
import { meLocale } from "modules/me-module";
import moment from "moment";
import { getAPIError } from "lib/utility-functions";

const serviceUrl = environment.get().api.serviceUrl;

export const DeveloperToolsContainer = () => {
  let MAX_TOKEN_LIMIT = 20;
  const { enhancedSecurity, tfaEnrolled, meLocale, isloading } = useSelector(getValuesFromStore);
  const [disableCreateToken, setDisableCreateToken] = useState(false);
  const [changeInEnhancedSecurity, setChangeInEnhancedSecurity] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const dispatch = useDispatch();

  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const getEnrollmentURL = (addSetupParam) => {
    let redirectURL = location.href;
    if (addSetupParam) {
      redirectURL += (location.search ? "&" : "?") + appconfig.storage.enhancedSecuritySetup + "=1";
    }
    return `${serviceUrl}enroll2fa?service=${encodeURIComponent(redirectURL)}`;
  };

  const disabletokenCreation = () => {
    const totalTokenCount = tokenList.length >= MAX_TOKEN_LIMIT;
    if (totalTokenCount) {
      setDisableCreateToken(true);
    }
    else {
      setDisableCreateToken(false);
    }
  };

  const setUpEnhancedSecurity = (e) => () => {
    Session.setItem(appconfig.storage.enhancedSecuritySetup, "1");
    const enrollmentURL = getEnrollmentURL(true);
    window.location.assign(enrollmentURL);
    return false;
  };

  const toggleEnhancedSecurity = (e) => () => {
    stopEvent(e);
    const enhancedSecurityEnabled = !enhancedSecurity;
    const payload = {};
    payload[appconfig.scimSchemas.getGo] = {
      enhancedSecurity: enhancedSecurityEnabled,
    };
    return dispatch(meRequestPatch(payload)).then(
      () => {
        const forceReload = !!(auth.token && auth.token.loa !== 3);
        if (enhancedSecurityEnabled && forceReload) {
          Session.setItem("minLoa", "3");
          auth.clear();
          window.location.assign(window.location.href.split("?")[0]);
        }
        setChangeInEnhancedSecurity(true);
      },
      () => {
        displayToastrMessage(
          "error",
          "enhanced-security.enhanced-security-save-failed"
        );
      }
    );
  };

  const fetchTokenList = () => {
    dispatch(personalAccessTokenGet()).then(
      (response) => {
        const tokenData = response.payload?.tokens || [];
        const lang = meLocale ? meLocale.split("_")[0] : "en";
        tokenData.forEach((data) => {
          if (data.createdOn)
            data.createdOn = moment(data.createdOn).locale(lang).format("LLL");
        });
        tokenData.sort((a, b) => {
          const dateA = new Date(a.createdOn);
          const dateB = new Date(b.createdOn);
          return (dateB > dateA ? 1 : -1);
        });
        setTokenList(tokenData);
      },
      (error) => {
        const errorObj = getAPIError(error);
        const errorCode = errorObj && errorObj.length === 1 ? errorObj[0].code : '';
        if (errorCode === 'not.authenticated') {
          handleXHRResult(false, 'token-edit.token-add-failed-dup-name');
        }
        else {
          handleXHRResult(false, 'token-edit.token-add-failed');
        }
      }
    );
  };

  const handleXHRResult = (success, messageId, toastrParams = null) => {
    const toastrType = success ? "success" : "error";
    displayToastrMessage(toastrType, messageId, toastrParams);
  };

  const onDelete = (tokenId) => (e) => {
    stopEvent(e);
    const tokenName = tokenList.find((data) => data.id === tokenId).name;
    dispatch(
      uiShowConfirmDialog(
        "deleteToken",
        () => doDelete(tokenId),
        () => true,
        false,
        { name: tokenName },
        "danger"
      ));
  };

  const doDelete = (tokenId) => {
    const tokenName = tokenList.find((data) => data.id === tokenId).name;
    if (!!tokenId) {
      dispatch(personalAccessTokenDelete(tokenId)).then(
        () => {
          handleXHRResult(true, "token-edit.delete", { name: tokenName });
          fetchTokenList();
        },
        () => {
          handleXHRResult(true, " token-edit.token-delete-failed");
        }
      );
    }
  };

  useEffect(() => {
    fetchTokenList();
  }, []);

  useEffect(() => {
    disabletokenCreation();
  }, [tokenList]);

  return (
    <DeveloperTools
      setUpEnhancedSecurityOnClick={setUpEnhancedSecurity()}
      enhancedSecurity={enhancedSecurity}
      meTfaEnrolled={tfaEnrolled}
      disableCreateToken={disableCreateToken}
      allTokens={tokenList}
      onDelete={onDelete}
      toggleEnhancedSecurity={toggleEnhancedSecurity()}
      changeInEnhancedSecurity={changeInEnhancedSecurity}
      isloading={isloading}
    />
  );
};

export const getValuesFromStore = (state) => ({
  meLocale: meLocale(state),
  enhancedSecurity: meEnhancedSecurity(state) || false,
  tfaEnrolled: meTfaEnrolled(state),
  isloading: personalAccessTokenIsLoading(state),
}
);

export default DeveloperToolsContainer;
