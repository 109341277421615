import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import st from "core/shared-translations";
import {
  NavigationRail,
  NavigationRailItem,
} from "@getgo/chameleon-web-react-wrapper";
import {
  EncryptionOutlinedIcon,
  PersonOutlinedIcon,
  PersonFilledIcon,
  EncryptionFilledIcon,
  CodeOutlinedIcon,
} from "@getgo/chameleon-icons/react";
import classes from "./main-nav-view.scss";

export const SideNav = ({ compareRoutes, movetoDifferentPage }) => {
  const intl = useIntl();
  const navLinks = [
    {
      to: "/personal-info",
      displayName: intl.formatMessage(st["shared.personal.info"]),
      activeIcon: <PersonFilledIcon />,
      inactiveIcon: <PersonOutlinedIcon />,
    },
    {
      to: "/signin-security",
      displayName: intl.formatMessage(st["nav.navbar.signinsecurity"]),
      activeIcon: <EncryptionFilledIcon />,
      inactiveIcon: <EncryptionOutlinedIcon />,
    },
    {
      to: "/developer-tools",
      displayName: intl.formatMessage(st["nav.navbar.developerTools"]),
      activeIcon: <CodeOutlinedIcon />,
      inactiveIcon: <CodeOutlinedIcon />,
    },
  ];
  return (
    <div className={classes["sideNavToggle"]}>
      <NavigationRail
        collapse-label="Collapse"
        aria-label="side-nav"
        //styles through className doesn't work directly in core component of chameleon
        style={{
          height: "calc(100vh - 46px)",
          zIndex: "2",
          bottom: "0",
          position: "relative",
          display: "inline-flex",
          marginTop: "-3px"
        }}
      >
        {navLinks.map(({ to, displayName, activeIcon, inactiveIcon }) => (
          <NavigationRailItem
            key={displayName}
            selected={compareRoutes(to)}
            icon={compareRoutes(to) ? activeIcon : inactiveIcon}
            onClick={() => movetoDifferentPage(to)}
          >
            {displayName}
          </NavigationRailItem>
        ))}
      </NavigationRail>
    </div>

  );
};

SideNav.propTypes = {
  compareRoutes: PropTypes.func.isRequired,
  movetoDifferentPage: PropTypes.func.isRequired,
};

export default SideNav;
