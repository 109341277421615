import React, { useState, useRef, useEffect } from "react";
import AcceptInvitation from "../components/accept-invitation-view";
import {
  eaIsLoading,
  extAdminMeRequestWithInvitationsGet,
  extAdminInvitationAccept,
  extAdminInvitationDecline,
  getInvitingAccount,
  getMyUserKey,
  getInvitingAccountKey,
  getInvitingAccountName,
  getConflictingAccountDetails,
  getInvitedLicenses,
  isInvitationValid,
  setInvitationId,
  getMyEmailAddress,
} from "modules/extadmin-module";
import { stopEvent, scrollToTop } from "lib/utility-functions";
import {
  uiShowConfirmDialog,
  uiShowToastrMessage,
  uiShowMessageBanner,
  uiShowMessageBannerToggle,
  uiMessageBannerVisible,
} from "modules/ui-module";
import environment from "lib/environment";
import appconfig from "config/appconfig";
import {
  setHandledErrorCodes,
  removeHandledErrorCodes,
} from "lib/error-message-handling";
import auth from "lib/profile-auth";
import { useSelector, useDispatch } from "react-redux";

const myAccountUrl = environment.get().myAccount_url;

export const AcceptInvitationContainer = () => {
  let {
    invitationId,
    isLoading,
    invitation,
    invitingAccountKey,
    myUserKey,
    conflictingAccountNames,
    conflictingLicenses,
    managerInAccounts,
    adminInAccounts,
    invitingAccountName,
    invitedLicenses,
    defaultIsInvitationValid,
    messageBannerVisible,
    invitedUserEmail,
    logout,
  } = useSelector(getValuesFromStore);
  let dispatch = useDispatch();
  const [invitationValid, setInvitationValid] = useState(false);
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      if (invitationId) {
        dispatch(setInvitationId(invitationId));
        dispatch(extAdminMeRequestWithInvitationsGet());
      } else {
        setInvitationValid(false);
      }
      mounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (mounted.current) {
      if (defaultIsInvitationValid !== invitationValid) {
        setInvitationValid(defaultIsInvitationValid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationValid, defaultIsInvitationValid]);

  const redirectToMyAccount = () => {
    location.href = environment.get().myAccount_url;
  };

  const getErrorCodesArray = () => {
    return [500, "invitation.not.found"];
  };

  const handleFailedActionResponse = (ex = {}) => {
    scrollToTop();
    if (ex && ex.status) {
      if (ex.status === 404) {
        dispatch(uiShowMessageBanner("userInvitationExpired"));

        setInvitationValid(false);
      } else if (ex.status === 500) {
        dispatch(uiShowMessageBanner("userInvitationError"));
      }
    }
    removeHandledErrorCodes(getErrorCodesArray());
  };

  const getParams = () => {
    return {
      accountKey: invitingAccountKey,
      userKey: myUserKey,
      invitationId: invitationId,
    };
  };

  const acceptInvitation = (e) => {
    stopEvent(e);

    setHandledErrorCodes(getErrorCodesArray());

    if (messageBannerVisible) {
      dispatch(uiShowMessageBannerToggle());
    }
    return dispatch(extAdminInvitationAccept(getParams())).then(
      () => {
        dispatch(
          uiShowConfirmDialog(
            "accept",
            () => true,
            () => true,
            true,
            {
              invitingAccountName: invitingAccountName,
              myAccountUrl: myAccountUrl,
            }
          )
        );
        removeHandledErrorCodes(getErrorCodesArray());
      },
      (ex) => {
        handleFailedActionResponse(ex);
      }
    );
  };

  const showDeclineDialog = () => {
    dispatch(uiShowConfirmDialog("decline",
      declineInvitation,
      () => true,
      false,
      {
        invitingAccountName: invitingAccountName,
      }
    )
    );
  };

  const declineInvitation = (e) => {
    stopEvent(e);

    setHandledErrorCodes(getErrorCodesArray());

    if (messageBannerVisible) {
      dispatch(uiShowMessageBannerToggle());
    }
    return dispatch(extAdminInvitationDecline(getParams())).then(
      () => {
        removeHandledErrorCodes(getErrorCodesArray());
        redirectToMyAccount();
      },
      (ex) => {
        handleFailedActionResponse(ex);
      }
    );
  };

  const getFirstProductNames = () => {
    if (!invitedLicenses || invitedLicenses.length < 1) {
      return "";
    }
    let firstProductNames = [];
    const tempProductArray = invitedLicenses.slice(0, -1);
    tempProductArray.forEach((product) =>
      firstProductNames.push(product.description)
    );
    return firstProductNames.join(", ");
  };

  const getLastProductName = () => {
    if (!invitedLicenses || !invitedLicenses.length) {
      return "";
    }
    return invitedLicenses[invitedLicenses.length - 1].description;
  };

  const getParsedAccountNames = (accountNamesArray) => {
    const parsedAccountNames = [];
    if (!accountNamesArray || !accountNamesArray.length) {
      return parsedAccountNames;
    }
    if (accountNamesArray.length === 1) {
      parsedAccountNames.push(accountNamesArray[0]);
    } else {
      parsedAccountNames[1] = accountNamesArray[accountNamesArray.length - 1];
      const firstAccountNames = [];
      const tempAccountNamesArray = accountNamesArray.slice(0, -1);
      tempAccountNamesArray.forEach((accountName) =>
        firstAccountNames.push(accountName)
      );
      parsedAccountNames[0] = firstAccountNames.join(", ");
    }
    return parsedAccountNames;
  };

  const getConflictingAccountNames = () => {
    let firstAccountNames = "";
    let lastAccountName = "";
    if (conflictingAccountNames && conflictingAccountNames.length) {
      lastAccountName =
        conflictingAccountNames[conflictingAccountNames.length - 1];
      firstAccountNames = conflictingAccountNames.slice(0, -1).join(", ");
    }
    return { firstAccountNames, lastAccountName };
  };

  return (
    <AcceptInvitation
      invitedLicenseCount={invitedLicenses.length}
      firstProductNames={getFirstProductNames()}
      lastProductName={getLastProductName()}
      invitingAccountName={invitingAccountName}
      managerInAccounts={getParsedAccountNames(managerInAccounts)}
      adminInAccounts={getParsedAccountNames(adminInAccounts)}
      acceptInvitation={acceptInvitation}
      showDeclineDialog={showDeclineDialog}
      noOfConflictingAccounts={conflictingAccountNames.length}
      conflictingAccountNames={getConflictingAccountNames()}
      conflictingLicenses={conflictingLicenses}
      invitedLicenses={invitedLicenses}
      isInvitationValid={invitationValid}
      eaIsLoading={isLoading}
      invitedUserEmail={invitedUserEmail}
      logout={logout}
      redirectToMyAccount={redirectToMyAccount}
    />
  );
};
export const getValuesFromStore = (state) => {
  const urlParams = new URLSearchParams(window.location.search);
  const invitationId = urlParams.get("id") || "";
  const conflictingAccountDetails = getConflictingAccountDetails(state);
  return {
    invitationId,
    isLoading: eaIsLoading(state),
    invitation: getInvitingAccount(state) || {},
    invitingAccountKey: getInvitingAccountKey(state) || "",
    myUserKey: getMyUserKey(state) || "",
    conflictingAccountNames:
      conflictingAccountDetails.conflictingAccountNames || [],
    conflictingLicenses: conflictingAccountDetails.conflictingLicenses || [],
    managerInAccounts: conflictingAccountDetails.managerInAccounts || [],
    adminInAccounts: conflictingAccountDetails.adminInAccounts || [],
    invitingAccountName: getInvitingAccountName(state) || "",
    invitedLicenses: getInvitedLicenses(state, invitationId) || [],
    defaultIsInvitationValid: isInvitationValid(state),
    messageBannerVisible: uiMessageBannerVisible(state) || false,
    invitedUserEmail: getMyEmailAddress(state) || "",
    logout: auth.logout.bind(auth),
  };
};

export default AcceptInvitationContainer;
