import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert as AlertBootstrap } from "react-bootstrap";
import classes from "./alert.scss";
import appconfig from "config/appconfig";
import { CloseOutlinedIcon } from '@getgo/chameleon-icons/react';

export const Alert = ({ type, icon, body, buttons, handleDismiss, isCloseButton = true }) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    handleDismiss();
  };
  return (
    <div>
      <AlertBootstrap
        variant={type}
        transition={false}
        show={show}
        className={classes.alertBox}
      >
        <div className={classes.alertContainer}>
          <div className={classes.content}>
            {icon}
            &nbsp;
            {body}
            &nbsp;
            {buttons}
          </div>
        </div>
        {isCloseButton &&
          <div
            className={classes.closeContainer}
            onClick={handleClose}
            role="presentation"
          >
            <CloseOutlinedIcon color="type-color-inverted" />
          </div>}
      </AlertBootstrap>
    </div>
  );
};

Alert.defaultProps = {
  type: appconfig.alertTypes.WARNING,
  handleDismiss: () => { },
};

Alert.propTypes = {
  type: PropTypes.oneOf(["warning", "danger", "success", "info"]),
  icon: PropTypes.any,
  body: PropTypes.any.isRequired,
  buttons: PropTypes.any,
  handleDismiss: PropTypes.func,
  isCloseButton: PropTypes.bool,
};

export default Alert;
