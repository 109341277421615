import {
  daoTfaMeGet,
} from 'dao/tfa-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const TFA_ME_GET = 'TFA_ME_GET';
export const TFA_ME_GET_SUCCESS = 'TFA_ME_GET_SUCCESS';
export const TFA_ME_GET_FAILURE = 'TFA_ME_GET_FAILURE';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  data: {
    enrollmentstatus: false,
  },

  // generic or global properties go here
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const tfaIsLoading = (state) => state.tfa.isLoading;
export const tfaMeData = (state) => state.tfa;

export const enrolledInTfa = (state) => {
  const tfaData = tfaMeData(state);
  return (tfaData && tfaData.enrollmentStatus) || false;
};

// ------------------------------------
// Actions
// ------------------------------------
export const tfaMeReceiveGetSuccess = (payload = {}) => ({
  type: TFA_ME_GET_SUCCESS,
  payload,
});

export const tfaMeReceiveGetFailure = (payload = {}) => ({
  type: TFA_ME_GET_FAILURE,
  payload,
});

export const tfaMeRequestGet = () => (dispatch) => {
  dispatch({
    type: TFA_ME_GET,
  });

  return daoTfaMeGet()
    .then(
      response => dispatch(tfaMeReceiveGetSuccess(response.data)),
      ex => dispatch(tfaMeReceiveGetFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [TFA_ME_GET]: (state) => ({...state, isLoading: true}),
  [TFA_ME_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object' && typeof action.payload.enrollmentstatus !== 'undefined') {
      return {
        ...state,
        enrollmentStatus: action.payload.enrollmentstatus,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [TFA_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const tfaReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default tfaReducer;
