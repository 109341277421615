import React from 'react';
import PropTypes from 'prop-types';
import { Typography, ButtonLink } from "@getgo/chameleon-web-react-wrapper";
import {
  FormattedMessage,
  defineMessages,
} from "react-intl";
import classes from './social-connect-to-link.scss';

const t = defineMessages({
  "social-accounts.disconnect-link": {
    id: "social-accounts.disconnect-link",
    defaultMessage: "Disconnect",
    description: "Link text displayed for disconnecting the user's associated social account. ",
  }
});

export const SocialConnectToLink = ({
  socialLink,
  disableNewConnect = false,
  socialEmail,
  onDisconnect,
  connectToText,
  icon,
  connectAsText,
  isViewOnly = false
}) => (
  <div id="social-item-container" className={classes["connectToSocialBlock"]}>
    {!socialEmail && !disableNewConnect &&
      <div className={classes["connectToIconGroup"]}>
        {icon}
        <ButtonLink
          variant="neutral"
          size="small"
          href={socialLink}
          disabled={isViewOnly}
        >
          {connectToText}
        </ButtonLink>
      </div>}
    {socialEmail &&
      <div className={classes["connectedAsContainer"]}>
        <div className={classes["connectAsIconGroup"]}>
          {icon}
          {connectAsText}
        </div>
        <ButtonLink
          size="small"
          variant="neutral"
          disabled={isViewOnly}
          onClick={onDisconnect}
        >
          <FormattedMessage {...t['social-accounts.disconnect-link']} />
        </ButtonLink>
      </div>}
  </div>
);

SocialConnectToLink.propTypes = {
  socialLink: PropTypes.string.isRequired,
  disableNewConnect: PropTypes.bool.isRequired,
  socialEmail: PropTypes.string,
  onDisconnect: PropTypes.func,
  connectToText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  connectAsText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  icon: PropTypes.node.isRequired,
  isViewOnly: PropTypes.bool.isRequired
};

export default SocialConnectToLink;
