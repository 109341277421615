import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import classes from './key-val-display.scss';

export const KeyValDisplay = ({ heading, val }) => {
  return (
    <div className={classes['keyValContainer']}>
      <Typography variant="caption-medium" color="type-color-secondary">
        {heading}
      </Typography>
      <Typography variant="body-medium" color="type-color-primary">
        {val}
      </Typography>
    </div>
  );
};

KeyValDisplay.propTypes = {
  heading: PropTypes.object.isRequired,
  val: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired
};

export default KeyValDisplay;
