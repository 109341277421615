import React from "react";
import PropTypes from "prop-types";
import { Typography, IconButton } from "@getgo/chameleon-web-react-wrapper";
import { KeyFilledIcon, TrashOutlinedIcon } from '@getgo/chameleon-icons/react';
import classes from './key-val-display.scss';
import { FormattedMessage, defineMessages } from "react-intl";


const t = defineMessages({
  'passkey-detail.last-used': {
    id: 'passkey-detail.last-used',
    defaultMessage: 'Last used: {lastUsedTime}',
    description: 'Date string to display when the passskey was last used',
  },
  'passkey-detail.Created': {
    id: 'passkey-detail.Created',
    defaultMessage: 'Created: {createdTime}',
    description: 'Date string to display when the passskey was created',
  },
});

export const PasskeyDetail = ({ name, lastUsedTime, createdTime, credentialId, showCreateTime = false, onDelete }) => {
  return (
    <div className={classes["passkeyDetailContainer"]}>
      <KeyFilledIcon size="26px" />
      <div className={classes["passkeyInfoBlock"]}>
        <div>
          <Typography variant="body-medium">{name}</Typography>
          {showCreateTime &&
            <Typography variant="body-small" color="type-color-secondary">
              <FormattedMessage
                {...t['passkey-detail.Created']}
                values={{ createdTime }}
              />
            </Typography>}
          <Typography variant="body-small" color="type-color-secondary">
            <FormattedMessage
              {...t['passkey-detail.last-used']}
              values={{ lastUsedTime }}
            />
          </Typography>
        </div>
        {showCreateTime &&
          <IconButton data-testid="delete-button" onClick={onDelete(credentialId)}>
            <TrashOutlinedIcon />
          </IconButton>}
      </div>
    </div>
  );
};

PasskeyDetail.propTypes = {
  name: PropTypes.string.isRequired,
  credentialId: PropTypes.string.isRequired,
  lastUsedTime: PropTypes.string.isRequired,
  createdTime: PropTypes.string.isRequired,
  showCreateTime: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default PasskeyDetail;
