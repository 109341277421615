// Illegal characters in standard user input fields (name, email, etc.)
const illegalFieldInputCharacters = ['%', '&', '<', '>', '[', ']', '{', '}'];

// Filter for illegal characters
export const hasIllegalCharacters = (inputText) => (illegalFieldInputCharacters.some((char) => inputText.indexOf(char) > -1));

// Numbers only input validation
export const numbersOnly = (inputText) => inputText.match(/^[0-9]+$/);

// URL validation from http://regexbuddy.com/ - modified to require http:// or https://
export const isValidUrl = (inputText) => inputText.match(/(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/);
