import React from 'react';
import PropTypes from 'prop-types';
import st from 'core/shared-translations';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  defineMessages,
  injectIntl,
} from 'react-intl';
import classes from './signin-security.scss';
import { Typography, Button, InputField } from '@getgo/chameleon-web-react-wrapper';
import { SendOutlinedIcon } from '@getgo/chameleon-icons/react';

export const EmailVerificationEditor = ({
  intl,
  showEmailEditor,
  onBlur,
  onChange,
  onConfirm,
  enableSubmit,
  isLoading,
  errors,
  userName,
  verificationCode,
  handlePrimaryEmailVerification,
}) => (
  <div id="email-verification-content" className={classes["emailVerificaionContainer"]}>
    <Typography variant="body-medium">
      <FormattedHTMLMessage {...st['shared.email-verification.user-instruction']} values={{ emailAddress: userName }} />
    </Typography>
    <InputField
      name="verificationCode"
      value={verificationCode}
      onChange={onChange}
      onBlur={onBlur}
      fullwidth
      error={!!errors.verificationCode}
      helperText={
        !!errors.verificationCode &&
        intl.formatMessage(st[errors.verificationCode] || t[errors.verificationCode])
      }
    >
      {intl.formatMessage(st['shared.email-verification.verification-code'])}
    </InputField>

    <Typography variant="body-small">
      <FormattedMessage {...st['shared.email-verification.info-message']} />
    </Typography>
    <Button
      variant="secondary"
      trailingIcon={<SendOutlinedIcon />}
      onClick={handlePrimaryEmailVerification}
    >
      <FormattedMessage {...st['shared.email-verification.resend-verification-email-link-text']} />
    </Button>
    <div id="cta" className={classes["ctaSectionConatiner"]}>
      <div className={classes["ctaSection"]}>
        <Button
          id="email-verification-confirm-button"
          disabled={!enableSubmit}
          onClick={onConfirm}
        >
          {isLoading ?
            <FormattedMessage {...st['shared.confirming']} /> :
            <FormattedMessage {...st['shared.confirm']} />}
        </Button>
        <Button
          id="email-verification-back-button"
          onClick={showEmailEditor}
          variant="tertiary"
        >
          <FormattedMessage {...st['shared.back']} />
        </Button>
      </div>
    </div>
  </div>
);

EmailVerificationEditor.propTypes = {
  intl: PropTypes.object.isRequired,
  personData: PropTypes.object.isRequired,
  showEmailEditor: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  enableSubmit: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  verificationCode: PropTypes.string,
  userName: PropTypes.string,
  handlePrimaryEmailVerification: PropTypes.func.isRequired,
};

export default injectIntl(EmailVerificationEditor);
