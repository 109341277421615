import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const apiBaseUrl = { baseURL: environment.get().api.serviceUrl };

const socialAccountsByUserIdEndPoint = appconfig.endpoints.socialAccountsByUserId;
const socialSignInLinksEndPoint = appconfig.endpoints.socialSignInLinks;
const socialAccountDeleteByUserIdEndPoint = appconfig.endpoints.socialAccountDeleteByUserId;


export const daoSocialAccountsByUserIdGet = (userId) => api.get(socialAccountsByUserIdEndPoint(userId), apiBaseUrl);

export const daoSocialSignInLinksGet = (params) => api.get(socialSignInLinksEndPoint(params), apiBaseUrl);

export const daoSocialAccountByUserIdDelete = (userId, accountName) => api.delete(`${socialAccountDeleteByUserIdEndPoint(userId)}${accountName}`, apiBaseUrl);
