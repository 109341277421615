import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  uiAlertAuthenticationWarningTime,
  uiAlertAuthenticationWarningToggle,
} from "modules/ui-module";
import AlertAuthWarning from "components/alert-auth-warning-view";
import Session from "lib/session";
import config from "config/appconfig";

export const mapStateToProps = (state) => ({
  timeInMs: uiAlertAuthenticationWarningTime(state),
});

export const mapDispatchToProps = (dispatch) => ({
  handleAlertDismiss: () => dispatch(uiAlertAuthenticationWarningToggle()),
});

export const mergeProps = (stateProps, dispatchProps) => ({
  timeInMs: stateProps.timeInMs,
  handleAlertDismiss: dispatchProps.handleAlertDismiss,
  handleReload: () => {
    Session.removeItem(config.storage.oauth);
    return window.location.reload();
  },
});

export class AlertAuthWarningContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeLeftInMs: props.timeInMs,
    };
  }

  static propTypes = {
    timeInMs: PropTypes.number,
    handleAlertDismiss: PropTypes.func.isRequired,
    handleReload: PropTypes.func.isRequired,
  };

  updateTime() {
    this.setState({
      ...this.state,
      timeLeftInMs: (this.state.timeLeftInMs -= 1000),
    });
  }

  timeRemainingInSeconds() {
    return Math.round(this.state.timeLeftInMs / 1000);
  }

  intervalStep() {
    this.updateTime();
    if (this.state.timeLeftInMs <= 0) {
      this.stopCountdown();
    }
  }

  startCountdown() {
    this.countdown = window.setInterval(this.intervalStep.bind(this), 1000);
  }

  stopCountdown() {
    window.clearInterval(this.countdown);
  }

  componentDidMount() {
    this.startCountdown();
  }

  componentWillUnmount() {
    this.stopCountdown();
  }

  render() {
    return (
      <div>
        <AlertAuthWarning
          handleAlertDismiss={this.props.handleAlertDismiss}
          handleReload={this.props.handleReload}
          timeLeft={this.timeRemainingInSeconds()}
        />
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AlertAuthWarningContainer);
