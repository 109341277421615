import React from "react";
import { useSelector } from "react-redux";
import EnterpriseSignIn from "../components/enterprise-signin-view";
import { organizationHasVerifiedDomains } from "modules/organization-module";
import {
  meIsOrganizationMember,
  meIsOrganizationAdmin,
  meIsConflicted,
} from "modules/me-module";

export const EnterpriseSignInContainer = () => {
  const {
    hasVerifiedOrgDomains,
    isOrganizationMember,
    isOrganizationAdmin,
    isViewOnly,
  } = useSelector(getValuesFromStore);

  return (
    <EnterpriseSignIn
      hasVerifiedOrgDomains={hasVerifiedOrgDomains}
      hideEnterpriseSignInDetails={isOrganizationMember && !isOrganizationAdmin}
      showEditLink={isOrganizationMember && isOrganizationAdmin}
      isViewOnly={isViewOnly}
    />
  );
};
export const getValuesFromStore = (state) => ({
  hasVerifiedOrgDomains: organizationHasVerifiedDomains(state),
  isOrganizationMember: meIsOrganizationMember(state),
  isOrganizationAdmin: meIsOrganizationAdmin(state),
  isViewOnly: meIsConflicted(state),
});

export default EnterpriseSignInContainer;
