import {
  daoPersonalAccessTokenPost,
  daoPersonalAccessTokenListGet,
  daoPersonalAccessTokenPatch,
  daoPersonalAccessTokenDelete,
} from "dao/personalaccesstoken-dao";

export const PERSONAL_ACCESSTOKEN_GET = "PERSONAL_ACCESSTOKEN_GET";
export const PERSONAL_ACCESSTOKEN_GET_SUCCESS = "PERSONAL_ACCESSTOKEN_GET_SUCCESS";
export const PERSONAL_ACCESSTOKEN_GET_FAILURE = "PERSONAL_ACCESSTOKEN_GET_FAILURE";

export const PERSONAL_ACCESSTOKEN_POST = "PERSONAL_ACCESSTOKEN_POST";
export const PERSONAL_ACCESSTOKEN_POST_SUCCESS = "PERSONAL_ACCESSTOKEN_POST_SUCCESS";
export const PERSONAL_ACCESSTOKEN_POST_FAILURE = "PERSONAL_ACCESSTOKEN_POST_FAILURE";

export const PERSONAL_ACCESSTOKEN_PATCH = "PERSONAL_ACCESSTOKEN_PATCH";
export const PERSONAL_ACCESSTOKEN_PATCH_SUCCESS = "PERSONAL_ACCESSTOKEN_PATCH_SUCCESS";
export const PERSONAL_ACCESSTOKEN_PATCH_FAILURE = "PERSONAL_ACCESSTOKEN_PATCH_FAILURE";

export const PERSONAL_ACCESSTOKEN_DELETE = "PERSONAL_ACCESSTOKEN_DELETE";
export const PERSONAL_ACCESSTOKEN_DELETE_SUCCESS = "PERSONAL_ACCESSTOKEN_DELETE_SUCCESS";
export const PERSONAL_ACCESSTOKEN_DELETE_FAILURE = "PERSONAL_ACCESSTOKEN_DELETE_FAILURE";

export const initialState = {
  data: {
    personalAccessTokenList: [],
  },

  isLoading: false,
};

export const personalAccessTokenIsLoading = (state) => state.personalAccessToken.isLoading;

export const personalAccessTokenList = (state) =>
  state.personalAccessToken && state.personalAccessToken.data && state.personalAccessToken.data.tokens
    ? state.personalAccessToken.data.tokens
    : [];

export const getTokenById = (state, tokenId) => {
  const token = !!personalAccessTokenList(state).length && personalAccessTokenList(state).find((data) => data.id === tokenId);
  return typeof token !== 'undefined' ? token : {};
};

// ------------------------------------
// Actions
// ------------------------------------
export const personalAccessTokenGetSuccess = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_GET_SUCCESS,
  payload,
});

export const personalAccessTokenGetFailure = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_GET_FAILURE,
  payload,
});

export const personalAccessTokenGet = () => (dispatch) => {
  dispatch({
    type: PERSONAL_ACCESSTOKEN_GET,
  });

  return daoPersonalAccessTokenListGet().then(
    (response) => dispatch(personalAccessTokenGetSuccess(response.data)),
    (ex) => {
      dispatchEvent(personalAccessTokenGetFailure(ex));
      Promise.reject(ex);
    }
  );
};

export const personalAccessTokenPostSuccess = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_POST_SUCCESS,
  payload,
});

export const personalAccessTokenPostFailure = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_POST_FAILURE,
  payload,
});

export const personalAccessTokenPost = (payload = {}) => (dispatch) => {
  dispatch({
    type: PERSONAL_ACCESSTOKEN_POST,
  });
  return daoPersonalAccessTokenPost(payload).then(
    (response) => dispatch(personalAccessTokenPostSuccess(response.data)),
    (ex) => {
      dispatch(personalAccessTokenPostFailure(ex));
      return Promise.reject(ex);
    }
  );
};

export const personalAccessTokenPatchSuccess = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_PATCH_SUCCESS,
  payload,
});

export const personalAccessTokenPatchFailure = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_PATCH_FAILURE,
  payload,
});

export const personalAccessTokenPatch = (tokenId, payload = {}) => (dispatch) => {
  dispatch({
    type: PERSONAL_ACCESSTOKEN_PATCH,
  });
  return daoPersonalAccessTokenPatch(tokenId, payload).then(
    (response) => dispatch(personalAccessTokenPatchSuccess(response.data)),
    (ex) => {
      dispatch(personalAccessTokenPatchFailure(ex));
      return Promise.reject(ex);
    }
  );
};

export const personalAccessTokenDeleteSuccess = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_DELETE_SUCCESS,
  payload,
});

export const personalAccessTokenDeleteFailure = (payload = {}) => ({
  type: PERSONAL_ACCESSTOKEN_DELETE_FAILURE,
  payload,
});

export const personalAccessTokenDelete = (tokenId) => (dispatch) => {
  dispatch({
    type: PERSONAL_ACCESSTOKEN_DELETE,
  });
  return daoPersonalAccessTokenDelete(tokenId).then(
    (response) =>
      dispatch(personalAccessTokenDeleteSuccess(response.data)),
    (ex) => {
      dispatch(personalAccessTokenDeleteFailure(ex));
      return Promise.reject(ex);
    }
  );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [PERSONAL_ACCESSTOKEN_GET]: (state) => ({ ...state, isLoading: true }),

  [PERSONAL_ACCESSTOKEN_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {

      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [PERSONAL_ACCESSTOKEN_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PERSONAL_ACCESSTOKEN_POST]: (state) => ({ ...state, isLoading: true }),

  [PERSONAL_ACCESSTOKEN_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [PERSONAL_ACCESSTOKEN_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PERSONAL_ACCESSTOKEN_PATCH]: (state) => ({ ...state, isLoading: true }),

  [PERSONAL_ACCESSTOKEN_PATCH_SUCCESS]: (state, action) => {
    if (typeof action.payload === "object") {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [PERSONAL_ACCESSTOKEN_PATCH_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PERSONAL_ACCESSTOKEN_DELETE]: (state) => ({ ...state, isLoading: true }),

  [PERSONAL_ACCESSTOKEN_DELETE_SUCCESS]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PERSONAL_ACCESSTOKEN_DELETE_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

};

const personalAccessTokenReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default personalAccessTokenReducer;
