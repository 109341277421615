
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PasskeySignin from "../components/passkey-signin";
import {
  passkeyRegistrationPost,
  passkeyRegistrationOptionsPost,
  passkeyDelete,
  passkeyCredentialsGet
} from "modules/passkey-module";
import base64url from "base64url";
import { Buffer } from 'buffer';
import moment from "moment";
import { meLocale, meData } from "modules/me-module";
import { uiShowToastrMessage, uiShowConfirmDialog } from "modules/ui-module";
import auth from "lib/profile-auth";
import { emailVerificationRequestPost, identityEmailData, identityEmailToken } from "modules/identity-module";

window.Buffer = Buffer;
export const PasskeySigninContainer = () => {
  const { meLocale, meEmail, emailToken, emailData } = useSelector(getValuesFromStore);

  const [passkeysList, setPasskeysList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [credentialIdForDeletion, setCredentialIdForDeletion] = useState('');
  const [credentialListIsLoading, setCredentialListIsIsLoading] = useState(true);
  const [passkeyCreationInProgress, setPasskeyCreationInProgress] = useState(false);
  const [verificationModalIsOpen, setVerificationModalIsOpen] = useState(false);

  let dispatch = useDispatch();

  const fetchPasskeyList = () => {
    const lang = meLocale ? meLocale.split("_")[0] : "en";
    return dispatch(passkeyCredentialsGet())
      .then(
        (result) => {
          const credentials = result.payload?.credentials || [];
          credentials.forEach((data) => {
            if (!!data.lastUsedTime)
              data.lastUsedTime = moment(data.lastUsedTime).locale(lang).format("ll");

            if (!!data.createdTime)
              data.createdTime = moment(data.createdTime).locale(lang).format("ll");

          });
          setPasskeysList(credentials);
          setCredentialListIsIsLoading(false);
        },
        () => {
          setCredentialListIsIsLoading(false);
        }
      );
  };

  const checkIfEmailVerificationNeeded = () => {
    const minLoa = auth.token?.loa || 2;
    return minLoa < 3;
  };

  const lastEmailTokenIsStillActive = () => {
    const { expirationTime, status } = emailData;
    if (status !== 'VERIFIED') {
      return false;
    }
    return moment(expirationTime).isAfter();
  };

  const displayToastrMessage = (toastrType, toastrMessageId, toastrParams = null, toastrMessageDescription = "") => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams, toastrMessageDescription));
  };

  const handleXHRResult = (success, messageId, toastrMessageDescription = "", toastrParams = null) => {
    const toastrType = success ? "success" : "error";
    displayToastrMessage(toastrType, messageId, toastrParams, toastrMessageDescription);
  };

  const processRegOptionAndCreateRegRequest = async (regOptions) => {
    const { regRequestId, credentialCreationOptions } = regOptions.payload;
    credentialCreationOptions.challenge = base64url.toBuffer(credentialCreationOptions.challenge);
    credentialCreationOptions.user.id = base64url.toBuffer(credentialCreationOptions.user.id);
    credentialCreationOptions.excludeCredentials?.forEach((credential) => credential.id = base64url.toBuffer(credential.id));
    setPasskeyCreationInProgress(false);
    const passkeyCredential = await navigator.credentials.create({
      publicKey: credentialCreationOptions
    });

    const registrationRequest = {
      regRequestId,
      publicKeyCredential: {
        type: passkeyCredential.type,
        id: passkeyCredential.id,
        response: {
          attestationObject: base64url.encode(passkeyCredential.response.attestationObject),
          clientDataJSON: base64url.encode(passkeyCredential.response.clientDataJSON),
          transports: passkeyCredential.response.getTransports()
        },
        clientExtensionResults: passkeyCredential.getClientExtensionResults()
      }
    };
    return registrationRequest;
  };

  const createNewPasskey = async () => {
    setModalIsOpen(false);
    setPasskeyCreationInProgress(true);
    const authProp = checkIfEmailVerificationNeeded() ? `Email ${emailToken}` : '';
    setVerificationModalIsOpen(false);
    return dispatch(passkeyRegistrationOptionsPost(authProp))
      .then(
        async (regOptions) => {
          const registrationRequest = await processRegOptionAndCreateRegRequest(regOptions);
          setPasskeyCreationInProgress(true);
          dispatch(passkeyRegistrationPost(registrationRequest, authProp))
            .then(
              () => {
                fetchPasskeyList();
                setPasskeyCreationInProgress(false);
                handleXHRResult(true, "passkey.created-successful", "passkey.created-successful-subtext");
              },
              () => {
                displayToastrMessage(
                  "error",
                  "passkey.connection.failure"
                );
                setPasskeyCreationInProgress(false);
              }
            );
        },
        () => {
          displayToastrMessage(
            "error",
            "passkey.connection.failure"
          );
          setPasskeyCreationInProgress(false);
        }
      );
  };

  const handleAuthorizationAndExecuteAction = (executionFunction, param = '') => {
    setPasskeyCreationInProgress(true);
    if (!!checkIfEmailVerificationNeeded() && !lastEmailTokenIsStillActive()) {
      sendVerificationEmail();
      setModalIsOpen(true);
      setVerificationModalIsOpen(true);
    }
    else {
      executionFunction(param);
      return;
    }
  };

  const sendVerificationEmail = () => {
    dispatch(
      emailVerificationRequestPost(meLocale, {
        email: meEmail,
      })
    ).then(
      () => {
        displayToastrMessage(
          "success",
          "email-editor.verification-email-send-successful"
        );
      },
      () => {
        displayToastrMessage(
          "error",
          "email-editor.verification-email-send-failed"
        );
      }
    );
  };

  const openModal = () => {
    setPasskeyCreationInProgress(false);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setPasskeyCreationInProgress(false);
    setModalIsOpen(false);
    setVerificationModalIsOpen(false);
  };

  const createNewPasskeyAfterAuthorization = async () => {
    handleAuthorizationAndExecuteAction(createNewPasskey);
  };

  const deletePasskeyAfterAuthorization = (credentialId = '') => (e) => {
    if (credentialId) {
      setCredentialIdForDeletion(credentialId);
    }
    handleAuthorizationAndExecuteAction(onDelete, credentialId);
  };

  const onDelete = (credentialId) => {
    setModalIsOpen(false);
    setVerificationModalIsOpen(false);
    const authProp = checkIfEmailVerificationNeeded() ? `Email ${emailToken}` : '';
    const credentialName = passkeysList.find((data) => data.credentialId === credentialId).name;
    if (credentialName) {
      dispatch(
        uiShowConfirmDialog(
          "deletePasskey",
          () => doDelete(credentialId, authProp),
          () => openModal(),
          false,
          { name: credentialName },
          "danger"
        ));
    }

  };

  const doDelete = (credentialId, authProp = '') => {
    setModalIsOpen(false);
    setCredentialListIsIsLoading(true);
    setPasskeyCreationInProgress(false);
    if (!!credentialId) {
      dispatch(passkeyDelete(credentialId, authProp)).then(
        () => {
          setCredentialIdForDeletion('');
          fetchPasskeyList();
          handleXHRResult(true, "passkey.delete-successful");
        },
        () => {
          setCredentialListIsIsLoading(false);
          setModalIsOpen(true);
        }
      );
    }
  };

  useEffect(() => {
    fetchPasskeyList();
  }, []);

  return (
    <PasskeySignin
      createNewPasskey={createNewPasskeyAfterAuthorization}
      passkeysList={passkeysList}
      modalIsOpen={modalIsOpen}
      onEditModalClick={openModal}
      closeModal={closeModal}
      isLoading={credentialListIsLoading}
      credentialIdForDeletion={credentialIdForDeletion}
      onDelete={deletePasskeyAfterAuthorization}
      completeProcess={credentialIdForDeletion ? onDelete : createNewPasskey}
      passkeyCreationInProgress={passkeyCreationInProgress}
      verificationModalIsOpen={verificationModalIsOpen}
      sendVerificationEmail={sendVerificationEmail}
    />
  );
};

export const getValuesFromStore = (state) => {
  const mePersonData = meData(state);
  const emails = mePersonData.emails || [];
  const primaryEmail = emails.find((email) => email.type === "primary") || "";
  return {
    meLocale: meLocale(state),
    meEmail: primaryEmail.value,
    emailToken: identityEmailToken(state),
    emailData: identityEmailData(state)
  };
};

export default PasskeySigninContainer;