import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SelectInput from "components/select-input-view";
import AvatarSelectorContainer from "../containers/avatar-selector-container";
import { FormattedMessage, defineMessages, injectIntl, useIntl } from "react-intl";
import st from "core/shared-translations";
import classes from "./personal-info.scss";
import commonClasses from "styles/_base.scss";
import { Typography } from "@getgo/chameleon-web-react-wrapper";
import BoxedContainer from "components/boxed-container-view";
import KeyValDisplay from "components/key-val-display";
import { EditOutlinedIcon, HelpCircleOutlinedIcon, PersonOutlinedIcon } from "@getgo/chameleon-icons/react";
import EmailSubscriptionOptOutContainer from "../containers/email-subscription-opt-out-container";
import { Button, InputField, TooltipV3, IconButton, AvatarV2, IconBadgeV2 } from '@getgo/chameleon-web-react-wrapper';
import ResponsiveDialog from 'components/dialog-view.js';

const t = defineMessages({
  "more-about-you-section.title": {
    id: "more-about-you-section.title",
    defaultMessage: "More About You",
    description: "Section title for the more about you fields",
  },
  "personal-info.timezone": {
    id: "personal-info.timezone",
    defaultMessage: "Time zone",
    description: "Field label for time zone selection",
  },
  "personal-info.language": {
    id: "personal-info.language",
    defaultMessage: "Preferred language",
    description: "Field label for language selection",
  },
  "personal-info.personal-info-help-message": {
    id: "personal-info.personal-info-help-message",
    defaultMessage:
      "Your personal info sets the name, language, dates, and times you see in GoTo products.",
    description:
      "Message to be displayed in the popover dialog when the Personal Info help icon is clicked.",
  },
  "personal-info.about-you-help-message": {
    id: "personal-info.about-you-help-message",
    defaultMessage:
      '"About you" info can appear in product features like your personal meeting page.',
    description:
      "Message to be displayed in the popover dialog when the More About You help icon is clicked.",
  },
  "personal-info.validation-error-field-name": {
    id: "personal-info.validation-error-field-name",
    defaultMessage: "Enter your name.",
    description:
      "Content of the error message when the Preferred Name field is left blank",
  },
  "personal-info.name-exceeds-max-length": {
    id: "personal-info.name-exceeds-max-length",
    defaultMessage: "You cannot enter more than 128 characters.",
    description:
      "Content of the error message when the entry in the Name field exceeds more than 128 characters",
  },
  "personal-info.title": {
    id: "personal-info.title",
    defaultMessage: "Title",
    description: "Field label for the user's title field",
  },
  "personal-info.title-exceeds-max-length": {
    id: "personal-info.title-exceeds-max-length",
    defaultMessage: "Enter a title less than 250 characters.",
    description:
      "Content of the error message when the entry in the Title field exceeds more than 250 characters",
  },
  "personal-info.profileUrl": {
    id: "personal-info.profileUrl",
    defaultMessage: "Link to profile or business",
    description: "Field label for the user's about URL field",
  },
  "personal-info.profileUrl-malformed-url": {
    id: "personal-info.profileUrl-malformed-url",
    defaultMessage:
      "Enter a valid URL less than 250 characters starting with http:// or https://",
    description:
      "Content of the error message when the entry in the Name field exceeds more than 250 characters",
  },
  "personal-info.location": {
    id: "personal-info.location",
    defaultMessage: "Location",
    description: "Field label for the user's location field",
  },
  "personal-info.location-exceeds-max-length": {
    id: "personal-info.location-exceeds-max-length",
    defaultMessage: "Enter a location less than 250 characters.",
    description:
      "Content of the error message when the entry in the Location field exceeds more than 250 characters",
  },
});

const PersonalInfo = ({
  intl,
  personData,
  allLocales,
  allTimeZones,
  errors,
  onChange,
  onBlur,
  onLocaleChange,
  onTimeZoneChange,
  onSave,
  onCancel,
  enableCTAs,
  isLoading,
  locationValue,
  isViewOnly,
  avatarImageUrl,
  modalIsOpen,
  closeModal,
  openModal
}) => {

  const PersonalInfoObject = [
    {
      id: "displatName",
      heading: <FormattedMessage {...st["shared.name"]} />,
      value: personData.displayName,
    },
    {
      id: "locale",
      heading: <FormattedMessage {...t["personal-info.language"]} />,
      value: allLocales.find(locale => locale.value === personData.selectedLocale)?.label,
    },
    {
      id: "timeZone",
      heading: <FormattedMessage {...t["personal-info.timezone"]} />,
      value: allTimeZones.find(timezone => timezone.value === personData.timezone)?.label,
    },
    {
      id: "title",
      heading: <FormattedMessage {...t["personal-info.title"]} />,
      value: personData.title,
    },
    {
      id: "location",
      heading: <FormattedMessage {...t["personal-info.location"]} />,
      value: locationValue,
    },
    {
      id: "profileUrl",
      heading: <FormattedMessage {...t["personal-info.profileUrl"]} />,
      value: personData.profileUrl,
    },
  ];
  return (
    <div id="personal-info-section">
      <div className={classes["fluidContainer"]}>
        <Typography tag="h1" variant="heading-large">
          <FormattedMessage {...st["shared.personal.info"]} />
        </Typography>
      </div>
      <div className={commonClasses['mainContainer']}>
        <div className={commonClasses['singleColumn']}>
          <AvatarSelectorContainer />
          <BoxedContainer
            title={<FormattedMessage {...st["shared.personal.info"]} />}
            buttonIcon={<EditOutlinedIcon />}
            buttonText={intl.formatMessage(st["shared.edit"])}
            onClick={openModal}
            boxIcon={<PersonOutlinedIcon color="#000000" />}
          >
            <div className={classes["fluidContainer"]}>
              {PersonalInfoObject.map(({ heading, value, id }) => (
                <KeyValDisplay key={`key-val-${id}`} heading={heading} val={!!value?.length ? value : "-"} />
              ))}
            </div>
          </BoxedContainer>
        </div>
        <EmailSubscriptionOptOutContainer />
      </div>
      <ResponsiveDialog
        open={modalIsOpen}
        closable
        onClosed={closeModal}
        title={
          <div className={classes["inlineTextWithIcon"]}>
            <FormattedMessage {...st['shared.personal.info']} />
            <Fragment>
              <IconButton
                id="personal-info-icon"
                size="small"
                labelId={<FormattedMessage {...st["shared.personal.info"]} />}
              >
                <HelpCircleOutlinedIcon color="#2c72de" size="16px" />
              </IconButton>
              <TooltipV3
                zIndex={1103}
                triggerId="personal-info-icon"
              >
                <FormattedMessage {...t['personal-info.personal-info-help-message']} />
              </TooltipV3>
            </Fragment>
          </div>
        }
        actions={<Fragment>
          <Button
            onClick={onSave}
            disabled={!enableCTAs || (enableCTAs && isViewOnly)}
          >
            {isLoading ? (
              <FormattedMessage {...st["shared.saving"]} />
            ) : (
              <FormattedMessage {...st["shared.save"]} />
            )}
          </Button>
          <Button role="link" onClick={onCancel} variant="tertiary">
            <FormattedMessage {...st["shared.cancel"]} />
          </Button>
        </Fragment>}
      >
        <div className={classes["personalInfoInputs"]}>
          <InputField
            id="displayName-input"
            data-testid="NameInput"
            value={personData.displayName}
            onChange={onChange}
            onBlur={onBlur}
            isShallowGroup
            fullwidth
            maxLength={129}
            disabled={isViewOnly}
            error={!!errors.displayName}
            helperText={errors.displayName &&
              intl.formatMessage(st[errors.displayName] || t[errors.displayName])}
          >
            <FormattedMessage {...st['shared.name']} />
          </InputField>
          <SelectInput
            name="locale"
            label={intl.formatMessage(t['personal-info.language'])}
            value={personData.selectedLocale}
            defaultOption=""
            options={allLocales}
            onChange={onLocaleChange}
            isShallowGroup
            isViewOnly={isViewOnly}
            error={errors.locale}
          />
          <SelectInput
            name="timezone"
            label={intl.formatMessage(t['personal-info.timezone'])}
            value={personData.timezone}
            options={allTimeZones}
            onChange={onTimeZoneChange}
            isShallowGroup
            isViewOnly={isViewOnly}
            error={errors.timezone}
          />
          <div className={classes["moreAboutYou"]}>
            <Typography variant="body-medium-strong">
              <div className={classes["inlineTextWithIcon"]}>
                <FormattedMessage {...t['more-about-you-section.title']} />&nbsp;
                <Fragment>
                  <IconButton
                    id="more-info-icon"
                    size="small"
                    labelId={<FormattedMessage {...t["more-about-you-section.title"]} />}
                  >
                    <HelpCircleOutlinedIcon color="#2c72de" size="16px" />
                  </IconButton>
                  <TooltipV3
                    zIndex={1103}
                    triggerId="more-info-icon"
                  >
                    <FormattedMessage {...t['personal-info.about-you-help-message']} />
                  </TooltipV3>
                </Fragment>
              </div>
            </Typography>
            <InputField
              id="title-input"
              name="title"
              value={personData.title}
              onChange={onChange}
              onBlur={onBlur}
              fullwidth
              disabled={isViewOnly}
              maxLength={251}
              error={!!errors.title}
              helperText={errors.title &&
                intl.formatMessage(st[errors.title] || t[errors.title])}
            >
              <FormattedMessage {...t['personal-info.title']} />
            </InputField>
            <InputField
              id="location-input"
              name="location"
              value={locationValue}
              onChange={onChange}
              onBlur={onBlur}
              fullwidth
              disabled={isViewOnly}
              maxLength={251}
              error={!!errors.location}
              helperText={errors.location &&
                intl.formatMessage(st[errors.location] || t[errors.location])}
            >
              <FormattedMessage {...t['personal-info.location']} />
            </InputField>
            <InputField
              id="profileUrl-input"
              name="profileUrl"
              value={personData.profileUrl}
              onChange={onChange}
              onBlur={onBlur}
              fullwidth
              disabled={isViewOnly}
              maxLength={251}
              error={!!errors.profileUrl}
              helperText={errors.profileUrl &&
                intl.formatMessage(st[errors.profileUrl] || t[errors.profileUrl])}
            >
              <FormattedMessage {...t['personal-info.profileUrl']} />
            </InputField>
          </div>
        </div>
      </ResponsiveDialog>
    </div>
  );
};

PersonalInfo.propTypes = {
  intl: PropTypes.object.isRequired,
  personData: PropTypes.object.isRequired,
  allLocales: PropTypes.array.isRequired,
  allTimeZones: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onLocaleChange: PropTypes.func.isRequired,
  onTimeZoneChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  enableCTAs: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locationValue: PropTypes.string,
  isViewOnly: PropTypes.bool,
  avatarImageUrl: PropTypes.string.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,

};

export default injectIntl(PersonalInfo);
