import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Core from "../components/core-view";
import {
  isUserInvitationPage,
  isInvitationAcceptancePage,
} from "lib/utility-functions";
import {
  uiAlertAuthenticationWarningVisible,
  uiAlertAuthenticationExpiredVisible,
  uiToastrMessage,
  uiConfirmDialog,
  uiMessageBannerVisible,
  uiShowMessageBanner,
} from "modules/ui-module";
import { meIsConflicted } from "modules/me-module";
import auth from "lib/profile-auth";

export const CoreContainer = () => {
  const {
    alertAuthWarningIsVisible,
    alertAuthExpiredIsVisible,
    messageBannerVisible,
    toastrMessage,
    confirmDialog,
    isUserConflicted,
    logout,
  } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isUserConflicted) {
      dispatch(
        uiShowMessageBanner("userIsConflicted", {
          onclick: handleSignOut,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserConflicted]);

  const handleSignOut = () => {
    logout();
  };

  return (
    <Core
      alertAuthWarningIsVisible={alertAuthWarningIsVisible}
      alertAuthExpiredIsVisible={alertAuthExpiredIsVisible}
      messageBannerVisible={messageBannerVisible}
      toastrMessage={toastrMessage}
      confirmDialog={confirmDialog}
      useStandardNav={!(isUserInvitationPage() || isInvitationAcceptancePage())}
    />
  );
};
export const getValuesFromStore = (state) => ({
  alertAuthWarningIsVisible: uiAlertAuthenticationWarningVisible(state),
  alertAuthExpiredIsVisible: uiAlertAuthenticationExpiredVisible(state),
  messageBannerVisible: uiMessageBannerVisible(state),
  toastrMessage: uiToastrMessage(state),
  confirmDialog: uiConfirmDialog(state),
  isUserConflicted: meIsConflicted(state),
  logout: auth.logout.bind(auth),
});

export default CoreContainer;
