import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import SocialAccounts from "../components/social-accounts-view";
import appconfig from "config/appconfig";
import {
  socialProviderByUserIdDelete,
  setModeDisconnectLastSocialAccount,
} from "modules/social-module";
import {
  meData,
  getGetGoSchema,
  meIsConflicted,
  meIsOrganizationMember,
  meIsOrganizationAdmin,
} from "modules/me-module";
import { uiShowToastrMessage } from "modules/ui-module";
import { getSocialAccountName, stopEvent } from "lib/utility-functions";
import Session from "lib/session";

export const SocialAccountsContainer = ({
  socialAccounts,
  socialSignInLinks,
}) => {
  let { personData, passwordSet, isViewOnly, isOrganizationMember, isOrganizationAdmin } = useSelector(getValuesFromStore);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  let dispatch = useDispatch();
  const getSocialAccountIdByProvider = (provider) => {
    const socialAccount = !!socialAccounts && socialAccounts.find(
      (socialAcct) => socialAcct["provider"] === provider
    );
    return typeof socialAccount !== "undefined" ? socialAccount.email : "";
  };

  const changeModalState = (state) => (e) => {
    stopEvent(e);
    if (typeof state !== "undefined") {
      setModalIsOpen(state);
    }
    return true;
  };

  const displayToastrMessage = (
    toastrType,
    toastrMessageId,
    toastrParams = "null"
  ) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId, toastrParams));
  };

  const deleteSocialAccountWithToastrMessage = (socialAccount) => {
    let socialAccountName = getSocialAccountName(socialAccount);

    dispatch(socialProviderByUserIdDelete(personData.id, socialAccount)).then(
      () => {
        displayToastrMessage(
          "success",
          "social-accounts.account-disconnect-successful",
          { socialAccountName: socialAccountName }
        );
      },
      () => {
        displayToastrMessage(
          "error",
          "social-accounts.account-disconnect-failed",
          { socialAccountName: socialAccountName }
        );
      }
    );
  };

  // currying, because function calls with params assigned to onClick events are called immediately
  // (https://www.sitepoint.com/currying-in-functional-javascript/)
  const disconnectSocialAccount = (socialAccount) => (e) => {
    stopEvent(e);

    if (socialAccounts.length > 1 || passwordSet) {
      deleteSocialAccountWithToastrMessage(socialAccount);
    } else {
      dispatch(setModeDisconnectLastSocialAccount(socialAccount));
    }
    // always return false so that the anchor tag will not try to navigate to the empty location (#).
    return false;
  };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      const socialAccount = Session.getItem(
        appconfig.storage.disconnectSocialAccount
      );
      if (
        socialAccount &&
        (passwordSet || (socialAccounts && socialAccounts.length > 1))
      ) {
        deleteSocialAccountWithToastrMessage(socialAccount);
        Session.removeItem(appconfig.storage.disconnectSocialAccount);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordSet, socialAccounts]);

  return (
    <SocialAccounts
      googleEmail={getSocialAccountIdByProvider(
        appconfig.socialAccounts.GOOGLE
      )}
      googleLink={socialSignInLinks[appconfig.socialAccountLinks.GOOGLE]}
      facebookEmail={getSocialAccountIdByProvider(
        appconfig.socialAccounts.FACEBOOK
      )}
      facebookLink={socialSignInLinks[appconfig.socialAccountLinks.FACEBOOK]}
      linkedinEmail={getSocialAccountIdByProvider(
        appconfig.socialAccounts.LINKEDIN
      )}
      linkedinLink={socialSignInLinks[appconfig.socialAccountLinks.LINKEDIN]}
      microsoftEmail={getSocialAccountIdByProvider(
        appconfig.socialAccounts.MICROSOFT
      )}
      microsoftLink={socialSignInLinks[appconfig.socialAccountLinks.MICROSOFT]}
      lastpassEmail={getSocialAccountIdByProvider(
        appconfig.socialAccounts.LASTPASS
      )}
      lastpassLink={socialSignInLinks[appconfig.socialAccountLinks.LASTPASS]}
      appleEmail={getSocialAccountIdByProvider(appconfig.socialAccounts.APPLE)}
      appleLink={socialSignInLinks[appconfig.socialAccountLinks.APPLE]}
      disconnectSocialAccount={disconnectSocialAccount}
      isViewOnly={isViewOnly}
      modalIsOpen={modalIsOpen}
      editLinkClick={changeModalState(true)}
      closeModal={changeModalState(false)}
      showEditLink={isOrganizationMember && isOrganizationAdmin}
    />
  );
};
export const getValuesFromStore = (state) => {
  let passwordSet = false;
  const getGoSchema = getGetGoSchema(state);
  if (getGoSchema && getGoSchema.passwordSet) {
    passwordSet = getGoSchema.passwordSet;
  }
  return {
    personData: meData(state),
    isOrganizationMember: meIsOrganizationMember(state),
    isOrganizationAdmin: meIsOrganizationAdmin(state),
    passwordSet,
    isViewOnly: meIsConflicted(state),
  };
};

SocialAccountsContainer.propTypes = {
  socialAccounts: PropTypes.array.isRequired,
  socialSignInLinks: PropTypes.object.isRequired,
};

export default SocialAccountsContainer;
