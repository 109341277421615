import React from 'react';
import {
  FormattedMessage,
  defineMessages,
} from 'react-intl';
import st from 'core/shared-translations';
import { Nav, Menu, } from '@getgo/chameleon-web-react-wrapper';
import classes from './main-nav-view.scss';
import GotoProfile from 'static/images/svg/Goto_profile.svg';


export const LogoHeader = () => (
  <Nav>
    <Menu className={classes['navContainer']} role="menu" labelId="navbar-header">
      <a className="navbar-brand-lite" href="#">
        <img src={GotoProfile} alt="" />
      </a>
    </Menu>
  </Nav>
);

export default LogoHeader;
