import React, { useEffect, useState } from "react";
import environment from "lib/environment";
import Session from "lib/session";
import ErrorView from "../components/error-view";

const errorUrl = environment.get().error_url;

const ErrorContainer = () => {
  let [error, setError] = useState({});
  useEffect(() => {
    const rawError = JSON.parse(Session.getItem("last_error")) || {};
    setError({ status: rawError.status, data: rawError.data });
  }, []);

  return <ErrorView errorUrl={errorUrl} error={error} />;
};
export default ErrorContainer;
