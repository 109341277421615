import React, {useState} from "react";
import PropTypes from "prop-types";
import AvatarEditor from "../components/avatar-editor-view";

export const AvatarEditorContainer = ({
  userImageUrl,
  closeModal,
  processImageAndUpload,
  showImageSelectorControl,
}) => {
  const [allValues, setAllValues] = useState({
    doneButtonDisabled: false,
  });
  const handleCanvasImage = (canvasImage) => {
    setAllValues({doneButtonDisabled: true});
    processImageAndUpload(canvasImage);
  };

  return (
    <AvatarEditor
      userImageUrl={userImageUrl}
      handleCanvasImage={handleCanvasImage}
      closeModal={closeModal}
      showImageSelectorControl={showImageSelectorControl}
      doneButtonDisabled={allValues.doneButtonDisabled}
    />
  );
};

AvatarEditorContainer.propTypes = {
  userImageUrl: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  processImageAndUpload: PropTypes.func.isRequired,
  showImageSelectorControl: PropTypes.func.isRequired,
};

export default AvatarEditorContainer;
