/* eslint-env _castle */
import React from "react";
import appconfig from "config/appconfig";
import environment from "lib/environment";
import { cssTransition, toast } from "react-toastify";

// Function to answer the social account name to display in the UI.
// This conversion function is necessary because we can't use the actual account names
//   in appconfig.socialAcounts as those are aligned with API name/value pairs.
export const getSocialAccountName = (socialAccount) => {
  let socialAccountName = "";

  switch (socialAccount) {
    case appconfig.socialAccounts.GOOGLE:
      socialAccountName = appconfig.socialAccountNames.GOOGLE;
      break;
    case appconfig.socialAccounts.FACEBOOK:
      socialAccountName = appconfig.socialAccountNames.FACEBOOK;
      break;
    case appconfig.socialAccounts.LINKEDIN:
      socialAccountName = appconfig.socialAccountNames.LINKEDIN;
      break;
    case appconfig.socialAccounts.MICROSOFT:
      socialAccountName = appconfig.socialAccountNames.MICROSOFT;
      break;
    case appconfig.socialAccounts.APPLE:
      socialAccountName = appconfig.socialAccountNames.APPLE;
      break;
    case appconfig.socialAccounts.LASTPASS:
      socialAccountName = appconfig.socialAccountNames.LASTPASS;
      break;
    default:
      socialAccountName = "";
  }

  return socialAccountName;
};

// Function to answer the support link URL - based on the SCIM "supportExperience" value.
export const getSupportExperienceUrls = (supportExperience) => {
  const urls = {
    supportUrl: "",
    faqUrl: "",
  };
  if (supportExperience === appconfig.supportExperiences.DEFAULT) {
    urls.supportUrl = environment.get().support_url;
    urls.faqUrl = environment.get().faq_url;
  } else if (supportExperience === appconfig.supportExperiences.JIVE_ONLY) {
    urls.supportUrl = environment.get().jive_support_url;
    urls.faqUrl = environment.get().jive_faq_url;
  }
  return urls;
};

// Function to return the password recovery URL.
export const getForgotPasswordUrl = () => {
  return `${environment.get().login_service_url}/?service=${encodeURIComponent(
    location.href
  )}`;
};

// Function to return query string values from a URL's location.search as an object.
export const getQueryStringParams = (searchStr) => {
  const queryValues = {};
  if (searchStr) {
    const searchString =
      searchStr.charAt(0) === "?" ? searchStr.substring(1) : searchStr;
    const paramArray = searchString.split("&");
    paramArray.forEach((param) => {
      const keyValuePair = param.split("=");
      if (keyValuePair.length === 2) {
        let val =
          keyValuePair[1].toLowerCase() === "true" ? true : keyValuePair[1];
        val = val === "false" ? false : val;
        queryValues[keyValuePair[0]] = val;
      }
    });
  }
  return queryValues;
};

// Function to inject the user's language into the given URL - if not english.
// URL must have a placeholder "{0}" at the place where the language should be inserted.
export const getLocalizedUrl = (url = "", lang) => {
  if (!url) {
    return "";
  }
  const language = lang ? lang.toLowerCase().split("_")[0] : "en";
  const langString = language !== "en" ? lang + "/" : "";
  return url.replace("{0}", langString);
};

// Function to scroll the page to the top.
export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// Function to display toastr messages (react-toastify) in core-view.js - https://www.npmjs.com/package/react-toastify
export const showToastrMessage = (
  toastrType,
  toastrMessage,
  toastrMessageId
) => {
  const messageType = toastrType.trim().toLowerCase();
  if (
    messageType === "success" ||
    messageType === "info" ||
    messageType === "error"
  ) {
    toast[messageType](toastrMessage, {
      toastId: toastrMessageId,
      autoClose: messageType === "error" ? 12000 : 6000,
      draggable: false,
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      newestOnTop: true,
      closeButton: true,
      transition: cssTransition({
        enter: "fadeIn",
        exit: "fadeOut",
        duration: 350,
      }),
      className: `toastifyToast ${messageType}`,
    });
  }
  return true;
};

// Stop event
export const stopEvent = (e) => {
  if (typeof e !== "undefined") {
    e.stopPropagation();
    e.preventDefault();
  }
};

export const isUserInvitationPage = () => {
  return (
    window.location.pathname
      .toLowerCase()
      .indexOf(appconfig.routes.userInvitation()) > -1
  );
};

export const isInvitationAcceptancePage = () => {
  return (
    window.location.pathname
      .toLowerCase()
      .indexOf(appconfig.routes.acceptInvitation()) > -1
  );
};

//debounching any function
export const debounceMyFunction = (func, delay = 500) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
};

// Copy the text to this method to the clipboard
export const copyTextToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  // execCommand is depreciated
  document.execCommand("copy");
  document.body.removeChild(el);
  return true;
};

// Get Error object from API response. Some APIs send an 'error' array, others 'errors' array.
export const getAPIError = (errorResponse) => {
  return (
    errorResponse &&
    errorResponse.data &&
    (errorResponse.data.error
      ? errorResponse.data.error
      : errorResponse.data.errors
        ? errorResponse.data.errors
        : null)
  );
};
