import React from 'react';
import PropTypes from 'prop-types';

export const ComponentBadgeImage = ({
  iconURL = "",
  iconFontName = "",
  title
}) => (
  <div className="picture-and-link">
    {iconFontName &&
      <div className="center-block img-circle component-badge" title={title}>
        <i className={`togo-icon ${iconFontName}`} />
      </div>}
    {iconURL &&
      <img
        className="center-block img-circle"
        id="top-picture"
        src={iconURL}
        title={title}
        alt={title}
      />}
  </div>
);

ComponentBadgeImage.propTypes = {
  title: PropTypes.string.isRequired,
  iconFontName: PropTypes.string,
  iconURL: PropTypes.string,
};

export default ComponentBadgeImage;
