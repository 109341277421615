import React, { useEffect, useState } from "react";
import ConfirmDialogView from "components/confirm-dialog-view";
import { stopEvent } from "lib/utility-functions";
import { uiRemoveConfirmDialog, uiConfirmDialog } from "modules/ui-module";
import environment from "lib/environment";
import { useSelector, useDispatch } from "react-redux";

export const ConfirmDialogContainer = () => {
  const { confirmDialog } = useSelector(getConfirmDialog);
  let [modalIsOpen, setModalIsOpen] = useState(false);
  let [secondsRemaining, setSecondsRemaining] = useState(20);
  let dispatch = useDispatch();
  useEffect(() => {
    if (
      confirmDialog.textResourceId &&
      ((confirmDialog.yesCallbackFn && confirmDialog.noCallbackFn) ||
        confirmDialog.useCountDownTimer)
    ) {
      openModal();
      if (confirmDialog.useCountDownTimer) {
        let tempSeconds = secondsRemaining;
        const nIntervalId = setInterval(() => {
          tempSeconds--;
          if (tempSeconds >= 0) {
            setSecondsRemaining(tempSeconds);
          } else {
            clearInterval(nIntervalId);
            redirectToMyAccount();
          }
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openModal = (e) => {
    stopEvent(e);
    setModalIsOpen(true);
  };

  const closeModal = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    dispatch(uiRemoveConfirmDialog());
  };

  const redirectToMyAccount = () => {
    closeModal();
    location.href = environment.get().myAccount_url;
  };

  const closeConfirmModalYesFn = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    confirmDialog.yesCallbackFn();
    dispatch(uiRemoveConfirmDialog());
  };

  const closeConfirmModalNoFn = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    confirmDialog.noCallbackFn();
    dispatch(uiRemoveConfirmDialog());
  };

  return (
    <div>
      <ConfirmDialogView
        closeModal={closeModal}
        textResourceId={confirmDialog.textResourceId}
        closeConfirmModalYesFn={closeConfirmModalYesFn}
        closeConfirmModalNoFn={closeConfirmModalNoFn}
        modalIsOpen={modalIsOpen}
        translationParams={confirmDialog.dialogParams || {}}
        useCountDownTimer={confirmDialog.useCountDownTimer}
        secondsRemaining={secondsRemaining}
        variant={confirmDialog.variant}
      />
    </div>
  );
};
export const getConfirmDialog = (state) => ({
  confirmDialog: uiConfirmDialog(state),
});

export default ConfirmDialogContainer;
