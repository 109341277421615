import React from 'react';
import PropTypes from 'prop-types';
import { Select, Option } from '@getgo/chameleon-web-react-wrapper';

let displayValue = '';

export const SelectInput = ({
  name,
  label,
  onChange,
  defaultOption,
  value,
  options,
  placeholder = 'Select...',
  error = '',
  isShallowGroup = false,
  isViewOnly = false,
  helpText,
  fullWidth = true
}) => {
  if (defaultOption) {
    options.unshift({ value: '', label: defaultOption });
  }
  if (isViewOnly) {
    const option = options.find(option => option.value === value);
    displayValue = option ? option.label : '';
  }

  return (
    <Select
      id={`${name}-select`}
      name={name}
      label={label}
      error={!!error}
      helperText={helpText}
      onChange={onChange}
      disabled={isViewOnly}
      value={options.find(option => option.value === value)?.value}
      fullwidth={fullWidth}
    >
      {!!options.length &&
        options.map(({ value, label }) => <Option key={`selection-option-${value}`} value={value}>{label}</Option>)}
    </Select>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultOption: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  isShallowGroup: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  fullWidth: PropTypes.bool
};

export default SelectInput;
