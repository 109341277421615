import React from 'react';
import PropTypes from 'prop-types';
import classes from './device-detail.scss';
import {
  FormattedMessage,
  defineMessages,
  useIntl,
} from 'react-intl';
import appconfig from 'config/appconfig';
import { Typography, PopoverV2, MenuItem, IconButton, Menu } from "@getgo/chameleon-web-react-wrapper";
import {
  EncryptionOutlinedIcon,
  MoreMenuVerticalOutlinedIcon,
  PresenceAvailableIcon,
  AlertOutlinedIcon
} from "@getgo/chameleon-icons/react";
import { Link } from "react-router-dom";

const t = defineMessages({
  'device-detail.menu-icon-title': {
    id: 'device-detail.menu-icon-title',
    defaultMessage: 'Manage device',
    description: 'Dropdown menu icon text for accessibility',
  },
  'device-detail.menu-dont-recognize-device': {
    id: 'device-detail.menu-dont-recognize-device',
    defaultMessage: 'I don\'t recognize this device',
    description: 'Dropdown menu option for unrecognized devices',
  },
  'device-detail.menu-trust-device': {
    id: 'device-detail.menu-trust-device',
    defaultMessage: 'I trust this device',
    description: 'Dropdown menu option for trusted devices',
  },
  'device-detail.duration-month': {
    id: 'device-detail.duration-month',
    defaultMessage: 'A month ago',
    description: 'One month duration string',
  },
  'device-detail.duration-months': {
    id: 'device-detail.duration-months',
    defaultMessage: '{count} months ago',
    description: 'Multi-month duration string',
  },
  'device-detail.duration-day': {
    id: 'device-detail.duration-day',
    defaultMessage: 'Yesterday',
    description: 'Duration string for yesterday',
  },
  'device-detail.duration-days': {
    id: 'device-detail.duration-days',
    defaultMessage: '{count} days ago',
    description: 'Multi-day duration string',
  },
  'device-detail.duration-hour': {
    id: 'device-detail.duration-hour',
    defaultMessage: 'An hour ago',
    description: 'One hour duration string',
  },
  'device-detail.duration-hours': {
    id: 'device-detail.duration-hours',
    defaultMessage: '{count} hours ago',
    description: 'Multi-hour duration string',
  },
  'device-detail.duration-minute': {
    id: 'device-detail.duration-minute',
    defaultMessage: 'A minute ago',
    description: 'One minute duration string',
  },
  'device-detail.duration-minutes': {
    id: 'device-detail.duration-minutes',
    defaultMessage: '{count} minutes ago',
    description: 'Multi-minute duration string',
  },
  'device-detail.duration-unknown': {
    id: 'device-detail.duration-unknown',
    defaultMessage: 'Time since last sign-in unknown',
    description: 'Unknown time since last login',
  },
  'device-detail.trusted-on-date': {
    id: 'device-detail.trusted-on-date',
    defaultMessage: 'Trusted on {shortDate}',
    description: 'Date string to display when the device was marked as trusted by the user. Example: \'Trusted on Jan 25, 2019\'',
  },
  'device-detail.security-alert': {
    id: 'device-detail.security-alert',
    defaultMessage: 'Security Alert - unidentified sign-in',
    description: 'Message to display to users that an unidentified sign in occurred using the listed device.',
  },
  'device-detail.current-device': {
    id: 'device-detail.current-device',
    defaultMessage: 'Current device',
    description: 'Message to identify the current device.',
  },
});

export const DeviceDetail = ({
  device,
  onTrustDeviceMenuClick,
  onUnrecognizedDeviceMenuClick,
  onDeviceNameClick,
  clickedDeviceId,
  popoverIsOpen,
  togglePopoverView,
  popoverOpendDeviceId
}) => {
  const intl = useIntl();
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      key={device.token}
      id={device.token}
      onClick={onDeviceNameClick(device.token)}
      className={`${classes['deviceListItem']} ${device.token === clickedDeviceId ? classes['clickedDevice'] : ''}`}
    >
      <div className={`${classes['deviceTypeRow']}`}>
        <Typography variant="body-medium">
          {device.title}
        </Typography>
        {!(device.is_current_device && device.trustedDate) &&
          <div className={classes["deviceLocation"]}>
            <IconButton
              role="button"
              onClick={togglePopoverView(device.token)}
              id="device-more-options"
              title="device-more-options"
              labelId={<FormattedMessage {...t['device-detail.menu-icon-title']} />}
            >
              <MoreMenuVerticalOutlinedIcon />
            </IconButton>
            <PopoverV2
              label={<FormattedMessage {...t['device-detail.menu-icon-title']} />}
              triggerId="device-more-options"
              position="bottom-end"
              menu
              zIndex={1101}
              className="deviceMenuDropdown"
            >
              {!device.is_current_device &&
                <a
                  href="#"
                  onClick={onUnrecognizedDeviceMenuClick(device.token)}
                  aria-label={intl.formatMessage(t['device-detail.menu-dont-recognize-device'])}
                  title={intl.formatMessage(t['device-detail.menu-dont-recognize-device'])}>
                  <MenuItem>
                    <Typography variant="body-small">
                      <FormattedMessage {...t['device-detail.menu-dont-recognize-device']} />
                    </Typography>
                  </MenuItem>
                </a>}
              {!device.trustedDate &&
                <a
                  href="#"
                  onClick={onTrustDeviceMenuClick(device.token)}
                  aria-label={intl.formatMessage(t['device-detail.menu-trust-device'])}
                  title={intl.formatMessage(t['device-detail.menu-trust-device'])}>
                  <MenuItem>
                    <Typography variant="body-small">
                      <FormattedMessage {...t['device-detail.menu-trust-device']} />
                    </Typography>
                  </MenuItem>
                </a>}
            </PopoverV2>
          </div>}
      </div>

      <div className={classes['deviceLocation']}>
        {device.risk >= appconfig.devices.highRiskThreshold &&
          <AlertOutlinedIcon size="14px" color="#D33A61" />}
        {device.risk < appconfig.devices.highRiskThreshold &&
          <PresenceAvailableIcon size="14px" color="#2C72DE" />}&nbsp;
        <Typography tag="p">
          {device.is_current_device &&
            <FormattedMessage {...t['device-detail.current-device']} />}
          {!device.is_current_device &&
            <FormattedMessage
              {...t[device.timeSinceLastLogin.resourceRef]}
              values={{ count: `${device.timeSinceLastLogin.count}` }}
            />}
          <span className={classes['deviceLocationSeparator']}>-</span>
          {`${device.location}`}
        </Typography>
      </div>
      <div>
        {!!device.trustedDate.length &&
          <div className={classes['deviceNote']}>
            <EncryptionOutlinedIcon />&nbsp;
            <Typography variant="body-small" color="type-color-secondary">
              <FormattedMessage
                {...t['device-detail.trusted-on-date']}
                values={{ shortDate: device.trustedDate }}
              />
            </Typography>
          </div>}
      </div>
    </div>
  );
};

DeviceDetail.propTypes = {
  device: PropTypes.object.isRequired,
  onTrustDeviceMenuClick: PropTypes.func.isRequired,
  onUnrecognizedDeviceMenuClick: PropTypes.func.isRequired,
  onDeviceNameClick: PropTypes.func.isRequired,
  clickedDeviceId: PropTypes.string,
  popoverIsOpen: PropTypes.bool,
  togglePopoverView: PropTypes.func,
  popoverOpendDeviceId: PropTypes.string
};

export default DeviceDetail;
