import { combineReducers } from 'redux';
import { intlReducer as intl } from 'react-intl-redux';

import me from 'modules/me-module';
import organization from 'modules/organization-module';
import identity from 'modules/identity-module';
import ui from 'modules/ui-module';
import social from 'modules/social-module';
import billing from 'modules/billing-module';
import tfa from 'modules/tfa-module';
import devices from 'modules/devices-module';
import extAdmin from 'modules/extadmin-module';
import mtgService from 'modules/meeting-module';
import { reducer as formReducer } from 'redux-form';
import personalAccessToken from "modules/personal-access-token-module";

// eslint-disable-next-line no-shadow
export const makeRootReducerFactory = ({ combineReducers, intl, me, organization, identity, social, ui, billing, tfa, devices, extAdmin, mtgService, personalAccessToken }) =>
  (asyncReducers) => combineReducers({
    // Add sync reducers here
    intl,
    me,
    organization,
    identity,
    social,
    ui,
    billing,
    tfa,
    devices,
    extAdmin,
    mtgService,
    personalAccessToken,
    ...asyncReducers,
  });

export const makeRootReducer = makeRootReducerFactory({
  combineReducers,
  intl,
  me,
  organization,
  identity,
  social,
  ui,
  billing,
  tfa,
  devices,
  extAdmin,
  mtgService,
  personalAccessToken,
  form: formReducer,
});

export default makeRootReducer;
