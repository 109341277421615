import {
  daoEmailVerificationRequestPost,
  daoEmailVerificationCompletionPost,
  daoEmailVerificationStatusGet,
} from 'dao/identityservice-dao';

// ------------------------------------
// Constants
// ------------------------------------
export const IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST = 'IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST';
export const IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_SUCCESS = 'IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_SUCCESS';
export const IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_FAILURE = 'IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_FAILURE';


export const IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST = 'IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST';
export const IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_SUCCESS = 'IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_SUCCESS';
export const IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_FAILURE = 'IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_FAILURE';

export const IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET = 'IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET';
export const IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_SUCCESS = 'IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_SUCCESS';
export const IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_FAILURE = 'IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_FAILURE';


// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  emailVerification: {
    email: '',
    token: '',
    status: '',
    expirationTime: '',
    verificationTime: '',
  },

  // generic or global properties go here
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const identityIsLoading = (state) => state.identity.isLoading;
export const identityEmailData = (state) => state.identity.emailVerification;

export const identityEmail = (state) => identityEmailData(state).email;

export const identityEmailToken = (state) => identityEmailData(state).token;

export const identityEmailIsValidated = (state) => {
  const iEmailVerification = identityEmailData(state);
  return iEmailVerification.status.toUpperCase() === 'VERIFIED';
};

// ------------------------------------
// Actions
// ------------------------------------
export const emailVerificationRequestPostSuccess = (payload = {}) => ({
  type: IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_SUCCESS,
  payload,
});

export const emailVerificationRequestPostFailure = (payload = {}) => ({
  type: IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_FAILURE,
  payload,
});

export const emailVerificationRequestPost = (locale, payload = {}) => (dispatch) => {
  dispatch({
    type: IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST,
  });

  return daoEmailVerificationRequestPost(locale, payload)
    .then(
      response => dispatch(emailVerificationRequestPostSuccess(response.data)),
      ex => dispatch(emailVerificationRequestPostFailure(ex)),
    );
};

// Email Verification Completion (with user-entered key)
export const emailVerificationCompletionPostSuccess = (payload = {}) => ({
  type: IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_SUCCESS,
  payload,
});

export const emailVerificationCompletionPostFailure = (payload = {}) => ({
  type: IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_FAILURE,
  payload,
});

export const emailVerificationCompletionPost = (emailToken, payload) => (dispatch) => {
  dispatch({
    type: IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST,
  });

  return daoEmailVerificationCompletionPost(emailToken, payload)
    .then(
      response => dispatch(emailVerificationCompletionPostSuccess(response.data)),
      ex => {
        dispatch(emailVerificationCompletionPostFailure(ex));
        return Promise.reject(ex);
      },
    );
};

// Email Verification Status request
export const emailVerificationStatusGetSuccess = (payload = {}) => ({
  type: IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_SUCCESS,
  payload,
});

export const emailVerificationStatusGetFailure = (payload = {}) => ({
  type: IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_FAILURE,
  payload,
});

export const emailVerificationStatusGet = (emailToken) => (dispatch) => {
  dispatch({
    type: IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET,
  });

  return daoEmailVerificationStatusGet(emailToken)
    .then(
      response => dispatch(emailVerificationStatusGetSuccess(response.data)),
      ex => dispatch(emailVerificationStatusGetFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST]: (state) => ({...state, isLoading: true}),
  [IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        emailVerification: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [IDENTITYSERVICE_EMAILVERIFICATIONREQUEST_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST]: (state) => ({...state, isLoading: true}),
  [IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        emailVerification: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [IDENTITYSERVICE_EMAILVERIFICATIONCOMPLETION_POST_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET]: (state) => ({...state, isLoading: true}),
  [IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        emailVerification: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [IDENTITYSERVICE_EMAILVERIFICATIONSTATUS_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const emailVerificationReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default emailVerificationReducer;
