import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const AnchorIntl = ({a}) => {
  const intl = useIntl();
  return (
    <a
      onClick={a.onClick}
      href={a.href}
      target={a.target || '_blank'}
      rel={a.includeRel ? 'noopener noreferrer' : null}
      title={intl.formatMessage(a.message)}
      aria-label={intl.formatMessage(a.message)}
    >
      {intl.formatMessage(a.message)}
    </a>
  );
};

AnchorIntl.propTypes = {
  a: PropTypes.shape({
    href: PropTypes.string,
    message: PropTypes.shape({
      id: PropTypes.string.isRequired,
      defaultMessage: PropTypes.string.isRequired,
    }),
    onClick: PropTypes.func,
    target: PropTypes.string,
    includeRel: PropTypes.bool,
  })
};

export default AnchorIntl;
