import { defineMessages } from 'react-intl';

const dt = defineMessages({
  'confirmDialog.accept.title': {
    id: 'confirmDialog.accept.title',
    defaultMessage: 'Invitation Accepted',
    description: 'Title for the accept invitation modal dialog',
  },
  'confirmDialog.accept.text': {
    id: 'confirmDialog.accept.text',
    defaultMessage: 'Congratulations! You are now a user on the account {invitingAccountName}. We are transferring your licenses and session history.<br /><br />You will be redirected to the <b><a href="{myAccountUrl}" aria-label="My Account" target="_self">My Account</a></b> page.',
    description: 'Text content for the accept invitation modal dialog',
  },
  'confirmDialog.accept.yesButtonText': {
    id: 'confirmDialog.accept.yesButtonText',
    defaultMessage: 'Accept',
    description: 'Text for the accept invitation modal dialog yes option button',
  },
  'confirmDialog.accept.noButtonText': {
    id: 'confirmDialog.accept.noButtonText',
    defaultMessage: 'Decline',
    description: 'Text for the accept invitation modal dialog no option button',
  },
  'confirmDialog.accept.countDownTimerText': {
    id: 'confirmDialog.accept.countDownTimerText',
    defaultMessage: 'Redirecting to My Account in {seconds} seconds.',
    description: 'Text for the accept invitation modal countdown timer',
  },
  'confirmDialog.decline.title': {
    id: 'confirmDialog.decline.title',
    defaultMessage: 'Are you sure?',
    description: 'Title for the decline client modal dialog',
  },
  'confirmDialog.decline.text': {
    id: 'confirmDialog.decline.text',
    defaultMessage: 'If you decline the invitation, an admin from {invitingAccountName} will need to re-invite you to the account.<br /><br />You will be redirected to the <b>My Account</b> page.',
    description: 'Text content for the decline client modal dialog',
  },
  'confirmDialog.decline.yesButtonText': {
    id: 'confirmDialog.decline.yesButtonText',
    defaultMessage: 'Decline Invitation',
    description: 'Text for the decline invitation modal dialog yes option button',
  },
  'confirmDialog.decline.noButtonText': {
    id: 'confirmDialog.decline.noButtonText',
    defaultMessage: 'Go Back',
    description: 'Text for the decline invitation modal dialog no option button',
  },
  'confirmDialog.deleteToken.title': {
    id: 'confirmDialog.deleteToken.title',
    defaultMessage: 'Delete this token?',
    description: 'Title for the delete token modal dialog',
  },
  'confirmDialog.deleteToken.text': {
    id: 'confirmDialog.deleteToken.text',
    defaultMessage: 'Integrations using the token <b>{name}</b> will stop working immediately.',
    description: 'Text content for the delete token modal dialog',
  },
  'confirmDialog.deleteToken.yesButtonText': {
    id: 'confirmDialog.deleteToken.yesButtonText',
    defaultMessage: 'Delete',
    description: 'Text for the delete token modal dialog yes option button',
  },
  'confirmDialog.deleteToken.noButtonText': {
    id: 'confirmDialog.deleteToken.noButtonText',
    defaultMessage: 'Keep token',
    description: 'Text for the delete token modal dialog no option button',
  },
  'confirmDialog.hasEdits.title': {
    id: 'confirmDialog.hasEdits.title',
    defaultMessage: 'You\'ve made changes',
    description: 'Title for the save changes modal dialog',
  },
  'confirmDialog.hasEdits.text': {
    id: 'confirmDialog.hasEdits.text',
    defaultMessage: 'Would you like to continue editing?',
    description: 'Text content for the hasEdits modal dialog',
  },
  'confirmDialog.hasEdits.yesButtonText': {
    id: 'confirmDialog.hasEdits.yesButtonText',
    defaultMessage: 'Continue editing',
    description: 'Text for the hasEdits modal dialog yes option button',
  },
  'confirmDialog.hasEdits.noButtonText': {
    id: 'confirmDialog.hasEdits.noButtonText',
    defaultMessage: 'Discard changes',
    description: 'Text for the hasEdits modal dialog no option button',
  },
  'confirmDialog.deletePasskey.title': {
    id: 'confirmDialog.deletePasskey.title',
    defaultMessage: 'Delete this passkey?',
    description: 'Title for the delete passkey modal dialog',
  },
  'confirmDialog.deletePasskey.text': {
    id: 'confirmDialog.deletePasskey.text',
    defaultMessage: 'Once you delete <b>{name}</b> you won’t be able to use it to sign in to your GoTo account.',
    description: 'Text content for the delete passkey modal dialog',
  },
  'confirmDialog.deletePasskey.yesButtonText': {
    id: 'confirmDialog.deletePasskey.yesButtonText',
    defaultMessage: 'Delete',
    description: 'Text for the delete paskey modal dialog yes option button',
  },
  'confirmDialog.deletePasskey.noButtonText': {
    id: 'confirmDialog.deletePasskey.noButtonText',
    defaultMessage: 'Keep passkey',
    description: 'Text for the delete paskey modal dialog no option button',
  },
});

export default dt;
