import React from 'react';
import PropTypes from 'prop-types';
import { AnchorIntl } from 'components/intl';
import environment from 'lib/environment';
import {
  FormattedMessage,
  defineMessages,
  useIntl,
} from 'react-intl';
import classes from './signin-security.scss';
import StaticLink from 'components/static-link-view';
import { Typography, ButtonLink } from "@getgo/chameleon-web-react-wrapper";
import st from "core/shared-translations";

const organizationCenterUrl = environment.get().organization_center_url;
const t = defineMessages({
  'enterprise-signin.info-text-has-domain': {
    id: 'enterprise-signin.info-text-has-domain',
    defaultMessage: 'Allow members of your team to sign in with their company username & password (e.g. from Active Directory)',
    description: 'Information string for the Enterprise Sign In section when verified domains have been set up.',
  },
  'enterprise-signin.info-text-no-domain': {
    id: 'enterprise-signin.info-text-no-domain',
    defaultMessage: 'Set up domains so that members of your team can sign in with their company username & password (e.g. from Active Directory)',
    description: 'Information string for the Enterprise Sign In section when no verified domains have been set up',
  },
  'enterprise-signin.add-company-link': {
    id: 'enterprise-signin.add-company-link',
    defaultMessage: 'Add company',
    description: 'Link text for adding a new company to enterprise sign in.',
  },
  'enterprise-signin.edit-company-link': {
    id: 'enterprise-signin.edit-company-link',
    defaultMessage: 'Edit company',
    description: 'Link text for editing company/domains for enterprise sign in.',
  }
});

export const EnterpriseSignIn = ({
  hasVerifiedOrgDomains,
  hideEnterpriseSignInDetails,
  showEditLink,
  isViewOnly = false,
}) => {
  const intl = useIntl();
  return (
    <div>
      {!hideEnterpriseSignInDetails &&
        <div className={classes["enterpriseSignIn"]}>
          <Typography variant="caption-medium-strong">
            <FormattedMessage {...st['shared.enterprise-signin.enterprise-sign-in']} />
          </Typography>
          <Typography variant="caption-medium" color="type-color-secondary">
            <FormattedMessage
              {...t[hasVerifiedOrgDomains ?
                'enterprise-signin.info-text-has-domain' :
                'enterprise-signin.info-text-no-domain']}
            />
          </Typography>
          <ButtonLink
            role="button"
            variant="neutral"
            size="small"
            href={organizationCenterUrl}
            disabled={isViewOnly}
            target="_blank"
          >
            <FormattedMessage
              {...t[showEditLink ?
                'enterprise-signin.edit-company-link' :
                'enterprise-signin.add-company-link']}
            />
          </ButtonLink>
        </div>}
    </div>
  );
};

EnterpriseSignIn.propTypes = {
  hasVerifiedOrgDomains: PropTypes.bool.isRequired,
  hideEnterpriseSignInDetails: PropTypes.bool.isRequired,
  showEditLink: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
};

export default EnterpriseSignIn;
