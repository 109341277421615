/* eslint-disable react/jsx-boolean-value */
import React from "react";
import PropTypes from "prop-types";
import ComponentBadgeImage from "components/component-badge-view";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  injectIntl,
} from "react-intl";
import classes from "./developer-tools.scss";
import PermissionItemView from "components/permission-item-view";
import st from "core/shared-translations";
import appconfig from "config/appconfig";
import {
  Button,
  Typography,
  IconButton,
  InputField,
  Checkbox,
  AlertV2,
  Link
} from "@getgo/chameleon-web-react-wrapper";
import {
  ChevronLeftFilledIcon,
  CloseOutlinedIcon,
  CopyOutlinedIcon
} from "@getgo/chameleon-icons/react";
import commonClass from "styles/_base.scss";

const t = defineMessages({
  "create-token.title": {
    id: "create-token.title",
    defaultMessage: "Create personal access token",
    description: "Title for personal access token",
  },
  "create-token.edit.title": {
    id: "create-token.edit.title",
    defaultMessage: "Edit personal access token",
    description: "Title for edit personal access token",
  },
  "create-token.tab.enter-token-details": {
    id: "create-token.tab.enter-token-details",
    defaultMessage: "Enter token details",
    description: "Title for personal access token tab enter token details",
  },
  "create-token.tab.copy-and-store": {
    id: "create-token.tab.copy-and-store",
    defaultMessage: "Copy and store your token",
    description:
      "Title for personal access token tab copy and store your token",
  },
  "create-token.tab.cancel": {
    id: "create-token.tab.cancel",
    defaultMessage: "Cancel",
    description: "Title for personal access token cancel button",
  },
  "create-token.tab.next": {
    id: "create-token.tab.next",
    defaultMessage: "Next",
    description: "Title for personal access token cancel next",
  },
  "create-token.tab.save": {
    id: "create-token.tab.save",
    defaultMessage: "Save",
    description: "Title for personal access token save button",
  },
  "create-token.name": {
    id: "create-token.name",
    defaultMessage: "Name (required)",
    description: "Field label for user name input",
  },
  "create-token.edit.name": {
    id: "create-token.edit.name",
    defaultMessage: "Name",
    description: "Field label for user name input",
  },
  "create-token.scopes": {
    id: "create-token.scopes",
    defaultMessage: "Scopes",
    description: "Field label for scopes",
  },
  "create-token.name-help-text": {
    id: "create-token.name-help-text",
    defaultMessage:
      "Give your token a unique name to make it easier to identify",
    description: "Field help text for user name input",
  },
  "create-token.credentials.copy-display-text": {
    id: "create-token.credentials.copy-display-text",
    defaultMessage:
      "The token will only be shown once.</br>Make sure to copy it and store it in a secure place.",
    description: "Label for token value copy",
  },
  "create-token.credentials.display-personal-access-token": {
    id: "create-token.credentials.display-personal-access-token",
    defaultMessage: "Personal access token",
    description: "Title for Personal access token",
  },
  "create-token.tab.permissions.instructions": {
    id: "create-token.tab.permissions.instructions",
    defaultMessage: "Select the scopes for this personal access token.",
    description: "Title for scopes info",
  },
  "create-token.logo": {
    id: "create-token.logo",
    defaultMessage: "Create Token logo",
    description: "Hover text to display for the Developer Tools image",
  },
  "create-token.token.copy.confirm": {
    id: "create-token.token.copy.confirm",
    defaultMessage: "I've copied and stored this token",
    description:
      "Body text Credentials - Text for the confirm secret copy check box label.",
  },
  "create-token.validation.name-required": {
    id: "create-token.validation.name-required",
    defaultMessage: "Enter a token name.",
    description: "Content of the error message when Name field is empty",
  },
  "create-token.validation.name-exceeds-max-length": {
    id: "create-token.validation.name-exceeds-max-length",
    defaultMessage: "Name can't be more than 30 characters.",
    description:
      "Content of the error message when the entry in the Name field exceeds more than 30 characters",
  },
});

const CreateTokens = ({
  intl,
  isEditMode,
  token,
  tokenValue,
  errors,
  onChange,
  permissionsList,
  onBlur,
  permissionsButtonClass,
  credentialsButtonClass,
  permissionsContentsClass,
  credentialsContentsClass,
  permissionCheckboxClick,
  permissionGroupCheckboxClick,
  togglePermissionsDetail,
  onCopy,
  isDone,
  onConfirmTokenCopy,
  onSave,
  onCancel,
  enableCTAs,
}) => {
  return (
    <div className={classes["createTokenMain"]}>
      <div className={classes["headerSection"]}>
        <Typography tag="p" variant="heading-small">
          <FormattedMessage {...t[isEditMode ? 'create-token.edit.title' : 'create-token.title']} />
        </Typography>
        <IconButton
          labelId={<FormattedMessage {...t["create-token.tab.cancel"]} />}
          onClick={onCancel}
        >
          <CloseOutlinedIcon role="button" />
        </IconButton>
      </div>
      {!isEditMode && <div className={classes["progressBlock"]}>
        <div className={classes["individualProgressBlock"]} />
        {!credentialsContentsClass && <div className={classes["individualProgressBlock"]} />}
      </div>}
      <div className={classes["overflowContainer"]}>
        <div className={classes["mainForm"]}>
          <div>
            <Typography tag="p" variant="heading-small">
              {!!credentialsContentsClass && <FormattedMessage {...t["create-token.tab.enter-token-details"]} />}
              {!credentialsContentsClass && <FormattedMessage {...t["create-token.tab.copy-and-store"]} />}
            </Typography>
          </div>
          {!!credentialsContentsClass && <div>
            <div>
              <InputField
                id="name-input"
                name="name"
                value={token.name}
                role="textbox"
                title={intl.formatMessage(t["create-token.name"])}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={
                  (errors.name &&
                    intl.formatMessage(st[errors.name] || t[errors.name])) ||
                  intl.formatMessage(t["create-token.name-help-text"])
                }
                fullwidth={true}
                maxLength={!!errors.name && '30'}
              >
                <FormattedMessage {...t["create-token.name"]} />
              </InputField>
            </div>
            <div>
              <Typography tag="p" variant="heading-small">
                <FormattedMessage {...t["create-token.scopes"]} />
              </Typography>
              <Typography
                tag="p"
                variant="body-medium"
                color="rgba(37, 40, 45, 1)"
                className={commonClass["general-text"]}
              >
                <FormattedMessage
                  {...t["create-token.tab.permissions.instructions"]}
                />
              </Typography>
              {Object.keys(permissionsList).map((keyName, i) => (
                <PermissionItemView
                  id="permissionsView"
                  key={`permission-view-${i}`}
                  permissionData={permissionsList[keyName]}
                  isProfilePermission={
                    permissionsList[keyName].name.toLowerCase() === "profile"
                  }
                  onPermissionCheckboxClick={permissionCheckboxClick}
                  onPermissionGroupCheckboxClick={permissionGroupCheckboxClick}
                  togglePermissionsDetail={togglePermissionsDetail}
                />
              ))}
            </div>
          </div>}

          {!credentialsContentsClass &&
            <div id="credentialsContents">
              <AlertV2>
                <FormattedHTMLMessage {...t['create-token.credentials.copy-display-text']} />
              </AlertV2>
              <div className={classes["pesonalTokenIdSection"]}>
                <Typography tag="p" variant="body-medium-strong" color="type-color-default">
                  <FormattedMessage {...t['create-token.credentials.display-personal-access-token']} />
                </Typography>
                <div className={classes["tokenDisplay"]}>
                  <Typography tag="p" variant="button-small">
                    {tokenValue}
                  </Typography>
                  <div className={classes["flexSideSmall"]}>
                    <Button
                      title="copyButton"
                      leadingIcon={<CopyOutlinedIcon color="#2C72DE" />}
                      variant="tertiary"
                      onClick={onCopy(tokenValue)}
                    >
                      <Typography tag="p" variant="body-small-strong" color="type-color-brand-default">
                        <FormattedMessage {...st['shared.copy']} />
                      </Typography>
                    </Button>
                  </div>
                </div>
                <div className={classes["flexBottomLarge"]}>
                  <Button
                    size="large"
                    fullWidth={true}
                    variant="secondary"
                    leadingIcon={<CopyOutlinedIcon color="#2C72DE" />}
                    onClick={onCopy(tokenValue)}
                  >
                    <Typography tag="p" variant="body-small-strong" color="type-color-brand-default">
                      <FormattedMessage {...st['shared.copy']} />
                    </Typography>
                  </Button>
                </div>
                <div>
                  <Checkbox
                    id="confirmCopy"
                    name="confirmCopy"
                    checked={isDone}
                    onChange={onConfirmTokenCopy}
                    title={intl.formatMessage(t['create-token.token.copy.confirm'])}>
                    <FormattedMessage {...t['create-token.token.copy.confirm']} />
                  </Checkbox>
                </div>
              </div>
            </div>}
        </div>
      </div>
      <div className={classes["ctaSection"]}>
        <React.Fragment>
          {!isEditMode && !!credentialsContentsClass && <Button variant="neutral" leadingIcon={<ChevronLeftFilledIcon />} onClick={onCancel}>
            {intl.formatMessage(t["create-token.tab.cancel"])}
          </Button>}
          {!!credentialsContentsClass && <Button
            disabled={!enableCTAs}
            onClick={onSave}
            style={{ marginLeft: "auto" }}
            title={isEditMode ? intl.formatMessage(t['create-token.tab.save']) : intl.formatMessage(t['create-token.tab.next'])}>
            {isEditMode ? intl.formatMessage(t['create-token.tab.save']) : intl.formatMessage(t["create-token.tab.next"])}
          </Button>}
        </React.Fragment>
        {!credentialsContentsClass && !isEditMode &&
          <Link
            href={appconfig.routes.developerTools()}
            id="token-list-add"
            props={{ 'aria-label': intl.formatMessage(st['shared.done']) }}
            className={classes["doneBtn"]}
            disabled={!isDone}
          >
            <Button disabled={!isDone}>
              <FormattedMessage {...st['shared.done']} />
            </Button>
          </Link>}
      </div>
    </div>
  );
};

CreateTokens.propTypes = {
  intl: PropTypes.object,
  isEditMode: PropTypes.bool.isRequired,
  token: PropTypes.object.isRequired,
  tokenValue: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  permissionsButtonClass: PropTypes.string.isRequired,
  credentialsButtonClass: PropTypes.string.isRequired,
  permissionsContentsClass: PropTypes.string.isRequired,
  credentialsContentsClass: PropTypes.string.isRequired,
  permissionCheckboxClick: PropTypes.func.isRequired,
  permissionGroupCheckboxClick: PropTypes.func.isRequired,
  togglePermissionsDetail: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool,
  permissionsList: PropTypes.object.isRequired,
  onCopy: PropTypes.func.isRequired,
  isDone: PropTypes.bool.isRequired,
  onConfirmTokenCopy: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  enableCTAs: PropTypes.bool.isRequired,
};

export default injectIntl(CreateTokens);