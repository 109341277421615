import React from 'react';
import PropTypes from 'prop-types';
import classes from './accept-invitation.scss';
import {
  useIntl,
  defineMessages,
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';
import ProductListItem from "core/components/product-view";
import environment from 'lib/environment';
import commonClasses from "styles/_base.scss";
import {
  Typography,
  Button,
  CircularProgress
} from "@getgo/chameleon-web-react-wrapper";
import { PriorityNormalOutlinedIcon } from '@getgo/chameleon-icons/react';

const featuresChangeURL = environment.get().user_invitation_changes_reference_url;
const myAccountURL = environment.get().myAccount_url;

const t = defineMessages({
  'accept-invitation.icon-text': {
    id: 'accept-invitation.icon-text',
    defaultMessage: 'Accept invitation icon',
    description: 'Hover text to display for the accept invitation image',
  },
  'accept-invitation.title': {
    id: 'accept-invitation.title',
    defaultMessage: 'Transfer your licenses to a different account',
    description: 'Accept invitation page title',
  },
  'accept-invitation.invitation.expired.title': {
    id: 'accept-invitation.invitation.expired.title',
    defaultMessage: 'No invitation for {invitedUserEmail}',
    description: 'Accept invitation page title for expired invitations',
  },
  'accept-invitation.invitation.expired.p1': {
    id: 'accept-invitation.invitation.expired.p1',
    defaultMessage: 'You were invited to join an account, but the invitation no longer exists. You may have already accepted or declined it, or the admin may have modified or deleted it.',
    description: 'First paragraph of expired invitation body text',
  },
  'accept-invitation.invitation.expired.p2': {
    id: 'accept-invitation.invitation.expired.p2',
    defaultMessage: 'What can I do now?',
    description: 'Second paragraph of expired invitation body text',
  },
  'accept-invitation.invitation.expired.p3': {
    id: 'accept-invitation.invitation.expired.p3',
    defaultMessage: 'If you need to join this account, contact the admin from the information in the invitation email.',
    description: 'Third paragraph of expired invitation body text',
  },
  'accept-invitation.invitation.expired.p4': {
    id: 'accept-invitation.invitation.expired.p4',
    defaultMessage: 'Sign in with a different email',
    description: 'Fourth paragraph of expired invitation body text',
  },
  'accept-invitation.invitation.expired.p5': {
    id: 'accept-invitation.invitation.expired.p5',
    defaultMessage: '<a href="{myAccountURL}">View my current account</a>',
    description: 'Fifth paragraph of expired invitation body text',
  },
  'accept-invitation.section-1-title': {
    id: 'accept-invitation.section-1-title',
    defaultMessage: 'Why am I seeing this?',
    description: 'Section 1 title text',
  },
  'accept-invitation.section-1-p1': {
    id: 'accept-invitation.section-1-p1',
    defaultMessage: 'You have an existing profile with GoTo products.',
    description: 'Section 1 paragraph 1 body text',
  },
  'accept-invitation.section-1-p2-1-product': {
    id: 'accept-invitation.section-1-p2-1-product',
    defaultMessage: 'You have been invited to use <b>{lastProductName}</b> with <b>{invitingAccountName}</b>.',
    description: 'Section 1 paragraph 2 body text with one product',
  },
  'accept-invitation.section-1-p2-2-products': {
    id: 'accept-invitation.section-1-p2-2-products',
    defaultMessage: 'You have been invited to use <b>{firstProductNames}</b> and <b>{lastProductName}</b> with <b>{invitingAccountName}</b>.',
    description: 'Section 1 paragraph 2 body text with two products',
  },
  'accept-invitation.section-1-p2-many-products': {
    id: 'accept-invitation.section-1-p2-many-products',
    defaultMessage: 'You have been invited to use <b>{firstProductNames},</b> and <b>{lastProductName}</b> with <b>{invitingAccountName}</b>.',
    description: 'Section 1 paragraph 2 body text with many products',
  },
  'accept-invitation.section-2-title': {
    id: 'accept-invitation.section-2-title',
    defaultMessage: 'What happens if I accept?',
    description: 'Section 2 title text',
  },
  'accept-invitation.section-2-p1': {
    id: 'accept-invitation.section-2-p1',
    defaultMessage: 'Your session history, scheduled sessions, and recordings will transfer to a new account.',
    description: 'Section 2 bullet point 1 text',
  },
  'accept-invitation.section-2-p2': {
    id: 'accept-invitation.section-2-p2',
    defaultMessage: 'Settings and available features may change. <a href="{featuresChangeURL}" target="_blank" rel="noopener noreferrer">What could change?</a>',
    description: 'Section 2 bullet point 2 text',
  },
  'accept-invitation.manager-in-one-account': {
    id: 'accept-invitation.manager-in-one-account',
    defaultMessage: 'You will remain an Account Manager for <b>{account}</b>.  If you are also the Billing Admin for this account, you can manage existing subscriptions in the <b>Billing Center</b>.',
    description: 'Section 2 bullet for when manager in one account',
  },
  'accept-invitation.manager-in-more-accounts': {
    id: 'accept-invitation.manager-in-more-accounts',
    defaultMessage: 'You will remain an Account Manager for <b>{accounts}</b> and <b>{account}</b>. If you are also the Billing Admin for this account, you can manage existing subscriptions in the <b>Billing Center</b>.',
    description: 'Section 2 bullet for when manager in more than one account',
  },
  'accept-invitation.admin-in-one-account': {
    id: 'accept-invitation.admin-in-one-account',
    defaultMessage: 'You will remain an Admin for <b>{account}</b>. If you are also the Billing Admin for this account, you can manage existing subscriptions in the <b>Billing Center</b>.',
    description: 'Section 2 bullet for when user is an admin in one account',
  },
  'accept-invitation.admin-in-more-accounts': {
    id: 'accept-invitation.admin-in-more-accounts',
    defaultMessage: 'You will remain an Admin for <b>{accounts}</b> and <b>{account}</b>. If you are also the Billing Admin for these accounts, you can manage existing subscriptions in the <b>Billing Center</b>.',
    description: 'Section 2 bullet for when user is an admin in more than one account',
  },
  'accept-invitation.accept.button': {
    id: 'accept-invitation.accept.button',
    defaultMessage: 'Accept Invitation',
    description: 'Accept invitation button text',
  },
  'accept-invitation.decline.button': {
    id: 'accept-invitation.decline.button',
    defaultMessage: 'Decline',
    description: 'Decline invitation button text',
  },
  'accept-invitation.prev-productlist-headline': {
    id: 'accept-invitation.prev-productlist-headline',
    defaultMessage: 'Your <b>{accountName}</b> account includes:',
    description: 'Headline for the previous products list',
  },
  'accept-invitation.prev-productlist-not-available': {
    id: 'accept-invitation.prev-productlist-not-available',
    defaultMessage: 'Current account name and product list is not available.',
    description: 'Headline for the previous products list when not available',
  },
  'accept-invitation.prev-productlist-multiple-accounts': {
    id: 'accept-invitation.prev-productlist-multiple-accounts',
    defaultMessage: 'Products from <b>{accountNames}</b> and <b>{accountName}</b> includes:',
    description: 'Headline for the previous products list from multiple accounts',
  },
  'accept-invitation.new-productlist-headline': {
    id: 'accept-invitation.new-productlist-headline',
    defaultMessage: 'The <b>{invitingAccountName}</b> account includes:',
    description: 'Headline for the new products list',
  },
  'accept-invitation.invited-license-na-title': {
    id: 'accept-invitation.invited-license-na-title',
    defaultMessage: 'Oops... there is a problem with the new account',
    description: 'Accept invitation page title',
  },
  'accept-invitation.invited-license-na-text-p1': {
    id: 'accept-invitation.invited-license-na-text-p1',
    defaultMessage: 'The license(s) you were invited to use are not available at this time.',
    description: 'Section body text for when the invited license is not available.',
  },
  'accept-invitation.invited-license-na-text-p2': {
    id: 'accept-invitation.invited-license-na-text-p2',
    defaultMessage: 'If you need more information, contact the admin from the information in the invitation email.',
    description: 'Section body text for when the invited license is not available, and the user needs more information',
  },
  'accept-invitation.invited-license-na-cta-use-existing-account': {
    id: 'accept-invitation.invited-license-na-cta-use-existing-account',
    defaultMessage: 'I\'ll use my existing account.',
    description: 'Button text for decline invitation when no license is in the new account',
  },
  'accept-invitation.invited-license-na-cta-try-again-later': {
    id: 'accept-invitation.invited-license-na-cta-try-again-later',
    defaultMessage: 'I\'ll try again later.',
    description: 'Button text for when no license is in the new account and user wants to return later to try again',
  },
});

const AcceptInvitation = ({
  isInvitationValid,
  invitedLicenseCount,
  lastProductName,
  firstProductNames = "",
  invitingAccountName,
  managerInAccounts,
  adminInAccounts,
  acceptInvitation,
  showDeclineDialog,
  noOfConflictingAccounts,
  conflictingAccountNames,
  conflictingLicenses,
  invitedLicenses,
  eaIsLoading,
  invitedUserEmail = "",
  logout,
  redirectToMyAccount,
}) => {
  const intl = useIntl();
  return (
    <div className={classes["acceptInvitationBlock"]}>
      {eaIsLoading &&
        <div className={commonClasses["centerAnyContent"]}>
          <CircularProgress />
        </div>}
      {!eaIsLoading &&
        <div id="accept-invitation-section" className={classes['acceptInvitationContainer']}>
          <form className={classes["mainForm"]}>
            <div className={`row ${classes['titleRow']}`}>
              <Typography variant="heading-medium" id="expInvitationTitle">
                <FormattedMessage
                  {...t[isInvitationValid ?
                    (invitedLicenseCount ? 'accept-invitation.title' : 'accept-invitation.invited-license-na-title') :
                    'accept-invitation.invitation.expired.title']}
                  values={{ invitedUserEmail: invitedUserEmail }}
                />
              </Typography>
            </div>
            {!isInvitationValid &&
              <div className={classes['gapedText']}>
                <Typography variant="body-medium" id="expInvitationExplanation">
                  <FormattedMessage {...t['accept-invitation.invitation.expired.p1']} />
                </Typography>
                <Typography variant="heading-small" id="expInvitationWhatCanIDoTitle">
                  <FormattedMessage {...t['accept-invitation.invitation.expired.p2']} />
                </Typography>
                <Typography variant="body-medium" id="expInvitationWhatCanIDoParagraph">
                  <FormattedMessage {...t['accept-invitation.invitation.expired.p3']} />
                </Typography>
                <Typography variant="body-medium">
                  <a href="#" id="invitation-logout" onClick={logout}>
                    <FormattedMessage {...t['accept-invitation.invitation.expired.p4']} /></a>
                </Typography>
                <Typography variant="body-medium">
                  <FormattedHTMLMessage {...t['accept-invitation.invitation.expired.p5']} values={{ myAccountURL }} />
                </Typography>
              </div>}
            {isInvitationValid &&
              <div className="row">
                <div className="col-xs-12">
                  {!invitedLicenseCount &&
                    <div className={classes['gapedText']}>
                      <Typography variant="body-medium" id="invitedLicenseNotAvailable">
                        <FormattedMessage {...t['accept-invitation.invited-license-na-text-p1']} />
                      </Typography>
                      <Typography variant="heading-small" id="invitationSubTitleLicNA">
                        <FormattedMessage {...t['accept-invitation.invitation.expired.p2']} />
                      </Typography>
                      <Typography variant="body-medium" id="invitedLicenseNotAvailableWhatCanIDoParagraph">
                        <FormattedMessage {...t['accept-invitation.invited-license-na-text-p2']} />
                      </Typography>
                    </div>}
                  {!!invitedLicenseCount &&
                    <div id="whyAmISeeingThis" className={classes['gapedText']}>
                      <Typography variant="heading-small">
                        <FormattedMessage {...t['accept-invitation.section-1-title']} />
                      </Typography>
                      <Typography variant="body-medium">
                        <FormattedHTMLMessage {...t['accept-invitation.section-1-p1']} />
                      </Typography>
                      <Typography variant="body-medium">
                        {invitedLicenseCount === 1 &&
                          <FormattedHTMLMessage
                            {...t['accept-invitation.section-1-p2-1-product']}
                            values={{
                              lastProductName: lastProductName,
                              invitingAccountName: invitingAccountName
                            }} />}
                        {invitedLicenseCount === 2 &&
                          <FormattedHTMLMessage
                            {...t['accept-invitation.section-1-p2-2-products']}
                            values={{
                              firstProductNames: firstProductNames,
                              lastProductName: lastProductName,
                              invitingAccountName: invitingAccountName
                            }} />}
                        {invitedLicenseCount > 2 &&
                          <FormattedHTMLMessage
                            {...t['accept-invitation.section-1-p2-many-products']}
                            values={{
                              firstProductNames: firstProductNames,
                              lastProductName: lastProductName,
                              invitingAccountName: invitingAccountName
                            }} />}
                      </Typography>
                    </div>}
                  {!!invitedLicenseCount &&
                    <div>
                      <div id="whatHappens" className={classes["whathapensBody"]}>
                        <Typography variant="heading-small">
                          <FormattedMessage {...t['accept-invitation.section-2-title']} />
                        </Typography>
                        <ul className={classes['bulletPoints']}>
                          <li className={classes["infoTextPointer"]}>
                            <Typography variant="body-medium">
                              <FormattedMessage {...t['accept-invitation.section-2-p1']} />
                            </Typography>
                          </li>
                          <li className={classes["infoTextPointer"]}>
                            <Typography variant="body-medium">
                              <FormattedHTMLMessage
                                {...t['accept-invitation.section-2-p2']}
                                values={{ featuresChangeURL: featuresChangeURL }} />
                            </Typography>
                          </li>
                          {managerInAccounts.length === 1 &&
                            <li className={classes["infoTextPointer"]}>
                              <Typography variant="body-medium">
                                <FormattedHTMLMessage
                                  {...t['accept-invitation.manager-in-one-account']}
                                  values={{ account: managerInAccounts[0] }} />
                              </Typography>
                            </li>}
                          {managerInAccounts.length > 1 &&
                            <li className={classes["infoTextPointer"]}>
                              <Typography variant="body-medium">
                                <FormattedHTMLMessage
                                  {...t['accept-invitation.manager-in-more-accounts']}
                                  values={{ accounts: managerInAccounts[0], account: managerInAccounts[1] }} />
                              </Typography>
                            </li>}
                          {adminInAccounts.length === 1 &&
                            <li className={classes["infoTextPointer"]}>
                              <Typography variant="body-medium">
                                <FormattedHTMLMessage
                                  {...t['accept-invitation.admin-in-one-account']}
                                  values={{ account: adminInAccounts[0] }} />
                              </Typography>
                            </li>}
                          {adminInAccounts.length > 1 &&
                            <li className={classes["infoTextPointer"]}>
                              <Typography variant="body-medium">
                                <FormattedHTMLMessage
                                  {...t['accept-invitation.admin-in-more-accounts']}
                                  values={{ accounts: adminInAccounts[0], account: adminInAccounts[1] }} />
                              </Typography>
                            </li>}
                        </ul>
                      </div>
                    </div>}
                  {!!invitedLicenseCount &&
                    <div className={`${classes['productsRow']}`}>
                      <div
                        className={`col-xs-12 col-sm-6 ${classes['productsColumn']}`}
                        id="fromAccount"
                      >
                        <Typography variant="body-small">
                          {noOfConflictingAccounts === 0 &&
                            <FormattedHTMLMessage
                              {...t['accept-invitation.prev-productlist-not-available']} />}
                          {noOfConflictingAccounts === 1 &&
                            <FormattedHTMLMessage
                              {...t['accept-invitation.prev-productlist-headline']}
                              values={{ accountName: conflictingAccountNames.lastAccountName }} />}
                          {noOfConflictingAccounts > 1 &&
                            <FormattedHTMLMessage
                              {...t['accept-invitation.prev-productlist-multiple-accounts']}
                              values={
                                {
                                  accountNames: conflictingAccountNames.firstAccountNames,
                                  accountName: conflictingAccountNames.lastAccountName
                              }
                              } />}
                        </Typography>
                        {noOfConflictingAccounts > 0 && !!conflictingLicenses.length &&
                          conflictingLicenses.map(product => <ProductListItem key={product.id} product={product} />)}
                      </div>
                      <div className={classes["transferIcon"]}>
                        <PriorityNormalOutlinedIcon size="34px" />
                      </div>
                      <div
                        className={`col-xs-12 col-sm-6 ${classes['productsColumn']}`}
                        id="toAccount"
                      >
                        <div className={classes['productsHeadline']}>
                          <Typography variant="body-small">
                            <FormattedHTMLMessage
                              {...t['accept-invitation.new-productlist-headline']}
                              values={{ invitingAccountName: invitingAccountName }} />
                          </Typography>
                        </div>
                        {!!invitedLicenses.length &&
                          invitedLicenses.map(product => <ProductListItem key={product.id} product={product} />)}
                      </div>
                    </div>}
                  {!invitedLicenseCount &&
                    <div className={`row ${classes['buttonRow']}`}>
                      <Button
                        id="accept-invitation-try-again-later"
                        variant="neutral"
                        onClick={redirectToMyAccount}
                        fullWidth
                        size="large"
                      >
                        <FormattedMessage {...t['accept-invitation.invited-license-na-cta-try-again-later']} />
                      </Button>
                      <Button
                        id="accept-invitation-use-existing-account"
                        fullWidth
                        onClick={showDeclineDialog}
                        size="large"
                      >
                        <FormattedMessage {...t['accept-invitation.invited-license-na-cta-use-existing-account']} />
                      </Button>
                    </div>}
                  {!!invitedLicenseCount &&
                    <div className={`row ${classes['buttonRow']}`}>
                      <Button
                        id="accept-invitation-reject"
                        onClick={showDeclineDialog}
                        variant="neutral"
                        fullWidth
                        role="button"
                        size="large"
                      >
                        <FormattedMessage {...t['accept-invitation.decline.button']} />
                      </Button>
                      <Button
                        id="accept-invitation-accept"
                        onClick={acceptInvitation}
                        fullWidth
                        role="button"
                        size="large"
                      >
                        <FormattedMessage {...t['accept-invitation.accept.button']} />
                      </Button>
                    </div>}
                </div>
              </div>}
          </form>
        </div>}
    </div>
  );
};

AcceptInvitation.propTypes = {
  isInvitationValid: PropTypes.bool.isRequired,
  invitedLicenseCount: PropTypes.number.isRequired,
  lastProductName: PropTypes.string.isRequired,
  firstProductNames: PropTypes.string,
  invitingAccountName: PropTypes.string.isRequired,
  managerInAccounts: PropTypes.array.isRequired,
  adminInAccounts: PropTypes.array.isRequired,
  acceptInvitation: PropTypes.func.isRequired,
  showDeclineDialog: PropTypes.func.isRequired,
  noOfConflictingAccounts: PropTypes.number.isRequired,
  conflictingAccountNames: PropTypes.object.isRequired,
  conflictingLicenses: PropTypes.array.isRequired,
  invitedLicenses: PropTypes.array.isRequired,
  eaIsLoading: PropTypes.bool.isRequired,
  invitedUserEmail: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  redirectToMyAccount: PropTypes.func.isRequired,
};

export default AcceptInvitation;
