import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const apiBaseUrl = { baseURL: environment.get().api.serviceUrl };
const forceSamlSsoEndpoint = appconfig.endpoints.forceSamlSso;
const loginOptionsEndpoint = appconfig.endpoints.loginOptions;

export const daoForceSamlSsoGet = (email) => api.get(`${forceSamlSsoEndpoint}?email=${encodeURIComponent(email)}`, apiBaseUrl);

export const daoLoginOptions = (email) => api.get(loginOptionsEndpoint(email), apiBaseUrl);