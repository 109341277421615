import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EnhancedSecurity from "../components/enhanced-security-view";
import {
  meEnhancedSecurity,
  meRequestPatch,
  meTfaEnrolled,
  meIsConflicted,
} from "modules/me-module";
import environment, { set } from "lib/environment";
import { uiShowToastrMessage } from "modules/ui-module";
import auth from "lib/profile-auth";
import Session from "lib/session";
import appconfig from "config/appconfig";
import { stopEvent } from "lib/utility-functions";
import { personalAccessTokenGet } from "modules/personal-access-token-module";

const serviceUrl = environment.get().api.serviceUrl;

export const EnhancedSecurityContainer = () => {
  const { enhancedSecurity, tfaEnrolled, isViewOnly } = useSelector(getValuesFromStore);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newEnhancedSecurity, setNewEnhancedSecurity] = useState(enhancedSecurity);
  const [userHasPatTokens, setUserHasPatTokens] = useState(false);

  const dispatch = useDispatch();

  const openModal = () => {
    setModalIsOpen(true);
    return true;
  };

  const closeModal = (e) => {
    stopEvent(e);
    setModalIsOpen(false);
    setNewEnhancedSecurity(enhancedSecurity);
    return true;
  };

  const updateEnhacedSecurity = (e) => {
    stopEvent(e);
    const newEnhancedSecurityVal = e.target.checked;
    setNewEnhancedSecurity(newEnhancedSecurityVal);
  };

  const enableCTA = () => {
    if ((typeof newEnhancedSecurity !== "undefined" &&
      (enhancedSecurity !== newEnhancedSecurity)) ||
      (typeof newEnhancedSecurity == "boolean" && (enhancedSecurity !== newEnhancedSecurity))) {
      return true;
    }
    return false;
  };

  const displayToastrMessage = (toastrType, toastrMessageId) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId));
  };

  const getEnrollmentURL = (addSetupParam) => {
    let redirectURL = location.href;
    if (addSetupParam) {
      redirectURL +=
        (location.search ? "&" : "?") +
        appconfig.storage.enhancedSecuritySetup +
        "=1";
    }
    return `${serviceUrl}enroll2fa?service=${encodeURIComponent(redirectURL)}`;
  };

  const setUpEnhancedSecurity = () => () => {
    Session.setItem(appconfig.storage.enhancedSecuritySetup, "1");
    const enrollmentURL = getEnrollmentURL(true);
    window.location.assign(enrollmentURL);
    return false;
  };

  const toggleEnhancedSecurity = () => {
    const enhancedSecurityEnabled = newEnhancedSecurity;
    const payload = {};
    payload[appconfig.scimSchemas.getGo] = {
      enhancedSecurity: enhancedSecurityEnabled,
    };
    return dispatch(meRequestPatch(payload)).then(
      () => {
        setModalIsOpen(false);
        displayToastrMessage(
          "success",
          "enhanced-security.enhanced-security-save-successful"
        );
        const forceReload = !!(auth.token && auth.token.loa !== 3);
        if (enhancedSecurityEnabled && forceReload) {
          // if the enhanced security setting was set to true and the current loa is not 3, force MFA login.
          Session.setItem("minLoa", "3");
          auth.clear();
          window.location.assign(window.location.href.split("?")[0]);
        }
      },
      () => {
        displayToastrMessage(
          "error",
          "enhanced-security.enhanced-security-save-failed"
        );
      }
    );
  };

  const fetchTokenList = () => {
    dispatch(personalAccessTokenGet()).then(
      (response) => {
        const tokenData = response.payload?.tokens || [];
        if (tokenData.length) {
          setUserHasPatTokens(true);
        }
      }
    );
  };

  useEffect(() => {
    fetchTokenList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EnhancedSecurity
      setUpEnhancedSecurityOnClick={setUpEnhancedSecurity()}
      toggleEnhancedSecurity={toggleEnhancedSecurity}
      resetDeviceOnClick={getEnrollmentURL(false)}
      meEnhancedSecurity={enhancedSecurity}
      meTfaEnrolled={tfaEnrolled}
      isViewOnly={isViewOnly}
      modalIsOpen={modalIsOpen}
      openModal={openModal}
      closeModal={closeModal}
      updateEnhacedSecurity={updateEnhacedSecurity}
      enhancedSecurityVal={newEnhancedSecurity}
      enableCTA={enableCTA()}
      userHasPatTokens={userHasPatTokens}
    />
  );
};
export const getValuesFromStore = (state) => ({
  enhancedSecurity: meEnhancedSecurity(state),
  tfaEnrolled: meTfaEnrolled(state),
  isViewOnly: meIsConflicted(state),
});

export default EnhancedSecurityContainer;
