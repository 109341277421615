import {
  daoExtAdminMeWithInviationGet,
  daoExtAdminInvitationAccept,
  daoExtAdminInvitationDecline,
} from 'dao/extadmin-dao';
import appconfig from 'config/appconfig';

// ------------------------------------
// Constants
// ------------------------------------
export const EXT_ADMIN_ME_GET = 'EXT_ADMIN_ME_GET';
export const EXT_ADMIN_ME_GET_SUCCESS = 'EXT_ADMIN_ME_GET_SUCCESS';
export const EXT_ADMIN_ME_GET_FAILURE = 'EXT_ADMIN_ME_GET_FAILURE';

export const EXT_ADMIN_INVITATION_ACCEPT_POST = 'EXT_ADMIN_INVITATION_ACCEPT_POST';
export const EXT_ADMIN_INVITATION_ACCEPT_POST_SUCCESS = 'EXT_ADMIN_INVITATION_ACCEPT_POST_SUCCESS';
export const EXT_ADMIN_INVITATION_ACCEPT_POST_FAILURE = 'EXT_ADMIN_INVITATION_ACCEPT_POST_FAILURE';

export const EXT_ADMIN_INVITATION_DECLINE_DELETE = 'EXT_ADMIN_INVITATION_DECLINE_DELETE';
export const EXT_ADMIN_INVITATION_DECLINE_DELETE_SUCCESS = 'EXT_ADMIN_INVITATION_DECLINE_DELETE_SUCCESS';
export const EXT_ADMIN_INVITATION_DECLINE_DELETE_FAILURE = 'EXT_ADMIN_INVITATION_DECLINE_DELETE_FAILURE';

export const EXT_ADMIN_SET_INVITATION_ID = 'EXT_ADMIN_SET_INVITATION_ID';

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  invitationId: "",
  accountWithInvitation: null,
  invitationStatus: "",

  // generic or global properties go here
  isLoading: false,
  isActionLoading: false
};

// ------------------------------------
// Selectors
// ------------------------------------
export const eaIsLoading = (state) => state.extAdmin.isLoading;

export const eaAdminData = (state) => state.extAdmin.extAdminMe || null;

export const getMyAccount = (state) => {
  let myAccount;
  const adminData = eaAdminData(state);
  if(adminData && adminData.accounts && adminData.accounts.length) {
    myAccount = adminData.accounts.find((account) => account.key === adminData.accountKey);
  }
  return myAccount || null;
};

export const getMyAccountKey = (state) => {
  const myAccount = getMyAccount(state);
  return myAccount && myAccount.key;
};

export const getMyUserKey = (state) => {
  const adminData = eaAdminData(state);
  return adminData && adminData.key;
};

export const getMyEmailAddress = (state) => {
  const adminData = eaAdminData(state);
  return adminData && adminData.email;
};

export const getInvitationId = (state) => state.extAdmin.invitationId;

export const getInvitingAccount = (state) => {
  let invitingAccount = null;
  const adminData = eaAdminData(state);
  if(adminData && adminData.accounts && adminData.accounts.length) {
    invitingAccount = adminData.accounts.find((account) => {
      if(account.invitation && account.invitation.id === getInvitationId(state))
        return account;
    });
  }
  return invitingAccount || null;
};

export const getInvitingAccountKey = (state) => {
  const invitingAccount = getInvitingAccount(state);
  return invitingAccount && invitingAccount.key;
};

export const getInvitingAccountName = (state) => {
  const invitingAccount = getInvitingAccount(state);
  return invitingAccount && invitingAccount.name;
};

const fixLicenseKey = (licenseKey) => {
  const licKey = licenseKey.toLowerCase();
  return licKey === 'g2c' ? 'gotoconnect' : licKey;  // EA API sends "G2C" as product id, but PMP uses "gotoconnect"
};

export const getInvitedLicenses = (state) => {
  const invitedLicenses = [];
  const invitingAccount = getInvitingAccount(state);
  if(invitingAccount &&
    invitingAccount.invitation.invitedLicenseKeys &&
    invitingAccount.invitation.invitedLicenseKeys.length) {
    invitingAccount.invitation.invitedLicenseKeys.forEach((licenseKey) => {
      let objLicense = invitingAccount.licenses.find((license) => license.key === licenseKey);
      if (objLicense) {
        invitedLicenses.push({description:objLicense.description, id:fixLicenseKey(objLicense.products[objLicense.products.length -1])});
      }
    });
  }
  return invitedLicenses.sort( (a, b) => (a.description > b.description) ? 1 : -1 );
};

export const isInvitationValid = (state) => !!getInvitingAccount(state);

export const getConflictingAccountByKey = (state, key) => {
  let foundAccount;
  const adminData = eaAdminData(state);
  if(adminData && adminData.accounts && adminData.accounts.length) {
    foundAccount = adminData.accounts.find((account) => account.key === key);
  }
  return foundAccount || null;
};

const isManagerInAccount = (account) => {
  return account && account.adminRoles && account.adminRoles.length &&
    !account.adminRoles.some(r => r === 'SUPER_USER');
};

const isAdminInAccount = (account) => {
  return account && account.adminRoles && account.adminRoles.length &&
    account.adminRoles.some(r => r === 'SUPER_USER');
};

const isBillingAdminInAccount = (state, account) => {
  const myAccount = getMyAccount(state);
  return myAccount && account && account.billingAdmin && account.billingAdmin.key === myAccount.key;
};

export const getConflictingAccountDetails = (state) => {
  const conflictingAccountNames = [];
  const conflictingLicenses = [];
  const managerInAccounts = [];
  const adminInAccounts = [];
  const invitingAccount = getInvitingAccount(state);
  if (invitingAccount &&
    invitingAccount.invitation &&
    invitingAccount.invitation.conflicts &&
    invitingAccount.invitation.conflicts.length) {
      invitingAccount.invitation.conflicts.forEach( (conflict) => {
        let conflictingAccount = getConflictingAccountByKey(state, conflict.accountKey);
        if (conflictingAccount) {
          conflictingAccountNames.push(conflictingAccount.name);
          if (isManagerInAccount(conflictingAccount)) {
            managerInAccounts.push(conflictingAccount.name);
          }
          if (isAdminInAccount(conflictingAccount) && !isBillingAdminInAccount(state, conflictingAccount)) {
            adminInAccounts.push(conflictingAccount.name);
          }
          conflict.licenseKeys.forEach( (licenseKey) => {
            let objLicense = conflictingAccount.licenses.find((license) => license.key === licenseKey);
            if (objLicense) {
              conflictingLicenses.push({description:objLicense.description, id:fixLicenseKey(objLicense.products[objLicense.products.length -1])});
            }
          });
        }
      });
  }
  return {
    conflictingAccountNames,
    conflictingLicenses: conflictingLicenses.sort( (a, b) => (a.description > b.description) ? 1 : -1 ),
    managerInAccounts: managerInAccounts.sort(),
    adminInAccounts: adminInAccounts.sort(),
  };
};

// ------------------------------------
// Actions
// ------------------------------------
export const extAdminMeWithInvitationsReceiveGetSuccess = (payload = {}) => ({
  type: EXT_ADMIN_ME_GET_SUCCESS,
  payload,
});

export const extAdminMeWithInvitationsReceiveGetFailure = (payload = {}) => ({
  type: EXT_ADMIN_ME_GET_FAILURE,
  payload,
});

export const extAdminMeRequestWithInvitationsGet = () => (dispatch) => {
  dispatch({
    type: EXT_ADMIN_ME_GET,
  });

  return daoExtAdminMeWithInviationGet()
    .then(
      response => dispatch(extAdminMeWithInvitationsReceiveGetSuccess(response.data)),
      ex => dispatch(extAdminMeWithInvitationsReceiveGetFailure(ex)),
    );
};

export const extAdminInvitationAcceptSuccess = (payload = {}) => ({
  type: EXT_ADMIN_INVITATION_ACCEPT_POST_SUCCESS,
  payload,
});

export const extAdminInvitationAcceptFailure = (payload = {}) => ({
  type: EXT_ADMIN_INVITATION_ACCEPT_POST_FAILURE,
  payload,
});

export const extAdminInvitationAccept = (params = {}) => (dispatch) => {
  dispatch({
    type: EXT_ADMIN_INVITATION_ACCEPT_POST,
  });

  return daoExtAdminInvitationAccept(params)
    .then(
      response => dispatch(extAdminInvitationAcceptSuccess(response.data)),
      ex => {
        dispatch(extAdminInvitationAcceptFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const extAdminInvitationDeclineSuccess = (payload = {}) => ({
  type: EXT_ADMIN_INVITATION_DECLINE_DELETE_SUCCESS,
  payload,
});

export const extAdminInvitationDeclineFailure = (payload = {}) => ({
  type: EXT_ADMIN_INVITATION_DECLINE_DELETE_FAILURE,
  payload,
});

export const extAdminInvitationDecline = (params = {}) => (dispatch) => {
  dispatch({
    type: EXT_ADMIN_INVITATION_DECLINE_DELETE,
  });

  return daoExtAdminInvitationDecline(params)
    .then(
      response => dispatch(extAdminInvitationDeclineSuccess(response.data)),
      ex => {
        dispatch(extAdminInvitationDeclineFailure(ex));
        return Promise.reject(ex);
      },
    );
};

export const setInvitationId = (invitationId = "") => (dispatch) => {
  dispatch({
    type: EXT_ADMIN_SET_INVITATION_ID,
    invitationId
  });
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [EXT_ADMIN_ME_GET]: (state) => ({...state, isLoading: true}),
  [EXT_ADMIN_ME_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        extAdminMe: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [EXT_ADMIN_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [EXT_ADMIN_INVITATION_ACCEPT_POST]: (state) => ({...state, isActionLoading: true}),
  [EXT_ADMIN_INVITATION_ACCEPT_POST_SUCCESS]: (state) => ({...state, isActionLoading: false}),
  [EXT_ADMIN_INVITATION_ACCEPT_POST_FAILURE]: (state) => ({...state, isActionLoading: false}),

  [EXT_ADMIN_INVITATION_DECLINE_DELETE]: (state) => ({...state, isActionLoading: true}),
  [EXT_ADMIN_INVITATION_DECLINE_DELETE_SUCCESS]: (state) => ({...state, isActionLoading: false}),
  [EXT_ADMIN_INVITATION_DECLINE_DELETE_FAILURE]: (state) => ({...state, isActionLoading: false}),

  [EXT_ADMIN_SET_INVITATION_ID]: (state, action) => {
    if (typeof action.invitationId === 'string') {
      return {
        ...state,
        invitationId: action.invitationId
      };
    }
    return {
      ...state,
      invitationId: ''
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const extAdminReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default extAdminReducer;
