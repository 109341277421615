import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PersonalInfo from "../components/personal-info-view";
import { hasIllegalCharacters, isValidUrl } from "core/input-validations";
import {
  timeZoneOptions,
  isSupportedTimezone,
} from "../assets/timezone-options";
import { localeOptions, isFauxLocale } from "../assets/locale-options";
import { uiShowToastrMessage } from "modules/ui-module";
import appconfig from "config/appconfig";
import {
  meData,
  meIsLoading,
  meRequestPatch,
  meIsConflicted,
  meId
} from "modules/me-module";
import { setHandledErrorCodes } from "lib/error-message-handling";
import castleIOWrapper from "lib/castleio-tracking";
import { isEqual } from "lodash";
import environment from "lib/environment";
import { stopEvent } from "lib/utility-functions";

export const PersonalInfoContainer = () => {
  const { personData, locales, timeZones, location, isLoading, isViewOnly, avatarImageUrl } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();
  const [allValues, setAllValues] = useState({
    personData: { ...personData },
    location: location,
    errors: {},
    modalIsOpen: false,
  });
  const LOCATION_FIELD = "location";
  let origState = {
    personData: { ...personData },
    location: location,
    errors: {},
    modalIsOpen: false
  };
  useEffect(() => {
    if (castleIOWrapper && castleIOWrapper.identifyUserAndTrackPageInCastle) {
      castleIOWrapper.identifyUserAndTrackPageInCastle(
        personData.id,
        personData.userName,
        "Profile Management Portal - Personal Info"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentsHaveChanged = () => {
    if (
      origState &&
      allValues &&
      origState.personData &&
      allValues.personData &&
      typeof origState.location !== "undefined" &&
      typeof allValues.location !== "undefined"
    ) {
      return !(
        isEqual(origState.personData, allValues.personData) &&
        isEqual(origState.location, allValues.location)
      );
    }
    return false;
  };

  const validateUserInput = (field, value, onBlurCheck) => {
    let errorText = "";
    const errors = { ...allValues.errors };
    if (errors[field]) {
      delete errors[field];
    }

    if (field === "displayName" && value.length > 128) {
      errorText = "personal-info.name-exceeds-max-length";
    }

    if (field === "title" && value.length > 250) {
      errorText = "personal-info.title-exceeds-max-length";
    }

    if (
      field === "profileUrl" &&
      (value.length > 250 || (onBlurCheck && value && !isValidUrl(value)))
    ) {
      errorText = "personal-info.profileUrl-malformed-url";
    }

    if (field === "location" && value.length > 250) {
      errorText = "personal-info.location-exceeds-max-length";
    }

    if (!errorText.length && hasIllegalCharacters(value)) {
      errorText = "shared.illegal-character-entry";
    }

    if (
      field === "displayName" &&
      !errorText.length &&
      onBlurCheck &&
      !value.length
    ) {
      errorText = "personal-info.validation-error-field-name";
    }

    if (errorText.length) {
      errors[field] = errorText;
    }

    setAllValues((prevState) => ({
      ...prevState,
      errors: { ...errors },
    }));

    return !errorText.length;
  };

  const validateNameEntry = (e) => {
    const field = e.target.id.split("-")[0];
    const value = e.target.value;
    return validateUserInput(field, value, true);
  };

  const enableSubmit = () => {
    return (
      Object.keys(allValues.errors).length === 0 &&
      contentsHaveChanged() &&
      !isLoading
    );
  };

  const handleLocationEntry = (field, value) => {
    if (validateUserInput(field, value, false)) {
      setAllValues((prevState) => ({
        ...prevState,
        location: value,
      }));
    }
    return true;
  };

  const updatePersonalInfoState = (e) => {
    const personData = { ...allValues.personData };
    const field = e.target.id.split("-")[0];
    const value = e.target.value;
    // handle the Location field entry in a dedicated function.
    if (field === LOCATION_FIELD) {
      return handleLocationEntry(field, value);
    }

    personData[field] = value;
    if (validateUserInput(field, value, false)) {
      setAllValues((prevState) => ({
        ...prevState,
        personData: { ...personData },
      }));
    }
    return true;
  };

  const updateLocaleState = (e) => {
    const personData = { ...allValues.personData };
    const val = e.target.value;
    // The locale (language) selector control returns the newly selected value and label in an object, not the control itself
    // so we must handle the returned values accordingly.
    if (val) {
      personData["locale"] = val;
      personData["selectedLocale"] = val;

      setAllValues((prevState) => ({
        ...prevState,
        personData: { ...personData },
      }));
    }
    return true;
  };

  const updateTimeZoneState = (e) => {
    const personData = { ...allValues.personData };
    const val = e.target.value;
    // The time zone selector control returns the newly selected value and label in an object, not the control itself
    // so we must handle the returned values accordingly.
    if (val) {
      personData["timezone"] = val;

      setAllValues((prevState) => ({
        ...prevState,
        personData: { ...personData },
      }));
    }
    return true;
  };

  const openModal = (e) => {
    stopEvent(e);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: true,
    }));
  };

  const closeModal = (e) => {
    stopEvent(e);
    setAllValues(origState);
  };

  const handleAjaxResult = (success) => {
    const toastrType = success ? "success" : "error";
    const toastrMessageId = success
      ? "personal-info.data-save-successful"
      : "personal-info.data-save-failed";
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId));
  };

  const onCancel = (e) => {
    stopEvent(e);
    closeModal();
  };

  const onClickSave = (e) => {
    stopEvent(e);
    const dataToSave = {};
    if (allValues.personData.displayName) {
      dataToSave.displayName = allValues.personData.displayName;
    }
    if (
      allValues.personData.locale &&
      !isFauxLocale(allValues.personData.selectedLocale)
    ) {
      dataToSave.locale = allValues.personData.locale;
    }
    //extracting the value from available timezones by comparing it with selected timezone label
    // const selectedTimeZone = timeZones.find(time => time.label === allValues.personData.timezone).value;
    if (
      allValues.personData.timezone &&
      isSupportedTimezone(
        allValues.personData.locale,
        allValues.personData.timezone
      )
    ) {
      dataToSave.timezone = allValues.personData.timezone;
    }

    setHandledErrorCodes([
      "user.username.empty",
      "user.username.maxlength",
      "user.username.invalid",
      "user.locale.malformed",
      "user.locale.language",
      "user.locale.country",
      "user.timezone.notfound",
      "user.givenname.maxlength",
      "user.givenname.invalid",
      "user.familyname.maxlength",
      "user.familyname.invalid",
    ]);

    // only save the optional field values (title, profileUrl, location) if they have changed
    if (origState && origState.personData) {
      if (origState.personData.title !== allValues.personData.title) {
        dataToSave.title = allValues.personData.title || "";
      }
      if (origState.personData.profileUrl !== allValues.personData.profileUrl) {
        dataToSave.profileUrl = allValues.personData.profileUrl || "";
      }
      if (origState.location !== allValues.location) {
        dataToSave[appconfig.scimSchemas.getGo] = {
          location: allValues.location,
        };
      }
    }
    // origState = null;
    return dispatch(meRequestPatch(dataToSave)).then(
      () => {
        handleAjaxResult(true);
        origState = {
          personData: { ...personData },
          location: location,
          errors: {},
        };
        setAllValues((prevState) => ({
          ...prevState,
          modalIsOpen: false
        }));
      },
      () => {
        handleAjaxResult(false);
      }
    );
  };

  return (
    <PersonalInfo
      personData={allValues.personData}
      openModal={openModal}
      closeModal={closeModal}
      modalIsOpen={allValues.modalIsOpen}
      allLocales={locales}
      allTimeZones={timeZones}
      errors={allValues.errors}
      onChange={updatePersonalInfoState}
      onBlur={validateNameEntry}
      onLocaleChange={updateLocaleState}
      onTimeZoneChange={updateTimeZoneState}
      onSave={onClickSave}
      enableCTAs={enableSubmit()}
      onCancel={onCancel}
      isLoading={isLoading}
      locationValue={allValues.location}
      isViewOnly={isViewOnly}
      avatarImageUrl={avatarImageUrl}
    />
  );
};
export const getValuesFromStore = (state) => {
  const personData = {};
  const mePersonData = meData(state);
  personData.id = mePersonData.id || "";
  personData.userName = mePersonData.userName || "";
  personData.displayName = mePersonData.displayName || "";
  personData.locale = mePersonData.locale || "";
  personData.selectedLocale = mePersonData.selectedLocale || "";
  personData.title = mePersonData.title || "";
  personData.location = mePersonData.location || "";
  personData.profileUrl = mePersonData.profileUrl || "";
  personData.timezone = mePersonData.timezone || "";

  const emails = mePersonData.emails || [];
  const primaryEmail = emails.find((email) => email.type === "primary") || "";
  personData.email = primaryEmail.value;

  const locales = localeOptions(
    mePersonData.locale,
    mePersonData.selectedLocale
  );
  const timeZones = timeZoneOptions(mePersonData.locale, mePersonData.timezone);
  const avatarBaseImageUrl = environment.get().avatar_image_url;
  let avatarImageUrl = `${avatarBaseImageUrl}${meId(state)}_medium.jpg`;

  const location = mePersonData[appconfig.scimSchemas.getGo] &&
    typeof mePersonData[appconfig.scimSchemas.getGo].location !== "undefined"
    ? mePersonData[appconfig.scimSchemas.getGo].location
    : "";
  return {
    personData,
    locales,
    timeZones,
    location,
    isLoading: meIsLoading(state),
    isViewOnly: meIsConflicted(state),
    avatarImageUrl
  };
};

export default PersonalInfoContainer;
