import React from "react";
import PropTypes from "prop-types";
import PasswordStrengthIndicator from "./password-strength-indicator";
import { FormattedHTMLMessage, defineMessages } from "react-intl";
import { InputField, Typography, Link, IconButton } from '@getgo/chameleon-web-react-wrapper';
import { VisibilityOnOutlinedIcon } from '@getgo/chameleon-icons/react';

const t = defineMessages({
  "reset-password-instruction-label": {
    id: "reset-password-instruction-label",
    defaultMessage: "Keep your account safe with a strong password. Your password needs a strength of <span class='coloredTextForStrength'>good</span> or <span class='coloredTextForStrength'>strong</span>.",
    description: "Instruction label for password strength",
  },
});

export const PasswordInput = ({
  name,
  label,
  onChange,
  onBlur,
  onFocus,
  inputType = "password",
  maxLength,
  showForgotPassword = {},
  placeholder,
  value,
  error = "",
  passwordStrengthScore,
  showWeakPasswordWarning = false,
  onViewAsTextClick,
  onViewAsTextByKeyboard
}) => (
  <div id="passwordStrengthIndicatorDiv">
    {name === "currentPassword" && (
      <Typography tag="p" variant="body-medium">
        <FormattedHTMLMessage {...t["reset-password-instruction-label"]} />
      </Typography>
    )}
    <div style={{ margin: "8px auto" }}>
      <InputField
        labelId={name}
        id={`${name}-input`}
        type={inputType}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={onFocus}
        trailingIcon={<IconButton name={name} id={`${name}_switch`} role="button" onClick={onViewAsTextClick}><VisibilityOnOutlinedIcon size="28px" color="type-color-secondary" /></IconButton>}
        onBlur={onBlur}
        fullwidth
        title={label}
        error={!!error}
        helperText={error}
        disabled={
          name === "confirmPassword" &&
            (!passwordStrengthScore || passwordStrengthScore <= 2)
            ? true
            : false
        }
      >
        {label}
      </InputField>

      {name === "newPassword" && (
        <PasswordStrengthIndicator
          passwordStrengthScore={passwordStrengthScore}
          showWeakPasswordWarning={showWeakPasswordWarning}
        />
      )}

      {showForgotPassword.label && (
        <Link href={showForgotPassword.href}>
          <Typography tag="p" variant="caption-medium" color="type-color-brand-default">
            {showForgotPassword.label}
          </Typography>
        </Link>
      )}
    </div>
  </div>
);
PasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  showForgotPassword: PropTypes.object,
  placeholder: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.string,
  error: PropTypes.string,
  passwordStrengthScore: PropTypes.number,
  showWeakPasswordWarning: PropTypes.bool,
  onViewAsTextClick: PropTypes.func,
  onViewAsTextByKeyboard: PropTypes.func
};

export default PasswordInput;
