import React from 'react';
import PropTypes from 'prop-types';
import prodSpecs from 'lib/nav-product-specs';
import classes from './product-view.scss';
import {
  defineMessages,
  FormattedHTMLMessage,
} from 'react-intl';


const t = defineMessages({
  'product-view.bundled-with-g2m': {
    id: 'product-view.bundled-with-g2m',
    defaultMessage: '{product}<br /><span class="regularText">with GoToMeeting</span>',
    description: 'Adds the G2M bundle string to the product name',
  },
  'product-view.g2c-with-tagline': {
    id: 'product-view.g2c-with-tagline',
    defaultMessage: '{product}<br /><span class="regularText">with meetings and voice</span>',
    description: 'Adds a tagline string to the GoToConnect product name',
  }
});

const ProductIconAndLabel = (props) => {
  const prod = prodSpecs[props.product.id];
  if (prod) {
    const prodName = prod.product;
    let prodClass = prod.class;
    let prodNameDisplay = props.product.description;
    if (prodNameDisplay.toLowerCase().indexOf('gotowebinar') > -1 || prodNameDisplay.toLowerCase().indexOf('gototraining') > -1) {
      prodNameDisplay = <FormattedHTMLMessage {...t['product-view.bundled-with-g2m']} values={{ product: prodNameDisplay }} />;
    } else if (prodNameDisplay.toLowerCase().indexOf('gotoconnect') > -1) {
      prodNameDisplay = <FormattedHTMLMessage {...t['product-view.g2c-with-tagline']} values={{ product: prodNameDisplay }} />;
    }
    return (
      <div className={classes['productName']} id={`${prodName}_row`}>
        {prodClass}
        <div className={classes['description']}>
          {prodNameDisplay}
        </div>
      </div>
    );
  }
  return null;
};

ProductIconAndLabel.propTypes = {
  product: PropTypes.object.isRequired,
};

export default ProductIconAndLabel;
