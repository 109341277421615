import { getTimeZones as getTzDbTimezones } from '@vvo/tzdb';
import timezones from './tzList';
// This file contains the Time zone selector values

// Time zone values
const timeZones = timezones.tzList;

// We initialize it once
const tzdbTimezones = getTzDbTimezones();

// e.g. America/Denver => (GMT-06:00) Mountain Time
const fullTimezoneName = (tz) => {
  const tzItem = tzdbTimezones.find(({ name }) => name === tz);
  // if the timezone is not recognized, we return what we've received
  if (!tzItem) {
    return tz;
  }

  const { alternativeName, countryName, currentTimeFormat } = tzItem;
  const [gmt] = currentTimeFormat.split(' ');

  return [`(GMT${gmt})`, alternativeName || countryName].join(' ');
};

const supportedTimezones = (locale) => (timeZones[locale] || timeZones.en_US).slice();

export const isSupportedTimezone =  (locale, selectedTz) => supportedTimezones(locale).some(tz => tz.value === selectedTz);

export const timeZoneOptions = (locale, selectedTz) => {
  const tzArray = supportedTimezones(locale);
  if (selectedTz) {
    const validTz = isSupportedTimezone(locale, selectedTz);
    if (!validTz) {
      tzArray.unshift({value: selectedTz, label: fullTimezoneName(selectedTz)});
    }
  }
  return tzArray;
};
