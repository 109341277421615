import React from 'react';
import appconfig from 'config/appconfig';
import {
  GotoAssistIcon,
  SeeItIcon,
  GotoIcon,
  GotoBlackMonochromeFaviconIcon,
  OpenVoiceIcon,
  GtcBlackVerticalIcon,
  GtrBlackVerticalIcon,
  GotoBlackIcon
} from '@getgo/chameleon-icons/react';
// Product settings and name references
const navProductSpecs = {
  g2ars: { class: <GotoAssistIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2ars, productUrlRef: 'GoToAssist_url' },
  g2asd: { class: <GotoAssistIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2asd, productUrlRef: 'ServiceDesk_url' },
  g2aseeit: { class: <SeeItIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2aseeit, productUrlRef: 'Seeit_url' },
  g2m: { class: <GotoBlackMonochromeFaviconIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2m, productUrlRef: 'GoToMeeting_url' },
  g2mApp: { class: <GotoBlackMonochromeFaviconIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2m, productUrlRef: 'GoToMeetingApp_url' },
  g2mfree: { class: <GotoBlackMonochromeFaviconIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2mfree, productUrlRef: 'GoToMeeting_url' },
  g2mfreeApp: { class: <GotoBlackMonochromeFaviconIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2mfree, productUrlRef: 'GoToMeetingApp_url' },
  g2t: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2t, productUrlRef: 'GoToTraining_url' },
  g2w: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2w, productUrlRef: 'GoToWebinar_url' },
  g2p: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.g2p, productUrlRef: 'GoToMyPc_url' },
  openvoice: {class: <OpenVoiceIcon color="type-color-default" />, product: appconfig.logMeInProducts.openvoice, productUrlRef: 'OpenVoice_url' },
  jive: { class: <GotoIcon color="type-color-default" />, product: appconfig.logMeInProducts.jive, productUrlRef: 'Jive_url' },
  hvoice: { class: <GotoBlackIcon color="type-color-default" />, product: appconfig.logMeInProducts.hvoice, productUrlRef: 'Jive_url' },
  gotoconnect: { class: <GtcBlackVerticalIcon color="type-color-default" />, product: appconfig.logMeInProducts.gotoconnect, productUrlRef: 'GoToConnect_url' },
  bold360: { class: <GotoBlackIcon color="type-color-default" />, product: appconfig.logMeInProducts.bold360, productUrlRef: 'Bold360_url' },
  gotoresolve: { class: <GtrBlackVerticalIcon color="type-color-default" />, product: appconfig.logMeInProducts.gotoresolve, productUrlRef: 'GoToResolve_url' },
  grasshopper: { class: <GotoBlackIcon color="type-color-default" />, product: appconfig.logMeInProducts.grasshopper, productUrlRef: 'Grasshopper_url' },
};
export default navProductSpecs;
