import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import moment from "moment";
import DevicesEditorView from "../components/devices-editor-view";
import { meData, meLocale } from "modules/me-module";
import {
  devicesList,
  devicesRequestGet,
  getMapMarkersData,
  getCurrentDevice,
  approveDeviceRequestPut,
  reportDeviceRequestPut,
} from "modules/devices-module";
import { uiShowToastrMessage } from "modules/ui-module";
import RedMarker from "static/images/svg/red-marker.svg";
import BlueMarker from "static/images/svg/blue-marker.svg";
import castleIOWrapper from "lib/castleio-tracking";
import * as Castle from "@castleio/castle-js";
import { useSelector } from "react-redux";
import { stopEvent } from "lib/utility-functions";


export const DevicesEditorContainer = ({ openDevicesDialogOnLoad }) => {
  let { devicesListAll, mapMarkerData, currentDevice, lang, personData } = useSelector(getValuesFromStore);
  const dispatch = useDispatch();
  let [allValues, setAllValues] = useState({
    errors: {},
    isDirty: false,
    modalIsOpen: false,
    selectedDevice: {},
    clickedDeviceId: "",
    reviewDevicesContentClass: "",
    trustedDeviceControlsClass: "hidden",
    unrecognizedDeviceControlsClass: "hidden",
    devicesModalContentClass: "",
    secureYourAccountModalContentClass: "hidden",
  });
  const [popoverInfo, setPopoverInfo] = useState({
    popoverIsOpen: false,
    deviceId: ""
  });

  const openModal = (e) => {
    stopEvent(e);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: true,
    }));
  };

  const closeModal = (e) => {
    stopEvent(e);
    setAllValues((prevState) => ({
      ...prevState,
      modalIsOpen: false,
    }));
  };

  useEffect(() => {
    if (castleIOWrapper && castleIOWrapper.identifyUserAndTrackPageInCastle) {
      castleIOWrapper.identifyUserAndTrackPageInCastle(
        personData.id,
        personData.userName,
        "Profile Management Portal - Sign In & Security"
      );
    }
    async function fn() {
      return Castle.createRequestToken().then(
        (requestToken) => {
          return dispatch(devicesRequestGet(requestToken)).then(
            () => {
              if (openDevicesDialogOnLoad && !allValues.modalIsOpen) {
                openModal();
              }
              return true;
            },
            () => {
              return false;
            }
          );
        },
        () => {
          return false;
        }
      );
    }
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const displayToastrMessage = (toastrType, toastrMessageId) => {
    dispatch(uiShowToastrMessage(toastrType, toastrMessageId));
  };

  const getDevice = (deviceId) =>
    Object.values(devicesListAll).find((device) => device.token === deviceId);

  const getDevicesList = () => {
    const currIndex = devicesListAll.findIndex(
      (device) => device.token === currentDevice.token
    );
    devicesListAll.push(...devicesListAll.splice(0, currIndex));
    return devicesListAll;
  };

  const getCurrentDeviceParams = (device) => {
    const deviceParams = {
      token: "",
      os: "",
      deviceType: "",
      browser: "",
      city: "",
      region: "",
      country: "",
      locationResourceId: "devices-editor.unknown-location",
    };
    if (device.token) {
      deviceParams.token = device.token;
      deviceParams.os =
        device.context &&
          device.context.user_agent &&
          device.context.user_agent.os
          ? device.context.user_agent.os
          : "";
      deviceParams.deviceType = device.context.type || "";
      deviceParams.browser =
        device.context &&
          device.context.user_agent &&
          device.context.user_agent.browser
          ? device.context.user_agent.browser
          : "";
      deviceParams.city = device.city || "";
      deviceParams.region = device.region || "";
      deviceParams.country = device.country || "";
      deviceParams.locationResourceId = device.locationResourceId || "";
    }
    return deviceParams;
  };

  const refocusSelectedDevice = () => {
    const token = allValues.selectedDevice.token;
    if (token) {
      setTimeout(() => {
        const div = document.getElementById(token);
        if (div) {
          const aArray = div.getElementsByTagName("a");
          if (aArray.length) {
            aArray[0].focus();
          } else {
            div.focus();
          }
        }
      }, 100);
    }
  };

  const onDeviceNameClick = (deviceId) => (e) => {
    stopEvent(e);
    const clickedDeviceId = deviceId;
    setAllValues((prevState) => ({
      ...prevState,
      clickedDeviceId,
    }));
  };

  const togglePopover = (deviceId) => (e) => {
    const popOverNewtState = !popoverInfo.popoverIsOpen;

    setPopoverInfo({
      popoverIsOpen: popOverNewtState,
      deviceId
    });
    return true;
  };

  const onTrustDeviceMenuClick = (deviceId) => (e) => {
    stopEvent(e);
    setPopoverInfo({
      popoverIsOpen: false,
      deviceId: ""
    });
    if (deviceId) {
      const trustedDeviceControlsClass = "";
      const reviewDevicesContentClass = "hidden";
      const selectedDevice = getDevice(deviceId);

      setAllValues((prevState) => ({
        ...prevState,
        selectedDevice,
        trustedDeviceControlsClass,
        reviewDevicesContentClass,
      }));
    }
    return false;
  };

  const onTrustDeviceButtonClick = (e) => {
    let castleClientId;
    stopEvent(e);
    if (allValues.selectedDevice.token) {
      return dispatch(
        approveDeviceRequestPut(
          encodeURIComponent(allValues.selectedDevice.token)
        )
      )
        .then(
          () => {
            displayToastrMessage(
              "success",
              "devices-editor.trusted-device-save-successful"
            );

            Castle.createRequestToken().then((requestToken) => {
              castleClientId = requestToken;
            });
            if (castleClientId) {
              dispatch(devicesRequestGet(castleClientId));
            }
            refocusSelectedDevice();
          },
          () => {
            displayToastrMessage(
              "error",
              "devices-editor.trusted-device-save-failed"
            );
          }
        )
        .then(() => {
          setAllValues((prevState) => ({
            ...prevState,
            selectedDevice: {},
          }));
          closeTrustDeviceModal();
        });
    }
    return true;
  };

  const closeTrustDeviceModal = (e) => {
    const trustedDeviceControlsClass = "hidden";
    const reviewDevicesContentClass = "";

    setAllValues((prevState) => ({
      ...prevState,
      selectedDevice: {},
      trustedDeviceControlsClass,
      reviewDevicesContentClass,
    }));
    refocusSelectedDevice();
  };

  const closeUnrecognizedDeviceModal = (e) => {
    stopEvent(e);
    const unrecognizedDeviceControlsClass = "hidden";
    const reviewDevicesContentClass = "";

    setAllValues((prevState) => ({
      ...prevState,
      selectedDevice: {},
      unrecognizedDeviceControlsClass,
      reviewDevicesContentClass,
    }));
    refocusSelectedDevice();
  };

  const onUnrecognizedDeviceMenuClick = (deviceId) => (e) => {
    if (deviceId) {
      const unrecognizedDeviceControlsClass = "";
      const reviewDevicesContentClass = "hidden";
      const selectedDevice = getDevice(deviceId);
      setPopoverInfo({
        popoverIsOpen: false,
        deviceId: ""
      });
      setAllValues((prevState) => ({
        ...prevState,
        selectedDevice,
        unrecognizedDeviceControlsClass,
        reviewDevicesContentClass,
      }));
    }
    return false;
  };

  const onReportDeviceButtonClick = (e) => {
    if (allValues.selectedDevice.token) {
      return dispatch(
        reportDeviceRequestPut(
          encodeURIComponent(allValues.selectedDevice.token)
        )
      ).then(
        () => {
          displayToastrMessage(
            "success",
            "devices-editor.report-device-save-successful"
          );
          const devicesModalContentClass = "hidden";
          const secureYourAccountModalContentClass = "";
          const unrecognizedDeviceControlsClass = "hidden";

          setAllValues((prevState) => ({
            ...prevState,
            devicesModalContentClass,
            secureYourAccountModalContentClass,
            unrecognizedDeviceControlsClass
          }));
          refocusSelectedDevice();
        },
        () => {
          displayToastrMessage(
            "error",
            "devices-editor.report-device-save-failed"
          );
        }
      );
    }
    return true;
  };

  const markerClick = (props, marker, e) => {
    if (e && e.xa) {
      e.xa.stopPropagation();
      e.xa.preventDefault();
    }
    const clickedDeviceId = marker.name;

    setAllValues((prevState) => ({
      ...prevState,
      clickedDeviceId,
    }));
    const container = document.getElementById("deviceList");
    const deviceRow = document.getElementById(clickedDeviceId);
    if (container && deviceRow) {
      container.scrollTop = 0; // move to 0 pixels scroll position, then
      container.scrollTop = deviceRow.offsetTop - container.offsetTop; // calculate new scrollTop value
    }
  };

  return (
    <DevicesEditorView
      onDeviceEditLinkClick={openModal}
      modalIsOpen={allValues.modalIsOpen}
      closeModal={closeModal}
      closeTrustDeviceModal={closeTrustDeviceModal}
      closeUnrecognizedDeviceModal={closeUnrecognizedDeviceModal}
      errors={allValues.errors}
      devicesListDisplay={getDevicesList()}
      mapMarkerData={mapMarkerData}
      markerClick={markerClick}
      clickedDeviceId={allValues.clickedDeviceId}
      currentDeviceParams={getCurrentDeviceParams(currentDevice)}
      onTrustDeviceMenuClick={onTrustDeviceMenuClick}
      onTrustDeviceButtonClick={onTrustDeviceButtonClick}
      onUnrecognizedDeviceMenuClick={onUnrecognizedDeviceMenuClick}
      onReportDeviceButtonClick={onReportDeviceButtonClick}
      onDeviceNameClick={onDeviceNameClick}
      trustedDeviceControlsClass={allValues.trustedDeviceControlsClass}
      reviewDevicesContentClass={allValues.reviewDevicesContentClass}
      unrecognizedDeviceControlsClass={allValues.unrecognizedDeviceControlsClass}
      devicesModalContentClass={allValues.devicesModalContentClass}
      secureYourAccountModalContentClass={allValues.secureYourAccountModalContentClass}
      locale={lang}
      selectedDevice={allValues.selectedDevice}
      userEmail={personData.userName || ""}
      selectedDeviceTitle={allValues.selectedDevice.title || ""}
      popoverIsOpen={popoverInfo.popoverIsOpen || false}
      togglePopoverView={togglePopover}
      popoverOpendDeviceId={popoverInfo.deviceId || ""}
    />
  );
};

DevicesEditorContainer.propTypes = {
  openDevicesDialogOnLoad: PropTypes.bool,
};

const getDurationSinceLastLogin = (device) => {
  const retVal = { count: 0, resourceRef: "device-detail.duration-unknown" };
  if (device.last_seen_at && device.last_seen_at.toLowerCase() !== "unknown") {
    const now = moment(new Date());
    const end = moment(device.last_seen_at);
    const timeSinceLastLogin = moment.duration(now.diff(end));
    const asMonths = Math.floor(timeSinceLastLogin.asMonths());
    const asDays = Math.floor(timeSinceLastLogin.asDays());
    const asHours = Math.floor(timeSinceLastLogin.asHours());
    const asMinutes = Math.floor(timeSinceLastLogin.asMinutes());

    if (asMonths > 0) {
      retVal.count = asMonths;
      retVal.resourceRef =
        asMonths > 1
          ? "device-detail.duration-months"
          : "device-detail.duration-month";
    } else if (asDays > 0) {
      retVal.count = asDays;
      retVal.resourceRef =
        asDays > 1
          ? "device-detail.duration-days"
          : "device-detail.duration-day";
    } else if (asHours > 0) {
      retVal.count = asHours;
      retVal.resourceRef =
        asHours > 1
          ? "device-detail.duration-hours"
          : "device-detail.duration-hour";
    } else if (asMinutes > 0) {
      retVal.count = asMinutes;
      retVal.resourceRef =
        asMinutes > 1
          ? "device-detail.duration-minutes"
          : "device-detail.duration-minute";
    } else {
      retVal.count = 0;
      retVal.resourceRef = "device-detail.duration-unknown";
    }
  }
  return retVal;
};

const getTimeOfLastLogin = (locale, device) => {
  let retVal = "";
  if (device.last_seen_at && device.last_seen_at.toLowerCase() !== "unknown") {
    const lang = locale.length ? locale.split("_")[0] : "en";
    retVal = moment(device.last_seen_at).locale(lang).format("LLLL");
  }
  return retVal;
};

const getApprovedDate = (locale, device) => {
  if (device.approved_at && device.approved_at.toLowerCase() !== "unknown") {
    const lang = locale.length ? locale.split("_")[0] : "en";
    return moment(device.approved_at).locale(lang).format("ll");
  }
  return "";
};

const getLocationResourceId = (device) => {
  let retVal = "";
  if (
    !device.context.location ||
    ((!device.context.location.country ||
      device.context.location.country.toLowerCase() === "unknown") &&
      (!device.context.location.region ||
        device.context.location.region.toLowerCase() === "unknown") &&
      (!device.context.location.city ||
        device.context.location.city.toLowerCase() === "unknown"))
  ) {
    retVal = "devices-editor.unknown-location";
  } else if (
    !device.context.location.region ||
    device.context.location.region.toLowerCase() === "unknown"
  ) {
    retVal = "devices-editor.location-no-region";
  } else {
    retVal = "devices-editor.location";
  }
  return retVal;
};
export const getValuesFromStore = (state) => {
  /* eslint no-param-reassign: ["error", { "props": false }] */
  const lang = meLocale(state);
  const markerData = getMapMarkersData(state);
  markerData.forEach((marker) => {
    switch (marker.trustLevel) {
      case 1:
        marker.iconRef = RedMarker;
        break;
      case 3:
        marker.iconRef = BlueMarker;
        break;
      default:
        marker.iconRef = BlueMarker;
    }
    return marker;
  });

  const devices = devicesList(state);
  devices.forEach((device) => {
    device.os =
      device.context.user_agent && device.context.user_agent.os
        ? device.context.user_agent.os
        : "";
    device.deviceType = device.context.type || "";
    device.browser =
      device.context.user_agent && device.context.user_agent.browser
        ? device.context.user_agent.browser
        : "";
    device.city =
      device.context.location && device.context.location.city
        ? device.context.location.city
        : "";
    device.region =
      device.context.location && device.context.location.region
        ? device.context.location.region
        : "";
    device.country =
      device.context.location && device.context.location.country
        ? device.context.location.country
        : "";
    device.timeSinceLastLogin = getDurationSinceLastLogin(device);
    device.timeOfLastLogin = getTimeOfLastLogin(lang, device);
    device.locationResourceId = getLocationResourceId(device);
    device.trustedDate = getApprovedDate(lang, device);
    device.ip = device.context && device.context.ip ? device.context.ip : "";
  });
  return {
    devicesListAll: devices,
    mapMarkerData: markerData,
    currentDevice: getCurrentDevice(state),
    lang,
    personData: meData(state),
  };
};
export default DevicesEditorContainer;
