import appconfig from "config/appconfig";

// ------------------------------------
// Constants
// ------------------------------------

// Authentication Alerts
export const UI_ALERT_AUTHENTICATION_WARNING_TOGGLE =
  "UI_ALERT_AUTHENTICATION_WARNING_TOGGLE";
export const UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE =
  "UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE";

export const UI_MESSAGE_BANNER_TOGGLE = "UI_MESSAGE_BANNER_TOGGLE";
export const UI_SHOW_MESSAGE_BANNER = "UI_SHOW_MESSAGE_BANNER";

export const UI_TOASTR_MESSAGE_DISPLAY = "UI_TOASTR_MESSAGE_DISPLAY";
export const UI_TOASTR_MESSAGE_REMOVE = "UI_TOASTR_MESSAGE_REMOVE";

export const UI_CONFIRM_DIALOG_DISPLAY = "UI_CONFIRM_DIALOG_DISPLAY";
export const UI_CONFIRM_DIALOG_REMOVE = "UI_CONFIRM_DIALOG_REMOVE";

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  authentication: {
    warning: false,
    expired: false,
  },
  bannerMessage: {
    messageId: "",
    params: {},
    show: false,
  },
  toastrMessage: {
    type: "",
    messageId: "",
  },
  confirmDialog: {
    textResourceId: "",
    yesCallbackFn: null,
    noCallbackFn: null,
    useCountDownTimer: false,
    dialogParams: {},
    variant: ""
  },
};

// ------------------------------------
// Selectors
// ------------------------------------
// Tree Object selectors
export const uiTree = (state) => state.ui || {};
export const uiAuthentication = (state) => uiTree(state).authentication || {};

// toastr message selector
export const uiToastrMessage = (state) => uiTree(state).toastrMessage || {};

// confirm dialog
export const uiConfirmDialog = (state) => uiTree(state).confirmDialog || {};

// authentication selectors
export const uiAlertAuthenticationWarningVisible = (state) =>
  uiAuthentication(state).warning;
export const uiAlertAuthenticationWarningTime = (state) =>
  uiAuthentication(state).warningTime;
export const uiAlertAuthenticationExpiredVisible = (state) =>
  uiAuthentication(state).expired;

// message banner selectors
export const uiMessageBannerVisible = (state) =>
  uiTree(state).bannerMessage.show;
export const uiMessageBannerId = (state) =>
  uiTree(state).bannerMessage.messageId;
export const uiMessageBannerParams = (state) =>
  uiTree(state).bannerMessage.params;

// ------------------------------------
// Actions
// ------------------------------------

export const uiAlertAuthenticationWarningToggle = (warningTime) => ({
  type: UI_ALERT_AUTHENTICATION_WARNING_TOGGLE,
  warningTime,
});

export const uiAlertAuthenticationExpiredToggle = () => ({
  type: UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE,
});

export const uiShowMessageBannerToggle = () => ({
  type: UI_MESSAGE_BANNER_TOGGLE,
});

export const uiShowMessageBanner =
  (messageId, params = {}) =>
    (dispatch) => {
      return dispatch({
        type: UI_SHOW_MESSAGE_BANNER,
        messageId,
        params,
      });
    };

export const uiShowToastrMessage = (toastrType, toastrMessageId, toastrParams = {}, toastrMessageDescription = "") =>
  (dispatch) => {
    setTimeout(() => dispatch({ type: UI_TOASTR_MESSAGE_REMOVE }), 6000);
    return dispatch({
      type: UI_TOASTR_MESSAGE_DISPLAY,
      toastrType,
      toastrMessageId,
      toastrParams,
      toastrMessageDescription
    });
  };

export const uiShowConfirmDialog =
  (
    textResourceId,
    yesCallbackFn,
    noCallbackFn,
    useCountDownTimer = false,
    dialogParams = {},
    buttonVariant,
  ) =>
    (dispatch) => {
      return dispatch({
        type: UI_CONFIRM_DIALOG_DISPLAY,
        textResourceId,
        yesCallbackFn,
        noCallbackFn,
        useCountDownTimer,
        dialogParams,
        buttonVariant
      });
    };

export const uiRemoveConfirmDialog = () => (dispatch) => {
  return dispatch({
    type: UI_CONFIRM_DIALOG_REMOVE,
  });
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UI_ALERT_AUTHENTICATION_WARNING_TOGGLE]: (state, action) => ({
    ...state,
    authentication: {
      ...state.authentication,
      warning: !state.authentication.warning,
      warningTime: action.warningTime,
    },
  }),
  [UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE]: (state) => ({
    ...state,
    authentication: {
      ...state.authentication,
      warning: false,
      expired: !state.authentication.expired,
    },
  }),
  [UI_MESSAGE_BANNER_TOGGLE]: (state) => ({
    ...state,
    bannerMessage: {
      ...state.bannerMessage,
      messageId: "",
      params: {},
      show: !state.bannerMessage.show,
    },
  }),
  [UI_SHOW_MESSAGE_BANNER]: (state, action) => ({
    ...state,
    bannerMessage: {
      ...state.bannerMessage,
      messageId: action.messageId,
      params: action.params,
      show: true,
    },
  }),
  [UI_TOASTR_MESSAGE_DISPLAY]: (state, action) => ({
    ...state,
    toastrMessage: {
      ...state.toastrMessage,
      type: action.toastrType,
      messageId: action.toastrMessageId,
      params: action.toastrParams,
      toastrMessageDescription: action.toastrMessageDescription
    },
  }),
  [UI_TOASTR_MESSAGE_REMOVE]: (state) => ({
    ...state,
    toastrMessage: {
      ...state.toastrMessage,
      type: "",
      messageId: "",
      params: {},
    },
  }),
  [UI_CONFIRM_DIALOG_DISPLAY]: (state, action) => ({
    ...state,
    confirmDialog: {
      ...state.confirmDialog,
      textResourceId: action.textResourceId,
      yesCallbackFn: action.yesCallbackFn,
      noCallbackFn: action.noCallbackFn,
      useCountDownTimer: action.useCountDownTimer,
      dialogParams: action.dialogParams,
      variant: action.buttonVariant
    },
  }),
  [UI_CONFIRM_DIALOG_REMOVE]: (state) => ({
    ...state,
    confirmDialog: {
      ...state.confirmDialog,
      textResourceId: "",
      yesCallbackFn: null,
      noCallbackFn: null,
      useCountDownTimer: false,
      dialogParams: {},
      variant: null
    },
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
const uiReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default uiReducer;
