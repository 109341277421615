import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const extAdminUrl = { baseURL: environment.get().api.extAdminURL };
const extAdminMeEndpoint = appconfig.endpoints.extAdminMe;
const extAdminUserInvitationEndpoint = appconfig.endpoints.extAdminUserInvitation;

export const daoExtAdminMeWithInviationGet = () => api.get(`${extAdminMeEndpoint}?includeInvitation=true&consistency=eventual`, extAdminUrl);
export const daoExtAdminInvitationAccept = (params) => api.post(extAdminUserInvitationEndpoint(params), null, extAdminUrl);
export const daoExtAdminInvitationDecline = (params) => api.delete(extAdminUserInvitationEndpoint(params), extAdminUrl);
