import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  uiShowMessageBannerToggle,
  uiMessageBannerId,
  uiMessageBannerParams,
} from "modules/ui-module";
import MessageBanner from "components/message-banner-view";

export const mapStateToProps = (state) => ({
  messageId: uiMessageBannerId(state),
  params: uiMessageBannerParams(state),
});

export const mapDispatchToProps = (dispatch) => ({
  handleAlertDismiss: () => dispatch(uiShowMessageBannerToggle()),
});

export const mergeProps = (stateProps, dispatchProps) => ({
  messageId: stateProps.messageId,
  params: stateProps.params,
  handleAlertDismiss: dispatchProps.handleAlertDismiss,
});

export const MessageBannerContainer = ({
  messageId,
  params,
  handleAlertDismiss,
}) => {
  return (
    <div>
      <MessageBanner
        handleAlertDismiss={handleAlertDismiss}
        messageId={messageId}
        params={params}
      />
    </div>
  );
};
MessageBannerContainer.propTypes = {
  messageId: PropTypes.string,
  params: PropTypes.object,
  handleAlertDismiss: PropTypes.func.isRequired,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(MessageBannerContainer);
