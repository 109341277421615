import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import st from "core/shared-translations";
import EmailInputGroup from "components/email-input-group-view";
import EmailVerificationContainer from "../containers/email-verification-container";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classes from "./signin-security.scss";
import BoxedContainer from "components/boxed-container-view";
import KeyValDisplay from "components/key-val-display";
import { EditOutlinedIcon, EmailOutlinedIcon } from "@getgo/chameleon-icons/react";
import { Button, InputField } from '@getgo/chameleon-web-react-wrapper';
import ResponsiveDialog from 'components/dialog-view.js';

const t = defineMessages({
  "email-editor.email-address": {
    id: "email-editor.email-address",
    defaultMessage: "Email Address",
    description: "Title string for the email editor section",
  },
  "email-editor.primary-email": {
    id: "email-editor.primary-email",
    defaultMessage: "Primary email",
    description: "Field label for Primary email",
  },
  "email-editor.select-domain": {
    id: "email-editor.select-domain",
    defaultMessage: "Select a domain",
    description: "Title attribute for the domain dropdown list",
  },
  "email-editor.recovery-email": {
    id: "email-editor.recovery-email",
    defaultMessage: "Recovery email",
    description: "Field label for Recovery email",
  },
  "email-editor.edit-email-addresses": {
    id: "email-editor.edit-email-addresses",
    defaultMessage: "Edit Email Addresses",
    description: "Title string for the email editor modal dialog",
  },
  "email-editor.primary-email-info": {
    id: "email-editor.primary-email-info",
    defaultMessage: "You'll sign in to your account with this email address.",
    description:
      "Information string to appear below the Primary Email form field",
  },
  "email-editor.recovery-email-info": {
    id: "email-editor.recovery-email-info",
    defaultMessage:
      "We'll use this to help you reset your password if you forget it.",
    description:
      "Information string to appear below the Recovery Email form field",
  },
  "email-editor.validation-error-email": {
    id: "email-editor.validation-error-email",
    defaultMessage: "Please enter a valid email address",
    description:
      "Content of the error message when the email address field is left blank or contains invalid characters",
  },
  "email-editor.validation-error-domain-entered": {
    id: "email-editor.validation-error-domain-entered",
    defaultMessage:
      "Enter the unique portion of your email address and select your domain from the list.",
    description:
      "Content of the error message when the user is part of an organization and he/she enters the full email address without selecting a domain from the dropdown list",
  },
  "email-editor.validation-error-email-length-exceeded": {
    id: "email-editor.validation-error-email-length-exceeded",
    defaultMessage: "The email address must not exceed 128 characters",
    description:
      "Content of the error message when the email address length exceeds 128 characters",
  },
  "email-editor.saml-sso-email-info": {
    id: "email-editor.saml-sso-email-info",
    defaultMessage: "You'll sign into your account with this email address.",
    description:
      "Info string to appear below the email filed when the email address is under SAML SSO org policy",
  },
});
/* eslint-disable */
export const EmailEditor = ({
  personData,
  isEmailChangeAllowed,
  onEditLinkClick,
  modalIsOpen,
  closeModal,
  completeProcess,
  onBlur,
  onChange,
  onSave,
  enableSubmit,
  isLoading,
  errors,
  domainValue,
  domainUserName,
  recoveryEmail,
  domainOptions,
  onDomainChange,
  showEmailEditor,
  modifiedUserName,
  handlePrimaryEmailVerification,
  emailEditorClass,
  verifyEmailClass,
  forceSamlSsoLogin,
  forceSamlSsoClass,
  isViewOnly = false,
}) => {
  const intl = useIntl();
  return (
    <Fragment>
      <BoxedContainer
        title={<FormattedMessage {...t["email-editor.email-address"]} />}
        buttonIcon={<EditOutlinedIcon />}
        buttonText={<FormattedMessage {...st["shared.edit"]} />}
        onClick={onEditLinkClick}
        disabled={isViewOnly || !isEmailChangeAllowed}
        boxIcon={<EmailOutlinedIcon color="#000000" />}
      >
        <KeyValDisplay
          heading={<FormattedMessage{...t["email-editor.primary-email"]} />}
          val={personData.userName || <FormattedMessage {...st["shared.none-set"]} />}
        />
        <KeyValDisplay
          heading={<FormattedMessage{...t["email-editor.recovery-email"]} />}
          val={!!recoveryEmail ? recoveryEmail : <FormattedMessage {...st["shared.none-set"]} />}
        />

      </BoxedContainer>
      <ResponsiveDialog
        open={modalIsOpen}
        closable={true}
        onClosed={closeModal}
        title={!emailEditorClass ?
          <FormattedMessage {...t["email-editor.edit-email-addresses"]} /> :
          <FormattedMessage {...st['shared.email-verification']} />
        }
        actions={!emailEditorClass &&
          <Fragment>
            <Button
              onClick={onSave}
              disabled={!enableSubmit}
            >
              {isLoading ? (
                <FormattedMessage {...st["shared.saving"]} />
              ) : (
                <FormattedMessage {...st["shared.save"]} />
              )}
            </Button>
            <Button onClick={closeModal} variant="tertiary">
              <FormattedMessage {...st["shared.cancel"]} />
            </Button>
          </Fragment>}
      >
        {!emailEditorClass &&
          <div id="email-address-section">
            {!domainOptions.length &&
              <InputField
                name="userName"
                value={personData.userName}
                title="Primary email"
                onChange={onChange}
                onBlur={onBlur}
                fullwidth={true}
                error={!!errors.userName}
                helperText={
                  (errors.userName &&
                    intl.formatMessage(st[errors.userName] || t[errors.userName])) ||
                  (intl.formatMessage(
                    t[
                    forceSamlSsoLogin
                      ? "email-editor.saml-sso-email-info"
                      : "email-editor.primary-email-info"
                    ]
                  ))
                }
              >
                {<FormattedMessage{...t["email-editor.primary-email"]} />}
              </InputField>
            }
            {!!domainOptions.length &&
              <EmailInputGroup
                name="domainUserName"
                label={intl.formatMessage(t["email-editor.primary-email"])}
                value={domainUserName}
                onChange={onChange}
                onBlur={onBlur}
                error={
                  errors.domainUserName &&
                  intl.formatMessage(
                    st[errors.domainUserName] || t[errors.domainUserName]
                  )
                }
                infoText={intl.formatMessage(
                  t[
                  forceSamlSsoLogin
                    ? "email-editor.saml-sso-email-info"
                    : "email-editor.primary-email-info"
                  ]
                )}
                domainValue={domainValue}
                domainOptions={domainOptions}
                onDomainChange={onDomainChange}
              />
            }

            {!forceSamlSsoLogin && (
              <InputField
                name="recoveryEmail"
                value={recoveryEmail}
                title="Recovery email"
                onChange={onChange}
                onBlur={onBlur}
                fullwidth={true}
                error={!!errors.recoveryEmail}
                helperText={
                  (!!errors.recoveryEmail &&
                    intl.formatMessage(
                      st[errors.recoveryEmail] || t[errors.recoveryEmail]
                    )) ||
                  (intl.formatMessage(t["email-editor.recovery-email-info"]))
                }
              >
                {intl.formatMessage(t["email-editor.recovery-email"])}
              </InputField>
            )}
          </div>}
        {!verifyEmailClass &&
          <div>
            <EmailVerificationContainer
              personData={personData}
              showEmailEditor={showEmailEditor}
              userName={modifiedUserName}
              handlePrimaryEmailVerification={handlePrimaryEmailVerification}
              closeModal={closeModal}
              completeProcess={completeProcess}
            />
          </div>
        }
      </ResponsiveDialog>
    </Fragment>
  );
};

EmailEditor.propTypes = {
  personData: PropTypes.object.isRequired,
  isEmailChangeAllowed: PropTypes.bool.isRequired,
  onEditLinkClick: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  completeProcess: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  enableSubmit: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  domainUserName: PropTypes.string.isRequired,
  domainValue: PropTypes.string.isRequired,
  domainOptions: PropTypes.array.isRequired,
  onDomainChange: PropTypes.func.isRequired,
  recoveryEmail: PropTypes.string.isRequired,
  showEmailEditor: PropTypes.func.isRequired,
  modifiedUserName: PropTypes.string,
  handlePrimaryEmailVerification: PropTypes.func.isRequired,
  emailEditorClass: PropTypes.string.isRequired,
  verifyEmailClass: PropTypes.string.isRequired,
  forceSamlSsoLogin: PropTypes.bool.isRequired,
  forceSamlSsoClass: PropTypes.string.isRequired,
  isViewOnly: PropTypes.bool,
};

export default EmailEditor;
