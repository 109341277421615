import React from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  defineMessages,
  injectIntl,
} from "react-intl";
import classes from "./developer-tools.scss";
import commonClasses from "styles/_base.scss";
import appconfig from "config/appconfig";
import {
  Typography,
  EmptyState,
  Button,
  CircularProgress,
  IconButton,
  AlertV2
} from "@getgo/chameleon-web-react-wrapper";
import st from "core/shared-translations";
import {
  AddOutlinedIcon,
  EditOutlinedIcon,
  TrashOutlinedIcon,
  AlertOutlinedIcon,
  MoreMenuVerticalOutlinedIcon
} from "@getgo/chameleon-icons/react";
import KeyIcon from "static/images/svg/personal-access-key.svg";
import {
  DataTableV2,
  DataTableCellV2,
  DataTableRowV2,
  DataTableHeaderCellV2,
  PopoverV2,
  MenuItem
} from "@getgo/chameleon-web-react-wrapper";
import { Link } from "react-router-dom";
import dt from "core/dialog-translations";

const t = defineMessages({
  "developer-tools.logo": {
    id: "developer-tools.logo",
    defaultMessage: "Developer Tools logo",
    description: "Hover text to display for the Developer Tools image",
  },
  "developer-tools.manage-personal-access-token": {
    id: "developer-tools.manage-personal-access-token",
    defaultMessage: "Manage personal access tokens",
    description: "Section title for Developer tools",
  },
  "developer-tools.info-text": {
    id: "developer-tools.info-text",
    defaultMessage:
      "Use personal access tokens to access GoTo resources for your development projects. Personal access tokens let you authenticate securely with GoTo APIs.",
    description: "Information string for personal access tokens",
  },
  "developer-tools.create-token": {
    id: "developer-tools.create-token",
    defaultMessage: "Create token",
    description: "Label for Button Create token",
  },
  "developer-tools.personal-access-token-limit": {
    id: "developer-tools.personal-access-token-limit",
    defaultMessage: "You can create up to 20 tokens.",
    description:
      "Information string for limit to create number of personal access tokens",
  },
  "developer-tools.set-up-enhanced-security": {
    id: "developer-tools.set-up-enhanced-security",
    defaultMessage: "Get started",
    description: "String to set up enhanced security",
  },
  "developer-tools.enhanced-security-info": {
    id: "developer-tools.enhanced-security-info",
    defaultMessage:
      "To create personal access tokens you need to enable <b>enhanced security.</b>",
    description:
      "Info create message to enable enhanced security to create personal access tokens",
  },
  "developer-tools.manage-security-info": {
    id: "developer-tools.manage-security-info",
    defaultMessage:
      "To manage personal access tokens, you need to enable <b>enhanced security.</b>",
    description:
      "Info manage message to enable enhanced security to create personal access tokens",
  },
  "developer-tools.maximum-tokens-limit-info": {
    id: "developer-tools.maximum-tokens-limit-info",
    defaultMessage:
      "You're at the maximum number of tokens. To create a new token, you need to delete an existing one.",
    description: "Info message on maximum tokens to create limit reached",
  },
  "developer-tools.enhanced-security-enabled": {
    id: "developer-tools.enhanced-security-enabled",
    defaultMessage:
      "<b>Enhanced security enabled</b> <br>Now you can manage personal access tokens.",
    description: "Info message on enhanced security enabled",
  },
  "developer-tools.enable-enhanced-security": {
    id: "developer-tools.enable-enhanced-security",
    defaultMessage: "Enable enhanced security",
    description: "String for enabling enhanced security",
  },
  "developer-tools.create-token-disable-info": {
    id: "developer-tools.create-token-disable-info",
    defaultMessage:
      "To create a token, you need to enable <b>enhanced security.</b>",
    description: "Info message to enable enhanced",
  },
  "personal-access.your-token-title": {
    id: "personal-access.your-token-title",
    defaultMessage: "Your tokens",
    description: "String to Your tokens.",
  },
  "personal-access.edit-token-info": {
    id: "personal-access.edit-token-info",
    defaultMessage: "Edit token",
    description: "Information string for editing personal access token",
  },
  "personal-access-token.table.title1": {
    id: "personal-access-token.table.title1",
    defaultMessage: "Name",
    description: "Table personal-access-Title 1",
  },
  "personal-access-token.table.title2": {
    id: "personal-access-token.table.title2",
    defaultMessage: "Creation date",
    description: "Table personal-access-Title 2",
  },
  "personal-access-token.table.title3": {
    id: "personal-access-token.table.title3",
    defaultMessage: "Actions",
    description: "Table personal-access-Title 4",
  },
  "personal-access-token.no.token": {
    id: "personal-access-token.no.token",
    defaultMessage: "No personal access tokens yet",
    description: "Title when no personal token exist",
  },
  "personal-access-token.no.token.subtitle": {
    id: "personal-access-token.no.token.subtitle",
    defaultMessage:
      "Create your first personal access token to access GoTo dev resources and authenticate securely with GoTo APIs.",
    description: "sub-title when no personal token exist",
  },
});

const DeveloperTool = ({
  intl,
  setUpEnhancedSecurityOnClick,
  toggleEnhancedSecurity,
  disableCreateToken,
  enhancedSecurity,
  meTfaEnrolled,
  onDelete,
  allTokens,
  changeInEnhancedSecurity,
  isloading,
}) => {
  return (
    <div>
      {isloading && (
        <div className={commonClasses["centerAnyContent"]}>
          <CircularProgress />
        </div>
      )}
      {!isloading && (
        <div className={classes["mainContentDeveloperTools"]}>
          <div className={classes["spaceBetweenContent"]}>
            <div className={classes["infoText"]}>
              <Typography variant="heading-large" className={commonClasses["pageHeader"]}>
                <FormattedMessage {...st["nav.navbar.developerTools"]} />
              </Typography>
              {meTfaEnrolled && (
                <div>
                  <Typography tag="h2" variant="heading-small">
                    <FormattedMessage
                      {...t["developer-tools.manage-personal-access-token"]}
                    />
                  </Typography>
                  <Typography tag="p" variant="body-medium">
                    <FormattedMessage {...t["developer-tools.info-text"]} />
                  </Typography>

                  <Typography tag="p" variant="body-medium" style={{ marginTop: "16px" }}>
                    <FormattedMessage
                      {...t["developer-tools.personal-access-token-limit"]}
                    />
                  </Typography>

                  <div className={classes["alertSection"]}>
                    {disableCreateToken && (
                      <AlertV2 className={classes["controlWidth"]}>
                        <FormattedMessage
                          {...t["developer-tools.maximum-tokens-limit-info"]}
                        />
                      </AlertV2>
                    )}

                    {changeInEnhancedSecurity && (
                      <AlertV2 variant="success" className={classes["controlWidth"]}>
                        <FormattedHTMLMessage
                          {...t["developer-tools.enhanced-security-enabled"]}
                        />
                      </AlertV2>
                    )}
                    {!enhancedSecurity &&
                      <AlertV2 variant="warning" closable={false} icon={<AlertOutlinedIcon />}>
                        <FormattedHTMLMessage
                          {...t["developer-tools.manage-security-info"]}
                        />
                      </AlertV2>}
                  </div>
                  {!enhancedSecurity && (
                    <Button onClick={toggleEnhancedSecurity}>
                      <FormattedMessage {...t["developer-tools.enable-enhanced-security"]} />
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div>
              {meTfaEnrolled && <Link to={!(!enhancedSecurity || disableCreateToken) ? appconfig.routes.createTokens() : "#"}>
                <Button
                  title="createToken"
                  className={classes["createTokenCta"]}
                  leadingIcon={<AddOutlinedIcon />}
                  disabled={!enhancedSecurity || disableCreateToken}
                >
                  <FormattedMessage {...t["developer-tools.create-token"]} />
                </Button>
              </Link>}
            </div>
          </div>

          {!!allTokens.length && <div className={classes["flexSideSmall"]}>
            <DataTableV2 className={classes["controlWidth"]}>
              <DataTableRowV2 style={{ borderTop: "none" }}>
                <DataTableHeaderCellV2 condensed>
                  <FormattedMessage {...t[`personal-access-token.table.title1`]} />
                </DataTableHeaderCellV2>
                <DataTableHeaderCellV2 condensed>
                  <FormattedMessage {...t[`personal-access-token.table.title2`]} />
                </DataTableHeaderCellV2>
                <DataTableHeaderCellV2 condensed>
                  <FormattedMessage {...t[`personal-access-token.table.title3`]} />
                </DataTableHeaderCellV2>
              </DataTableRowV2>
              {allTokens.map(({ name, createdOn, id }) => (
                <DataTableRowV2 key={`data_row_${name}`}>
                  <DataTableCellV2>{name}</DataTableCellV2>
                  <DataTableCellV2>{createdOn}</DataTableCellV2>
                  <DataTableCellV2>
                    <Link to={enhancedSecurity && appconfig.routes.editTokens(id)}>
                      <IconButton disabled={!enhancedSecurity} labelId="edit" title={intl.formatMessage(st['shared.edit'])}>
                        <EditOutlinedIcon />
                      </IconButton>
                    </Link>
                    <IconButton disabled={!enhancedSecurity} labelId="Delete" title={intl.formatMessage(dt['confirmDialog.deleteToken.yesButtonText'])} onClick={enhancedSecurity && onDelete(id)}>
                      <TrashOutlinedIcon />
                    </IconButton>
                  </DataTableCellV2>
                </DataTableRowV2>
              ))}
            </DataTableV2>
          </div>}
          {!!allTokens.length && <div className={classes["flexBottomLarge"]}>
            {allTokens.map(({ name, createdOn, id }) => (
              <div className={classes["tokenItem"]} key={`data_row_${name}`}>
                <div>
                  <Typography variant="body-medium-strong">{name}</Typography>
                  <Typography variant="body-small">
                    {intl.formatMessage(t[`personal-access-token.table.title2`])}: {createdOn}
                  </Typography>
                </div>
                <div id="actions">
                  <IconButton id="token-actions" labelId={<FormattedMessage {...st['shared.edit']} />}>
                    <MoreMenuVerticalOutlinedIcon />
                  </IconButton>
                  <PopoverV2
                    triggerId="token-actions"
                    position="bottom-end"
                    label={<FormattedMessage {...t[`personal-access-token.table.title3`]} />}
                    zIndex={1100}
                    menu
                    maxWidth={110}
                  >
                    <Link
                      href="#"
                      to={enhancedSecurity && appconfig.routes.editTokens(id)}
                      aria-label={intl.formatMessage(st['shared.edit'])}
                      title={intl.formatMessage(st['shared.edit'])}>
                      <MenuItem leadingIcon={<EditOutlinedIcon color="type-color-default" />}>
                        <FormattedMessage {...st['shared.edit']} />
                      </MenuItem>
                    </Link>
                    <Link
                      href="#"
                      to=""
                      onClick={enhancedSecurity && onDelete(id)}
                      aria-label={intl.formatMessage(dt['confirmDialog.deleteToken.yesButtonText'])}
                      title={intl.formatMessage(dt['confirmDialog.deleteToken.yesButtonText'])}>
                      <MenuItem leadingIcon={<TrashOutlinedIcon color="type-color-default" />}>
                        <FormattedMessage {...dt['confirmDialog.deleteToken.yesButtonText']} />
                      </MenuItem>
                    </Link>
                  </PopoverV2>
                </div>
              </div>
            ))}
          </div>}
          {!allTokens.length && !meTfaEnrolled && (
            <div className={classes["emptyStateStyle"]}>
              <EmptyState
                icon={<img src={KeyIcon} alt="keyicon" />}
                title={
                  <Typography variant="heading-small">
                    <FormattedMessage
                      {...t["personal-access-token.no.token"]}
                    />
                  </Typography>
                }
                actions={<React.Fragment>
                  <Button onClick={setUpEnhancedSecurityOnClick}>
                    <FormattedMessage
                      {...t["developer-tools.set-up-enhanced-security"]}
                    />
                  </Button>
                </React.Fragment>}
              >
                <FormattedMessage
                  {...t["personal-access-token.no.token.subtitle"]}
                />
              </EmptyState>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DeveloperTool.propTypes = {
  intl: PropTypes.object,
  setUpEnhancedSecurityOnClick: PropTypes.func,
  enhancedSecurity: PropTypes.bool.isRequired,
  meTfaEnrolled: PropTypes.bool.isRequired,
  disableCreateToken: PropTypes.bool.isRequired,
  allTokens: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  toggleEnhancedSecurity: PropTypes.func.isRequired,
  changeInEnhancedSecurity: PropTypes.bool.isRequired,
  isloading: PropTypes.bool.isRequired,
  handleHeaderClick: PropTypes.func,
};

export default injectIntl(DeveloperTool);
