import React from "react";
import UserInvite from "../components/user-invitation-view";
import environment from "lib/environment";
import { getForgotPasswordUrl } from "lib/utility-functions";

export const UserInvitationContainer = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let acceptInvitationURL = `${environment.get().oauth.redirect_url}acceptinvitation?id=${urlParams.get("id")}`;
  const navToSignIn = () => {
    window.location.assign(acceptInvitationURL);
  };

  return (
    <UserInvite
      navToSignIn={navToSignIn}
      resetPwdUrl={getForgotPasswordUrl()}
    />
  );
};

export default UserInvitationContainer;
