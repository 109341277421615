import React from 'react';
import appconfig from 'config/appconfig';
import Session from 'lib/session';
import auth from "lib/profile-auth";
import { getQueryStringParams } from 'lib/utility-functions';
import {
  meEnhancedSecurity,
  meTfaEnrolled,
  meRequestPatch,
} from 'modules/me-module';

/**
 * Function to clear the auth token and reload the current URL which causes a new login validation
 */
const clearSessionAndReload = () => {
  Session.setItem('minLoa', '3');
  auth.clear();
  window.location.assign(window.location.href.split('?')[0]);
};

/**
 * Function to set the enhancedSecurity flag.
 *
 * @param store
 * @returns {Promise<*>} - true if update succeeded, false if it did not.
 */
const setEnhancedSecurityFlag = (store) => {
  const payload = {};
  payload[appconfig.scimSchemas.getGo] = {enhancedSecurity: true};
  return store.dispatch(meRequestPatch(payload))
    .then(() => {
        clearSessionAndReload();
        return true;
      },
      () => {
        return false;
      });
};

/**
 * Function to check if the login needs to set the enhancedSecurity flag or step up user validation with MFA.
 *
 * @param store
 */
export const securityCheck = (store) => {
  const enhancedSecurityIsEnabled = meEnhancedSecurity(store.getState());
  const minLoa = auth.token.loa || 2;
  const tfaEnrolled = meTfaEnrolled(store.getState());
  const queryParams = getQueryStringParams(location.search);

  const enhancedSecurityCookie = Session.getItem(appconfig.storage.enhancedSecuritySetup);
  if (enhancedSecurityCookie) { // if the enhanced security setup cookie is found, then delete it.
    Session.removeItem(appconfig.storage.enhancedSecuritySetup);
  }
  if (queryParams[appconfig.storage.enhancedSecuritySetup] &&
    enhancedSecurityCookie &&
    minLoa < 3 &&
    !enhancedSecurityIsEnabled &&
    tfaEnrolled) {
      return setEnhancedSecurityFlag(store);
  }

  if (minLoa < 3 && enhancedSecurityIsEnabled) {
    clearSessionAndReload();
  }
};

export default { securityCheck };
