import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import classes from './permission-item.scss';
import appconfig from "config/appconfig";
import st from "core/shared-translations";
import {
  Typography,
  Checkbox,
  Link,
  IconButton,
  Button
} from "@getgo/chameleon-web-react-wrapper";
import { ChevronDownOutlinedIcon, ChevronUpOutlinedIcon } from '@getgo/chameleon-icons/react';

const t = defineMessages({
  'permissionItem.granular.link.show': {
    id: 'permissionItem.granular.link.show',
    defaultMessage: 'Choose from granular permissions',
    description: 'Link text for showing more granular permissions',
  },
  'permissionItem.granular.link.hide': {
    id: 'permissionItem.granular.link.hide',
    defaultMessage: 'Hide granular scopes',
    description: 'Link text for hiding granular permissions',
  },
});

const PermissionItemView = ({
  permissionData,
  onPermissionCheckboxClick,
  onPermissionGroupCheckboxClick,
  togglePermissionsDetail,
  isProfilePermission,
  disabled = false,
}) => {
  const intl = useIntl();
  return (
    <div className={classes["permission-item-row"]}>
      <Typography tag="p" variant="body-medium-strong">
        <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.title`]} />
      </Typography>
      <div className={classes["permission-subtitle"]}>
        {!permissionData.hasChildScopes && !isProfilePermission &&
          <Checkbox
            id={permissionData.scopes[0]}
            name={permissionData.scopes[0]}
            title={intl.formatMessage(st[`shared.permissionItem.${permissionData.name}.cbtitle`])}
            checked={permissionData.selectedScopes.indexOf(permissionData.scopes[0]) > -1}
            onChange={onPermissionCheckboxClick(permissionData.name, permissionData.scopes[0])}
            disabled={disabled}
          />}
        {permissionData.hasChildScopes && !isProfilePermission &&
          <Checkbox
            id={`groupCheck_${permissionData.name}`}
            name={`groupCheck_${permissionData.name}`}
            title={intl.formatMessage(st[`shared.permissionItem.${permissionData.name}.cbtitle`])}
            indeterminate={
              !!(permissionData.selectedScopes.length &&
                permissionData.scopes.length > 0 &&
                permissionData.selectedScopes.length < permissionData.scopes.length)
            }
            checked={!!(permissionData.selectedScopes.length && permissionData.selectedScopes.length === permissionData.scopes.length)}
            onChange={onPermissionGroupCheckboxClick(permissionData.name)}
            disabled={disabled}
          />}
        <div className={classes["permission-subtitle-text"]}>
          {!isProfilePermission &&
            <Typography tag="p" variant="body-medium" color="type-color-default">
              <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.subtitle`]} />
            </Typography>}
          {isProfilePermission &&
            <Typography tag="p" variant="body-small" color="type-color-default">
              <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.subtitle`]} />
            </Typography>}
          {!permissionData.hasChildScopes && !isProfilePermission &&
            <Typography tag="p" variant="body-small" color="type-color-secondary">
              {`[${permissionData.scopes[0]}]`}
            </Typography>}
        </div>
      </div>
      {(permissionData.hasChildScopes || isProfilePermission) && <React.Fragment>
        {!isProfilePermission &&
          <Button
            variant="tertiary"
            className={classes["advanced-scopes-toggle"]}
            trailingIcon={!permissionData.isGranularView ? <ChevronDownOutlinedIcon color="type-color-brand-persistent" /> : <ChevronUpOutlinedIcon color="type-color-brand-persistent" />} onClick={togglePermissionsDetail(permissionData.name)}
          >
            <Typography tag="p" variant="caption-medium" color="type-color-brand-persistent">
              {intl.formatMessage(t[permissionData.isGranularView ? 'permissionItem.granular.link.hide' : 'permissionItem.granular.link.show'])}
            </Typography>
          </Button>}
        <div className={classes["childScope"]}>

          {((!isProfilePermission && permissionData.isGranularView) || isProfilePermission) && permissionData.scopes.map((scope, index) => (
            <Checkbox
              key={`child-scope-${index}`}
              id={scope}
              name={scope}
              className={classes["child-scope-checker"]}
              title={intl.formatMessage(st[`shared.permissionItem.${permissionData.name}.${scope}.cbtitle`])}
              checked={permissionData.selectedScopes.indexOf(scope) > -1 || scope === appconfig.defaultProfileScope}
              disabled={scope === appconfig.defaultProfileScope || disabled}
              onChange={onPermissionCheckboxClick(permissionData.name, scope)}
            >
              <Typography tag="p" variant="body-medium" color="type-color-default">
                <FormattedMessage {...st[`shared.permissionItem.${permissionData.name}.${scope}`]} />
              </Typography>
              {scope !== appconfig.defaultProfileScope &&
                <Typography tag="p" variant="body-small" color="type-color-secondary">
                  {`[${scope}]`}
                </Typography>}
            </Checkbox>
          ))}
        </div>
      </React.Fragment>}
    </div>
  );
};

PermissionItemView.propTypes = {
  permissionData: PropTypes.object.isRequired,
  onPermissionCheckboxClick: PropTypes.func.isRequired,
  onPermissionGroupCheckboxClick: PropTypes.func.isRequired,
  togglePermissionsDetail: PropTypes.func.isRequired,
  isProfilePermission: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default PermissionItemView;