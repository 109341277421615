import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import Modal from 'react-modal';
import classes from './confirm-dialog.scss';
import st from "core/shared-translations";
import dt from "core/dialog-translations";
import { Button, CircularProgress } from '@getgo/chameleon-web-react-wrapper';
import ResponsiveDialog from 'components/dialog-view.js';
if (process.env.NODE_ENV !== 'test') {
  Modal.setAppElement('#root');
}

const ConfirmDialogView = ({
  textResourceId,
  closeConfirmModalYesFn,
  closeConfirmModalNoFn,
  closeModal,
  modalIsOpen,
  useCountDownTimer = false,
  translationParams = {},
  secondsRemaining = 0,
  variant = "primary"
}) => {
  const intl = useIntl();
  return (
    <ResponsiveDialog
      open={modalIsOpen}
      title={<FormattedMessage {...dt[`confirmDialog.${textResourceId}.title`]} />}
      closable={!useCountDownTimer}
      onClosed={closeModal}
      actions={!useCountDownTimer &&
        <React.Fragment>
          <Button variant={variant} onClick={closeConfirmModalYesFn}>
            <FormattedMessage {...dt[`confirmDialog.${textResourceId}.yesButtonText`]} />
          </Button>
          <Button variant="tertiary" onClick={closeConfirmModalNoFn}>
            <FormattedMessage {...dt[`confirmDialog.${textResourceId}.noButtonText`]} />
          </Button>
        </React.Fragment>}>
      <FormattedHTMLMessage {...dt[`confirmDialog.${textResourceId}.text`]} values={{ ...translationParams }} />
      {useCountDownTimer &&
        <div className={classes["useCountDownTimerBlock"]}>
          <span><CircularProgress className={classes["loadingIcon"]} /></span>
          <FormattedHTMLMessage {...dt[`confirmDialog.${textResourceId}.countDownTimerText`]} values={{ seconds: secondsRemaining }} />
        </div>}
    </ResponsiveDialog>
  );
};

ConfirmDialogView.propTypes = {
  textResourceId: PropTypes.string.isRequired,
  closeConfirmModalYesFn: PropTypes.func.isRequired,
  closeConfirmModalNoFn: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  useCountDownTimer: PropTypes.bool,
  translationParams: PropTypes.object.isRequired,
  secondsRemaining: PropTypes.number,
  variant: PropTypes.string
};

export default ConfirmDialogView;
