import React from 'react';
import PropTypes from 'prop-types';

export const staticLink = ({
  value = '',
  showEditIcon = false
}) =>
  <span
    className="view-only-text"
    title={value}>
    {showEditIcon &&
      <span>
        <i className="togo-icon togo-icon-edit" />
        &nbsp;
      </span>}
    {value}
  </span>;

staticLink.propTypes = {
  value: PropTypes.string,
  showEditIcon: PropTypes.bool
};

export default staticLink;
