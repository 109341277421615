import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Typography } from '@getgo/chameleon-web-react-wrapper';
import classes from './notification-view.scss';

const Notification = ({
  toastrType,
  toastrMessage,
  toastrMessageId,
  toastrMessageDescription
}) => {
  return (
    <div
      key={toastrMessageId}
      className={
        (toastrMessageId === "token-edit.token-add-successful" ||
          toastrMessageId === "copy-to-clipboard.token-id-copied" ||
          toastrMessageId === "token-edit.token-update-failed-dup-name") ?
          classes["notificationInTokenCreation"] :
          classes["notificationBlock"]
      }
    >
      <Snackbar>
        <Typography variant="body-small" color="type-color-inverted">
          {`${toastrMessage}`}
        </Typography>
        <Typography variant="caption-medium" color="type-color-secondary-inverted">
          {`${toastrMessageDescription}`}
        </Typography>
      </Snackbar>
    </div>
  );
};

Notification.propTypes = {
  toastrType: PropTypes.string.isRequired,
  toastrMessage: PropTypes.string.isRequired,
  toastrMessageId: PropTypes.string.isRequired,
  toastrMessageDescription: PropTypes.string
};

export default Notification;
