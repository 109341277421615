import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  defineMessages,
  injectIntl,
  FormattedHTMLMessage
} from 'react-intl';
import st from 'core/shared-translations';
import environment from 'lib/environment';
import ProductLink from './product-link-view';
import appconfig from 'config/appconfig';
import { getLocalizedUrl } from 'lib/utility-functions';
import { IconButton } from '@getgo/chameleon-web-react-wrapper';
import {
  PersonOutlinedIcon,
  HelpCircleOutlinedIcon,
  ListOutlinedIcon,
  FileTextOutlinedIcon,
  IdCardOutlinedIcon,
  LockedFilledIcon,
  CodeOutlinedIcon,
  ExternalLinkOutlinedIcon,
  PeopleOutlinedIcon,
  CashOutlinedIcon,
  SignOutOutlinedIcon,
  MoreMenuHamburgerOutlinedIcon,
  CloseOutlinedIcon,
  ChevronRightOutlinedIcon
} from '@getgo/chameleon-icons/react';
import classes from './main-nav-view.scss';
import { MenuItem, Menu, MenuSeparator, PopoverV2, Typography } from '@getgo/chameleon-web-react-wrapper';
import GotoProfile from 'static/images/svg/Goto_profile.svg';

const myAccountUrl = environment.get().myAccount_url;
const adminCenterUrl = environment.get().admin_url;
const billingUrl = environment.get().billing_url;

const t = defineMessages({
  'nav.hamburgertoggle': {
    id: 'nav.hamburgertoggle',
    defaultMessage: 'Toggle navigation',
    description: 'The nav hamburger toggle which shows and hides nav menu for mobile devices',
  },
  'nav.navbar.help': {
    id: 'nav.navbar.help',
    defaultMessage: 'Help',
    description: 'Help icon label',
  },
  'nav.navbar.faq': {
    id: 'nav.navbar.faq',
    defaultMessage: 'FAQ',
    description: 'FAQ menu label',
  },
  'nav.navbar.new-features': {
    id: 'nav.navbar.new-features',
    defaultMessage: 'New Features',
    description: 'New Features menu label',
  },
  'nav.navbar.sign-out': {
    id: 'nav.navbar.sign-out',
    defaultMessage: 'Sign Out',
    description: 'Sign Out menu label',
  },
  'nav.navbar.my-account': {
    id: 'nav.navbar.my-account',
    defaultMessage: 'My Account',
    description: 'My Account menu label',
  },
  'nav.navbar.admin-center': {
    id: 'nav.navbar.admin-center',
    defaultMessage: 'Admin Center',
    description: 'Admin Center menu label',
  },
  'nav.navbar.billing': {
    id: 'nav.navbar.billing',
    defaultMessage: 'Billing',
    description: 'Billing menu label',
  },
  'nav.lmi.account': {
    id: 'nav.lmi.account',
    defaultMessage: 'GoTo Account: {name}',
    description: 'Hover text to be displayed over the Nav Bar\'s small avatar image',
  },
  'footer.aboutus': {
    id: 'footer.aboutus',
    defaultMessage: 'About us',
    description: 'The about us link',
  },
  'footer.legalinfo': {
    id: 'footer.legalinfo',
    defaultMessage: 'Legal info',
    description: 'The terms of service link',
  },
  'footer.copyright': {
    id: 'footer.copyright',
    defaultMessage: '© {currentYear} GoTo Group, Inc. All Rights Reserved',
    description: 'The copyright text',
  },
});


export const MainNav = ({
  intl,
  logout,
  displayName,
  meIsAccountAdmin,
  myProducts,
  avatarImageUrl,
  meIsBillingAdmin,
  displayEmail,
  showSupportLink,
  supportUrls,
  gotoAppEnabled,
  language,
  showMenu,
  toggleMenuVisibility
}) => {
  const privacyPolicyUrl = environment.get().privacy_policy_url;
  const aboutUsUrl = environment.get().about_us_url;
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <Menu className={classes['navContainer']} role="menu" aria-label="navbar">
        <img src={GotoProfile} alt="" />
        <div className={classes['iconGroup']}>
          {showSupportLink &&
            <div className={classes["supportLinks"]}>
              <IconButton id="help-trigger" labelId={<FormattedMessage {...t['nav.navbar.help']} />}>
                <HelpCircleOutlinedIcon size="24px" />
              </IconButton>
              <PopoverV2
                triggerId="help-trigger"
                label="nav Popover label"
                position="bottom-end"
                menu
                minWidth={238}
              >
                <a href={getLocalizedUrl(supportUrls.faqUrl, language)}>
                  <MenuItem leadingIcon={<ListOutlinedIcon color="type-color-default" />}>
                    <FormattedMessage {...t['nav.navbar.faq']} />
                  </MenuItem>
                </a>
                <a href={getLocalizedUrl(supportUrls.supportUrl, language)}>
                  <MenuItem leadingIcon={<FileTextOutlinedIcon color="type-color-default" />}>
                    <FormattedMessage {...st['shared.help.support']} />
                  </MenuItem>
                </a>
                <MenuSeparator />
                <a href={getLocalizedUrl(aboutUsUrl, language)}>
                  <MenuItem trailingIcon={<ExternalLinkOutlinedIcon color="type-color-default" />}>
                    <FormattedMessage {...t['footer.aboutus']} />
                  </MenuItem>
                </a>
                <a href={getLocalizedUrl(privacyPolicyUrl, language)}>
                  <MenuItem trailingIcon={<ExternalLinkOutlinedIcon color="type-color-default" />}>
                    <FormattedMessage {...t['footer.legalinfo']} />
                  </MenuItem>
                </a>
              </PopoverV2>
            </div>}
          <div>
            <IconButton
              labelId={<FormattedMessage {...st['nav.hamburgertoggle']} />}
              id="user-option-trigger"
              onClick={toggleMenuVisibility}
            >
              <div className={classes["avatar-nav"]}>
                <img src={avatarImageUrl} alt={displayEmail} className={classes["navIcon"]} />
              </div>
              <div className={classes["fluidOptions"]}>
                {!showMenu && <MoreMenuHamburgerOutlinedIcon />}
                {showMenu && <CloseOutlinedIcon />}
              </div>
            </IconButton>
            <PopoverV2
              position="bottom-start"
              menu
              triggerId="user-option-trigger"
              label="avatar options"
              width={window.innerWidth < 762 && window.innerWidth - 29}
              onChange={toggleMenuVisibility}
            >
              <div className={classes["avatarInfo"]}>
                <IconButton id="user-option-trigger" role="button" labelId={<FormattedMessage {...st['nav.hamburgertoggle']} />}>
                  <img src={avatarImageUrl} alt="displayEmail" className={classes["navIcon"]} />
                </IconButton>
                <div>
                  <Typography variant="body-small" title={displayName} color="type-color-default">{displayName}</Typography>
                  <Typography variant="caption-default-02" title={displayEmail} color="text-02">{displayEmail}</Typography>
                </div>
              </div>
              <MenuSeparator />
              <a href={getLocalizedUrl(myAccountUrl, language)}>
                <MenuItem leadingIcon={<PersonOutlinedIcon color="type-color-default" />}>
                  <FormattedMessage {...t['nav.navbar.my-account']} />
                </MenuItem>
              </a>
              <a href={appconfig.routes.personalInfo()}>
                <MenuItem leadingIcon={<IdCardOutlinedIcon color="type-color-default" />}>
                  <FormattedMessage {...st['shared.personal.info']} />
                </MenuItem>
              </a>
              <a href={appconfig.routes.signinSecurity()}>
                <MenuItem leadingIcon={<LockedFilledIcon color="type-color-default" />}>
                  <FormattedMessage {...st['nav.navbar.signinsecurity']} />
                </MenuItem>
              </a>
              <a href={appconfig.routes.developerTools()}>
                <MenuItem leadingIcon={<CodeOutlinedIcon color="type-color-default" />}>
                  <FormattedMessage {...st['nav.navbar.developerTools']} />
                </MenuItem>
              </a>
              <div id="help-option-trigger" className={classes["fluidOptions"]}>
                <MenuItem
                  leadingIcon={<HelpCircleOutlinedIcon color="type-color-default" />}
                  trailingIcon={<ChevronRightOutlinedIcon color="type-color-default" />}
                >
                  <FormattedMessage {...t['nav.navbar.help']} />
                </MenuItem>
                <PopoverV2
                  position="bottom-end"
                  menu
                  triggerId="help-option-trigger"
                  width={window.innerWidth - 29}
                  label={<FormattedMessage {...t['nav.navbar.help']} />}
                >
                  <a href={getLocalizedUrl(supportUrls.faqUrl, language)}>
                    <MenuItem leadingIcon={<ListOutlinedIcon color="type-color-default" />}>
                      <FormattedMessage {...t['nav.navbar.faq']} />
                    </MenuItem>
                  </a>
                  <a href={getLocalizedUrl(supportUrls.supportUrl, language)}>
                    <MenuItem leadingIcon={<FileTextOutlinedIcon color="type-color-default" />}>
                      <FormattedMessage {...st['shared.help.support']} />
                    </MenuItem>
                  </a>
                  <MenuSeparator />
                  <a href={getLocalizedUrl(aboutUsUrl, language)}>
                    <MenuItem trailingIcon={<ExternalLinkOutlinedIcon color="type-color-default" />}>
                      <FormattedMessage {...t['footer.aboutus']} />
                    </MenuItem>
                  </a>
                  <a href={getLocalizedUrl(privacyPolicyUrl, language)}>
                    <MenuItem trailingIcon={<ExternalLinkOutlinedIcon color="type-color-default" />}>
                      <FormattedMessage {...t['footer.legalinfo']} />
                    </MenuItem>
                  </a>
                </PopoverV2>
              </div>
              {!!myProducts.length && <MenuSeparator />}
              {meIsAccountAdmin &&
                <a href={getLocalizedUrl(adminCenterUrl, language)}>
                  <MenuItem leadingIcon={<PeopleOutlinedIcon color="type-color-default" />}>
                    <FormattedMessage {...t['nav.navbar.admin-center']} />
                  </MenuItem>
                </a>}
              {meIsBillingAdmin &&
                <a href={getLocalizedUrl(billingUrl, language)}>
                  <MenuItem leadingIcon={<CashOutlinedIcon color="type-color-default" />}>
                    <FormattedMessage {...t['nav.navbar.billing']} />
                  </MenuItem>
                </a>}
              {!!myProducts.length &&
                myProducts.map(product => <ProductLink key={product} product={product} gotoAppEnabled={gotoAppEnabled} />)}
              {!!myProducts.length && <MenuSeparator />}
              <a href="#" onClick={logout}>
                <MenuItem leadingIcon={<SignOutOutlinedIcon color="type-color-default" />}>
                  <FormattedMessage {...t['nav.navbar.sign-out']} />
                </MenuItem>
              </a>
              <MenuSeparator />
              <div className={classes['copyRightBlock']}>
                <Typography variant="caption-small" color="type-color-secondary">
                  <FormattedHTMLMessage {...t['footer.copyright']} values={{ currentYear }} />
                </Typography>
              </div>

            </PopoverV2>
          </div>
        </div>
      </Menu>
    </div>
  );
};

MainNav.propTypes = {
  intl: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  meIsAccountAdmin: PropTypes.bool.isRequired,
  myProducts: PropTypes.array.isRequired,
  avatarImageUrl: PropTypes.string.isRequired,
  meIsBillingAdmin: PropTypes.bool.isRequired,
  displayEmail: PropTypes.string.isRequired,
  showSupportLink: PropTypes.bool.isRequired,
  supportUrls: PropTypes.object.isRequired,
  gotoAppEnabled: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  showMenu: PropTypes.bool.isRequired,
  toggleMenuVisibility: PropTypes.func.isRequired
};

export default injectIntl(MainNav);
