import React, { useState } from "react";
import { useSelector } from "react-redux";
import EmailSubscriptionOptOut from "../components/email-subscription-opt-out-view";
import { meUserName, meIsConflicted } from "modules/me-module";
import environment from "lib/environment";

const marketingOptOutUrl = environment.get().marketing_opt_out_url;

export const EmailSubscriptionOptOutContainer = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { userName, isViewOnly } = useSelector(getValuesFromStore);
  const optOutUrl = () => `${marketingOptOutUrl}?Email=${userName}`;
  const onEmailSubscriptionClick = () => {
    setModalIsOpen(true);
    return;
  };

  const closeModal = () => {
    setModalIsOpen(false);
    return;
  };

  return (
    <EmailSubscriptionOptOut
      marketingOptOutUrl={optOutUrl()}
      isViewOnly={isViewOnly}
      onEmailSubscriptionClick={onEmailSubscriptionClick}
      closeModal={closeModal}
      modalIsOpen={modalIsOpen}
    />
  );
};
export const getValuesFromStore = (state) => ({
  userName: meUserName(state) || "",
  isViewOnly: meIsConflicted(state),
});

export default EmailSubscriptionOptOutContainer;
