import React from "react";
import PropTypes from "prop-types";
import classes from "./email-input-group.scss";
import { Typography, InputField } from '@getgo/chameleon-web-react-wrapper';
import SelectInput from "components/select-input-view";
import { useIntl } from "react-intl";

export const EmailInputGroup = ({
  name,
  label,
  onChange,
  onBlur,
  domainValue,
  domainOptions,
  onDomainChange,
  placeholder,
  value,
  error = "",
  infoText,
}) => {
  const intl = useIntl();
  return (
    <div>
      <div id="email-input-group-container" className={classes["selectInputGroup"]}>
        <InputField
          id={`${name}-input`}
          className={classes["emailInput"]}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          title={label}
          error={!!error}
        >
          {label}
        </InputField>
        <div className={classes["selectDomain"]}>
          <SelectInput
            name={`${name}_domain`}
            id={`${name}_domain`}
            value={(!!domainOptions && !!domainValue &&
              (domainOptions.find((option) => option.value === domainValue)).value) || null}
            onChange={onDomainChange}
            options={domainOptions}
          />
        </div>
      </div>
      <div>
        <Typography variant="caption-medium" color={!error ? "type-color-secondary" : "type-color-danger"}>
          {!error ? infoText : error}
        </Typography>
      </div>
    </div>
  );
};

EmailInputGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  infoText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  domainValue: PropTypes.string,
  domainOptions: PropTypes.array.isRequired,
  onDomainChange: PropTypes.func.isRequired,
};

export default EmailInputGroup;
